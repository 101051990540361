import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  
  initializeAnalytics() {
    if (typeof gtag === 'undefined') {
      this.loadGoogleAnalytics();
    }
  }

  trackFormSubmission(status: 'success' | 'error') {
    gtag('event', 'form_submission', {
      'event_category': 'Landing Page',
      'event_label': status,
      'value': status === 'success' ? 1 : 0
    });
  }

  private loadGoogleAnalytics() {
    // Add Google Analytics script dynamically
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', environment.googleAnalyticsId);
  }
}