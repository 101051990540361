/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chatbot.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../data-wp.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./chatbot.component";
var styles_ChatbotComponent = [i0.styles];
var RenderType_ChatbotComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatbotComponent, data: {} });
export { RenderType_ChatbotComponent as RenderType_ChatbotComponent };
function View_ChatbotComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flotante-whatsapp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["onclick", "gtag('event', 'whatsapp');"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/img/icon-whatsapp.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "https://api.whatsapp.com/send?phone=", _v.parent.context.$implicit.acf.whatsapp.numero_sin_espacios, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_ChatbotComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatbotComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.acf.whatsapp.habilitar; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChatbotComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChatbotComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.posts$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChatbotComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatbotComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posts$; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChatbotComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-chatbot", [], null, null, null, View_ChatbotComponent_0, RenderType_ChatbotComponent)), i1.ɵprd(512, null, i3.DataWpServiceChatbot, i3.DataWpServiceChatbot, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i5.ChatbotComponent, [i3.DataWpServiceChatbot], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ChatbotComponentNgFactory = i1.ɵccf("app-chatbot", i5.ChatbotComponent, View_ChatbotComponent_Host_0, {}, {}, []);
export { ChatbotComponentNgFactory as ChatbotComponentNgFactory };
