<div class="contentModalGral px-3 pt-4">
    <div class="row titulos " >
        <div class="col-12 px-4">
            <h2><img src="assets/img/icon-socios-08.png" alt=""> Modificación de datos</h2>
        </div> 
        <div class="col-12 subtituloModificacionTitulo">
            <h5>Elegí la persona a la que deseas cambiar los datos, podrás cambiar nombre,
                apellido o teléfono.</h5>
        </div>
    </div>
    <form class="formSolicitarCredencial" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="col-12">
            <mat-form-field class="select-socio" appearance="standard" style="width: 100%;">
                <mat-label class="labelfamiliar">Elige la persona para cambiar sus datos</mat-label>                        
                    <mat-select name="familiar" id="familiar" formControlName="familiar" (valueChange)='handleChange($event)'>                                                  
                      <mat-option *ngFor="let familiar of familiares;  index as i" value = {{i}} >{{familiar.nombre}} {{familiar.apellido}}</mat-option>
                    </mat-select>
            </mat-form-field>
        </div> 
        <div class="row justify-content-end formulario">  
                    <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': nombre.invalid && (nombre.dirty || nombre.touched)  }" >
                        <input type="text" formControlName="nombre"  id="nombre">
                        <span [ngClass]="{'spanInvalido ': nombre.invalid && (nombre.dirty || nombre.touched)  } ">Nombre *</span>
                        <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="invalido">
                            <div *ngIf="nombre.errors.required">
                                El Nombre es requerido.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': apellido.invalid && (apellido.dirty || apellido.touched)  }" >
                        <input type="text" formControlName="apellido"  id="apellido">
                        <span [ngClass]="{'spanInvalido ': apellido.invalid && (apellido.dirty || apellido.touched)  } ">Apellido *</span>
                        <div *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)" class="invalido">
                            <div *ngIf="apellido.errors.required">
                                El Apellido es requerida.
                            </div>
                        </div>  
                    </div>
                    <!-- <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': nroAfiliado.invalid && (nroAfiliado.dirty || nroAfiliado.touched)  }" >
                        <input type="number" formControlName="nroAfiliado"  id="nroAfiliado">
                        <span [ngClass]="{'spanInvalido ': nroAfiliado.invalid && (nroAfiliado.dirty || nroAfiliado.touched)  } ">Nro de Afiliado. *</span>
                        <div *ngIf="nroAfiliado.invalid && (nroAfiliado.dirty || nroAfiliado.touched)" class="invalido">
                            <div *ngIf="nroAfiliado.errors.required">
                                El Nro. de Afiliado es requerido.
                            </div>
                        </div>  
                    </div> -->
                    <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': telefono.invalid && (telefono.dirty || telefono.touched)  }" >
                        <input type="number" formControlName="telefono"  id="telefono">
                        <span [ngClass]="{'spanInvalido ': telefono.invalid && (telefono.dirty || telefono.touched)  } ">Teléfono *</span>
                        <div *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)" class="invalido">
                            <div *ngIf="telefono.errors.required">
                                El Teléfono es requerida.
                            </div>
                            <div *ngIf="telefono.errors.pattern">
                                Ingrese solo números.
                            </div>
                        </div>  
                    </div>
                    <div  class="col-12 col-lg-8 boxObligatoriosForms">
                        <p><small>* Campos obligatorios</small></p>
                    </div>
                    <div class="col-12 col-lg-4 contentButton">
                        <button [disabled]="contactForm.invalid" >ENVIAR</button>
                    </div>  
        </div>
    </form>
 
</div>


<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>