<ng-container  *ngIf="posts">
    <div class="row justify-content-center">

        <ng-template ngFor let-post  let-i="index" [ngForOf]="posts | paginate: config">

            <ng-template [ngIf]="i == 0" >
                <div class="col-12 itemNota primera">
                    <div class="contentNota">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="contentImg w-100 h-100" [ngStyle]="{'background-image': 'url(' + post.better_featured_image?.source_url + ')'}"></div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <span class="fecha"><i class="fa fa-calendar" aria-hidden="true"></i> {{post.date_gmt | date:'fullDate' }}</span>   
                                <a class="titleLink" routerLink="/{{post.slug}}"><h2 [innerHTML]='post.title.rendered'></h2></a>
                                <div class="contentBajada" [innerHTML]="post.excerpt.rendered.substr(0,155)+'...'"></div>
                                <div class="w-100"><p class="contentLink"><a routerLink="/{{post.slug}}">MÁS INFO</a></p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="i > 0">
    
                <div class="col-12 col-lg-6 itemNota {{i}}">
                    <div class="contentNota">
                        <div class="row">
                            <div class="col-12">
                                <img [src]="post.better_featured_image?.source_url" alt="">
                            </div>
                            <div class="col-12">
                                <span class="fecha"><i class="fa fa-calendar" aria-hidden="true"></i> {{post.date_gmt | date:'fullDate' }}</span>   
                                <a class="titleLink" routerLink="/{{post.slug}}"><h2 [innerHTML]='post.title.rendered'></h2></a>
                                <p [innerHTML]="post.excerpt.rendered.substr(0,120)+'...'"></p>
                            </div>
                                <p class="contentLink"><a routerLink="/{{post.slug}}">MÁS INFO</a></p>
                        </div>
                    </div>
                </div>
    
            </ng-template>
    
    
        </ng-template>
    
        <div class="col-12 contentPageNavi">
    
            <pagination-controls 
            [maxSize]="maxSize"
            [directionLinks]="directionLinks"
            [autoHide]="autoHide"
            [responsive]="responsive"
            [previousLabel]="labels.previousLabel"
            [nextLabel]="labels.nextLabel"
            [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
            [screenReaderPageLabel]="labels.screenReaderPageLabel"
            [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
            [ngx-scroll-to]="'#anchor1'"                        
            (pageChange)="onPageChange($event)"></pagination-controls>
    
        </div>

    </div>

</ng-container>
