/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-relacionadas.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-slick-carousel";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../../../../node_modules/ngx-slick-carousel/ngx-slick-carousel.ngfactory";
import * as i6 from "@angular/forms";
import * as i7 from "../../data-wp.service";
import * as i8 from "@angular/common/http";
import * as i9 from "./nav-relacionadas.component";
var styles_NavRelacionadasComponent = [i0.styles];
var RenderType_NavRelacionadasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavRelacionadasComponent, data: {} });
export { RenderType_NavRelacionadasComponent as RenderType_NavRelacionadasComponent };
function View_NavRelacionadasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "slide"], ["ngxSlickItem", ""]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.SlickItemDirective, [i1.ElementRef, i1.PLATFORM_ID, i2.SlickCarouselComponent], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "contentItem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 17, "div", [["class", "item-servicio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "pic"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "background-image": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "textBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "span", [["class", "fecha"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", ""])), i1.ɵppd(13, 2), (_l()(), i1.ɵeld(14, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "p", [["class", "contentLink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["M\u00E1s info"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = _ck(_v, 7, 0, (("url(" + ((_v.context.$implicit.better_featured_image == null) ? null : _v.context.$implicit.better_featured_image.source_url)) + ")")); _ck(_v, 6, 0, currVal_0); var currVal_6 = i1.ɵinlineInterpolate(1, "/", _v.context.$implicit.slug, ""); _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.date_gmt, "fullDate")); _ck(_v, 12, 0, currVal_1); var currVal_2 = _v.context.$implicit.title.rendered; _ck(_v, 15, 0, currVal_2); var currVal_3 = (_v.context.$implicit.excerpt.rendered.substring(0, 160) + "..."); _ck(_v, 16, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 19).target; var currVal_5 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_4, currVal_5); }); }
export function View_NavRelacionadasComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "section", [["class", "nav-servicios"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notas relacionadas"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ngx-slick-carousel", [["class", "carousel"]], null, null, null, i5.View_SlickCarouselComponent_0, i5.RenderType_SlickCarouselComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SlickCarouselComponent]), i1.ɵdid(6, 13287424, [["slickModal", 4]], 0, i2.SlickCarouselComponent, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NavRelacionadasComponent_1)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slideConfig; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.posts; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_NavRelacionadasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-nav-relacionadas", [], null, null, null, View_NavRelacionadasComponent_0, RenderType_NavRelacionadasComponent)), i1.ɵprd(512, null, i7.DataWpServiceBlog, i7.DataWpServiceBlog, [i8.HttpClient]), i1.ɵdid(2, 114688, null, 0, i9.NavRelacionadasComponent, [i7.DataWpServiceBlog, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var NavRelacionadasComponentNgFactory = i1.ɵccf("app-nav-relacionadas", i9.NavRelacionadasComponent, View_NavRelacionadasComponent_Host_0, {}, {}, []);
export { NavRelacionadasComponentNgFactory as NavRelacionadasComponentNgFactory };
