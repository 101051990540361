<div class="contentModalGral px-3 pt-4">
    <div class="row titulos " >
        <div class="col-12 px-4">
            <h2><img src="assets/img/icon-socios-04.png" alt=""> Pedido de contrato</h2>
        </div>        
    </div>
    <form class="formSolicitarCredencial" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="row justify-content-end formulario">
            <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': razonSocial.invalid && (razonSocial.dirty || razonSocial.touched)  }" >
                <input type="text" formControlName="razonSocial"  id="razonSocial">
                <span [ngClass]="{'spanInvalido ': razonSocial.invalid && (razonSocial.dirty || razonSocial.touched)  } ">Razón social *</span>
                <div *ngIf="razonSocial.invalid && (razonSocial.dirty || razonSocial.touched)" class="invalido">
                    <div *ngIf="razonSocial.errors.required">
                        La razón social es requerida.
                    </div>
                </div>  
            </div>
            <div class="col-12 col-lg-4">
                <div class="row ">
                    <div class="col-12" [ngClass]="{'campoInvalido ': nombreFantasia.invalid && (nombreFantasia.dirty || nombreFantasia.touched)  }" >
                        <input type="text" formControlName="nombreFantasia"  id="nombreFantasia">
                        <span [ngClass]="{'spanInvalido ': nombreFantasia.invalid && (nombreFantasia.dirty || nombreFantasia.touched)  } ">Nombre de Fantasia *</span>
                        <div *ngIf="nombreFantasia.invalid && (nombreFantasia.dirty || nombreFantasia.touched)" class="invalido">
                            <div *ngIf="nombreFantasia.errors.required">
                                El nombre de fantasia es requerido.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': localidad.invalid && (localidad.dirty || localidad.touched)  }" >
                        <input type="text" formControlName="localidad"  id="localidad">
                        <span [ngClass]="{'spanInvalido ': localidad.invalid && (localidad.dirty || localidad.touched)  } ">Localidad *</span>
                        <div *ngIf="localidad.invalid && (localidad.dirty || localidad.touched)" class="invalido">
                            <div *ngIf="localidad.errors.required">
                                La localidad es requerida.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': email.invalid && (email.dirty || email.touched)  }" >
                        <input type="text" formControlName="email"  id="email">
                        <span [ngClass]="{'spanInvalido ': email.invalid && (email.dirty || email.touched)  } ">Email *</span>
                        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalido">
                            <div *ngIf="email.errors.required">
                                El Email es requerido.
                            </div>
                            <div *ngIf="email.errors.email">
                                Debe colocar una direccion de email valida.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': dniFirmante.invalid && (dniFirmante.dirty || dniFirmante.touched)  }" >
                        <input type="text" formControlName="dniFirmante"  id="dniFirmante">
                        <span [ngClass]="{'spanInvalido ': dniFirmante.invalid && (dniFirmante.dirty || dniFirmante.touched)  } ">DNI *</span>
                        <div *ngIf="dniFirmante.invalid && (dniFirmante.dirty || dniFirmante.touched)" class="invalido">
                            <div *ngIf="dniFirmante.errors.required">
                                El dni del firmante es requerido.
                            </div>
                            <div *ngIf="dniFirmante.errors.pattern">
                                El DNI debe tener entre 7 y 8 números.
                            </div>
                        </div>  
                    </div>

                    <div class="col-12" [ngClass]="{'campoInvalido ': telefonoCobertura.invalid && (telefonoCobertura.dirty || telefonoCobertura.touched)  }" >
                        <input type="text" formControlName="telefonoCobertura"  id="telefonoCobertura">
                        <span [ngClass]="{'spanInvalido ': telefonoCobertura.invalid && (telefonoCobertura.dirty || telefonoCobertura.touched)  } ">Telefono de cobertura *</span>
                        <div *ngIf="telefonoCobertura.invalid && (telefonoCobertura.dirty || telefonoCobertura.touched)" class="invalido">
                            <div *ngIf="telefonoCobertura.errors.required">
                                El telefono de cobertura es requerido.
                            </div>
                        </div>  
                    </div>

                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="row  ">
                    <div class="col-12" [ngClass]="{'campoInvalido ': areaProtegida.invalid && (areaProtegida.dirty || areaProtegida.touched)  }" >
                        <input type="text" formControlName="areaProtegida"  id="areaProtegida">
                        <span [ngClass]="{'spanInvalido ': areaProtegida.invalid && (areaProtegida.dirty || areaProtegida.touched)  } ">N° de Area Protegida. *</span>
                        <div *ngIf="areaProtegida.invalid && (areaProtegida.dirty || areaProtegida.touched)" class="invalido">
                            <div *ngIf="areaProtegida.errors.required">
                                El número de area protegida es requerido.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': cuit.invalid && (cuit.dirty || cuit.touched)  }" >
                        <input type="text" formControlName="cuit"  id="cuit">
                        <span [ngClass]="{'spanInvalido ': cuit.invalid && (cuit.dirty || cuit.touched)  } ">Cuit *</span>
                        <div *ngIf="cuit.invalid && (cuit.dirty || cuit.touched)" class="invalido">
                            <div *ngIf="cuit.errors.required">
                                El cuit es requerido.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': telefono.invalid && (telefono.dirty || telefono.touched)  }" >
                        <input type="text" formControlName="telefono"  id="telefono">
                        <span [ngClass]="{'spanInvalido ': telefono.invalid && (telefono.dirty || telefono.touched)  } ">Teléfono *</span>
                        <div *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)" class="invalido">
                            <div *ngIf="telefono.errors.required">
                                El Teléfono es requerido.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': cargoFirmante.invalid && (cargoFirmante.dirty || cargoFirmante.touched)  }" >
                        <input type="text" formControlName="cargoFirmante"  id="cargoFirmante">
                        <span [ngClass]="{'spanInvalido ': cargoFirmante.invalid && (firmante.dirty || cargoFirmante.touched)  } ">Cargo *</span>
                        <div *ngIf="cargoFirmante.invalid && (cargoFirmante.dirty || cargoFirmante.touched)" class="invalido">
                            <div *ngIf="cargoFirmante.errors.required">
                                El cargo del firmante es requerido.
                            </div>
                        </div>  
                    </div>
                    
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="row  ">
                <div class="col-12 " [ngClass]="{'campoInvalido ': domicilioLegal.invalid && (domicilioLegal.dirty || domicilioLegal.touched)  }" >
                    <input type="text" formControlName="domicilioLegal"  id="domicilioLegal">
                    <span [ngClass]="{'spanInvalido ': domicilioLegal.invalid && (domicilioLegal.dirty || domicilioLegal.touched)  } ">Domicilio Legal *</span>
                    <div *ngIf="domicilioLegal.invalid && (domicilioLegal.dirty || domicilioLegal.touched)" class="invalido">
                        <div *ngIf="domicilioLegal.errors.required">
                            El domicilio legal es requerido.
                        </div>
                    </div>  
                </div>
                <div class="col-12" [ngClass]="{'campoInvalido ': condicionIva.invalid && (condicionIva.dirty || condicionIva.touched)  }" >
                    <input type="text" formControlName="condicionIva"  id="condicionIva">
                    <span [ngClass]="{'spanInvalido ': condicionIva.invalid && (condicionIva.dirty || condicionIva.touched)  } ">Condición IVA *</span>
                    <div *ngIf="condicionIva.invalid && (condicionIva.dirty || condicionIva.touched)" class="invalido">
                        <div *ngIf="condicionIva.errors.required">
                            La condición de IVA es requerida.
                        </div>
                    </div>  
                </div>
                <div class="col-12" [ngClass]="{'campoInvalido ': firmante.invalid && (firmante.dirty || firmante.touched)  }" >
                    <input type="text" formControlName="firmante"  id="firmante">
                    <span [ngClass]="{'spanInvalido ': firmante.invalid && (firmante.dirty || firmante.touched)  } ">Firmante *</span>
                    <div *ngIf="firmante.invalid && (firmante.dirty || firmante.touched)" class="invalido">
                        <div *ngIf="firmante.errors.required">
                            El firmante es requerido.
                        </div>
                    </div>  
                </div>
                <div class="col-12" [ngClass]="{'campoInvalido ': domicilioCobertura.invalid && (domicilioCobertura.dirty || domicilioCobertura.touched)  }" >
                    <input type="text" formControlName="domicilioCobertura"  id="domicilioCobertura">
                    <span [ngClass]="{'spanInvalido ': domicilioCobertura.invalid && (domicilioCobertura.dirty || domicilioCobertura.touched)  } ">Domicilio de cobertura *</span>
                    <div *ngIf="domicilioCobertura.invalid && (domicilioCobertura.dirty || domicilioCobertura.touched)" class="invalido">
                        <div *ngIf="domicilioCobertura.errors.required">
                            El domicilio de cobertura es requerido.
                        </div>
                    </div>  
                </div>
                </div>
            </div> 
            <div  class="col-12 col-lg-8 boxObligatoriosForms">
                <p><small>* Campos obligatorios</small></p>
            </div>
            <div class="col-12 col-lg-4 contentButton">
                <button [disabled]="contactForm.invalid" >ENVIAR</button>
            </div>  
        </div>
    </form>

</div>
<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
        <h4 style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>