<section class="headerComponent" id="headerPromos" *ngFor="let postPromo of postPromoPadre" >


  <ngb-carousel *ngIf="postPromo.acf.slider.slide" [showNavigationArrows]="postPromo.acf.slider.slide.length > 1" [showNavigationIndicators]="postPromo.acf.slider.slide.length > 1" interval="7500" [keyboard]="true" [pauseOnHover]="true" [wrap]="true" [activeId]="'secondSlide'" >
      <ng-template ngbSlide *ngFor="let slider of postPromo.acf.slider.slide">
          
              <div class="img" [ngStyle]="{'background-image': 'url(' + slider.imagen + ')'}"></div>
              <div class="container h-100">
                  <div class="row m-0 h-100 ">
                      <div class="col-lg-6 col-12 my-auto p-0" >
                          <h2 [innerHTML]="slider.titulo" class="slide-left"></h2>
                          <p [innerHTML]="slider.bajada" class="slide-left"></p>
                      </div>            
                  </div>
              </div>    
              
          </ng-template>
      </ngb-carousel>
      
</section>

<section class="promos">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-3 contentItem wow fadeInDown" *ngFor="let post of posts; let i=index">
                <div class="item-servicio" *ngIf="!mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                  <div class="hoverContent  h-100" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                    <div class="hoverContentTxt w-100 my-auto ">
                      <h2 [innerHTML]="post.acf.portada.titulo"></h2>
                      <p [innerHTML]="post.acf.portada.descripcion"></p>

                      <a *ngIf="!post.acf.portada.redirect.utilizar_redireccionamiento" routerLink="/promos/{{post.slug}}">
                        <div class="boton">{{post.acf.portada.label_boton}}</div>
                      </a>
                      <a *ngIf="post.acf.portada.redirect.utilizar_redireccionamiento" href="{{post.acf.portada.redirect.url}}">
                        <div class="boton">{{post.acf.portada.label_boton}}</div>
                      </a>

                    </div>
    
                  </div>
                  <h3>{{post.acf.portada.titulo}}</h3>
                </div>
                <div class="item-servicio" *ngIf="mobile" [ngStyle]="{'background-image':'url(' + post.acf.interior.imagenMobile + ')'}">
                  <div class="hoverContent  h-100" [ngStyle]="{'background-image':'url(' + post.acf.interior.imagenMobile + ')'}">
                    <div class="hoverContentTxt w-100 my-auto ">
                      <h2 [innerHTML]="post.acf.portada.titulo"></h2>
                      <p [innerHTML]="post.acf.portada.descripcion"></p>

                      <a *ngIf="!post.acf.portada.redirect.utilizar_redireccionamiento" routerLink="/promos/{{post.slug}}">
                        <div class="boton">{{post.acf.portada.label_boton}}</div>
                      </a>
                      <a *ngIf="post.acf.portada.redirect.utilizar_redireccionamiento" href="{{post.acf.portada.redirect.url}}">
                        <div class="boton">{{post.acf.portada.label_boton}}</div>
                      </a>

                    </div>
    
                  </div>
                  <h3>{{post.acf.portada.titulo}}</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- 
<section class="servicios">
    <div class="container">
        <div class="row d-flex justify-content-center">

            <div class="col-12 col-lg-3 contentItem wow fadeInDown" *ngFor="let post of posts; let i=index" [attr.data-wow-delay]="'3.'+i+'s'">
                <div class="item-servicio" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                  <div class="hoverContent  h-100" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                    <div class="hoverContentTxt w-100 my-auto ">
                      <h2 [innerHTML]="post.acf.portada.titulo"></h2>
                      <p [innerHTML]="post.acf.portada.descripcion"></p>
                      <a routerLink="/{{post.slug}}"><div class="boton">Más información</div></a>
                    </div>
    
                  </div>
                  <h3>{{post.acf.portada.titulo}}</h3>
                </div>
            </div>

        </div>
    </div>
</section>
-->