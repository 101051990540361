import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SessionStorageService {
    constructor() {
        this.isHome$ = new BehaviorSubject(true);
        this.isRegister$ = new BehaviorSubject(true);
    }
    isHome() {
        return this.isHome$.asObservable();
    }
    setHome() {
        this.isHome$.next(true);
    }
    clearHome() {
        this.isHome$.next(false);
    }
    isRegister() {
        return this.isRegister$.asObservable();
    }
    setRegister() {
        this.isRegister$.next(true);
    }
    clearRegister() {
        this.isRegister$.next(false);
    }
}
SessionStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionStorageService_Factory() { return new SessionStorageService(); }, token: SessionStorageService, providedIn: "root" });
