<ng-template [ngIf]="post">

    <section  class="headerInterior" [ngStyle]="{'background-image': 'url(' + post.better_featured_image?.source_url + ')'}"></section>  



    <section class="blog">
        <div class="container">
            <div class="row">
                <div class="col-12 order-last order-lg-0 col-lg-3">
                    <ng-container *ngFor="let categoria of post.categories; let i=index">
                        <ng-container *ngIf="i == 0">
                            <app-side-categorias [slugCategoria]="categoria.category_name"></app-side-categorias>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-12 col-lg-9">
                    <div class="row">
    
    
    
                        <div class="col-12 itemNota">
                            <div class="titulo" >
                                <span class="catItem" *ngFor="let categoria of post.categories"> {{categoria.category_name}} </span>
                            </div>
                            <div class="notaInterior">
                                <h2 [innerHTML]='post.title.rendered'></h2>
                                <span class="fecha"><i class="fa fa-calendar" aria-hidden="true"></i> {{post.date_gmt | date:'fullDate' }}</span>   
                                <div [innerHTML]='post.content.rendered' class="contenidoNota"></div> 
                                <div class="contentShare">
                                    <button shareButton="facebook" class="btnFacebook"></button>
                                    <button shareButton="twitter" class="btnTwitter"></button>
                                    <button shareButton="linkedin" class="btnlinkedin"></button>
                                    <button shareButton="whatsapp" class="btnWhatsapp"></button>
                                </div>
                            </div> 

                        </div>
    
    
    
                    </div>
    
                </div>
            </div>
        </div>
    </section>

</ng-template> 


<app-nav-relacionadas></app-nav-relacionadas>
