<!-- NAV BAR PLANES -->
<div *ngIf="post" id="planesSingle">
  <section class="resumen-plan">
    <div class="row">
      <!-- <div [ngClass]="{'aaaa': myCarousel?.activeId == '0'}" class="col-12 col-lg-6 p-0 slider " > -->
      <div class="col-12 col-lg-6 p-0 slider">
        <ng-container *ngIf="mobile; else sliderDesktop">
          <ngb-carousel
            [showNavigationArrows]="post.acf.slider.slide.length > 1"
            [showNavigationIndicators]="post.acf.slider.slide.length > 1"
            interval="7500"
            [keyboard]="true"
            [pauseOnHover]="true"
            [wrap]="true"
            [activeId]="'myCarousel'"
            *ngIf="post.acf.slider"
          >
            <ng-template
              ngbSlide
              *ngFor="let slide of post.acf.slider.slide; let i = index"
            >
              <div
                class="img d-block h-100 w-100"
                [ngStyle]="{
                  'background-image': 'url(' + slide.imagen_mobile + ')'
                }"
              ></div>
              <div
                class="img d-block h-100 w-100"
                [ngStyle]="{
                  'background-image': 'url(' + slide.imagen_mobile + ')'
                }"
              ></div>
              <div class="descripcion">
                <h2
                  *ngIf="slide.titulo"
                  [innerHTML]="slide.titulo"
                  class="slide-left"
                ></h2>
                <p
                  *ngIf="slide.bajada"
                  [innerHTML]="slide.bajada"
                  class="slide-left"
                ></p>
              </div>
            </ng-template>
          </ngb-carousel>
        </ng-container>
        <ng-template #sliderDesktop>
          <ngb-carousel
            [showNavigationArrows]="post.acf.slider.slide.length > 1"
            [showNavigationIndicators]="post.acf.slider.slide.length > 1"
            interval="7500"
            [keyboard]="true"
            [pauseOnHover]="true"
            [wrap]="true"
            [activeId]="'myCarousel'"
            *ngIf="post.acf.slider"
          >
            <ng-template
              ngbSlide
              *ngFor="let slide of post.acf.slider.slide; let i = index"
            >
              <div
                class="img d-block h-100 w-100"
                [ngStyle]="{ 'background-image': 'url(' + slide.imagen + ')' }"
              ></div>
              <div
                class="img d-block h-100 w-100"
                [ngStyle]="{ 'background-image': 'url(' + slide.imagen + ')' }"
              ></div>
              <div class="descripcion">
                <h2
                  *ngIf="slide.titulo"
                  [innerHTML]="slide.titulo"
                  class="slide-left"
                ></h2>
                <p
                  *ngIf="slide.bajada"
                  [innerHTML]="slide.bajada"
                  class="slide-left"
                ></p>
              </div>
            </ng-template>
          </ngb-carousel>
        </ng-template>
      </div>

      <div class="flotanteContratar">
        <a
          *ngIf="!post.acf.resumen.deshabilitar_contratar_plan"
          href="{{ environment.API_BASE_URL }}/gestion/ecommerce/{{ post.slug }}"
          >Contratar Plan</a
        >
      </div>

      <div class="col-12 col-lg-6 my-auto">
        <div class="contentTxt">
          <h2 *ngIf="post.acf.resumen.top_titulo_derecha">
            <strong>{{ post.acf.resumen.top_titulo_derecha }}</strong>
          </h2>
          <p *ngIf="post.acf.resumen.titulo_derecha">
            <strong>{{ post.acf.resumen.titulo_derecha }}</strong>
          </p>
          <div
            class="txt"
            [innerHTML]="transform(post.acf.resumen.bajada)"
          ></div>
          <p class="contentLink">
            <a
              *ngIf="!post.acf.resumen.deshabilitar_cotizar"
              (click)="openModalCotizar(mymodal)"
              >Cotizar</a
            >
            <a
              *ngIf="!post.acf.resumen.deshabilitar_comparar"
              (click)="getPrecios()"
              href="#"
              data-toggle="modal"
              data-target="#modalPlanesSingle"
              >Comparar</a
            >
          </p>
          <p *ngIf="!post.acf.deshabilitar_servicios" class="linkAnchor">
            <a [ngx-scroll-to]="'#anchor1'">Ver servicios incluidos</a>
          </p>
        </div>
      </div>
    </div>
  </section>
  <section id="anchor1"></section>
  <section class="servicios-incluidos">
    <div class="row" *ngIf="!post.acf.deshabilitar_servicios">
      <div class="col-12"><h2>Servicios incluidos</h2></div>
    </div>

    <div class="container" *ngIf="!post.acf.deshabilitar_servicios">
      <div class="row d-flex justify-content-center">
        <div
          class="col-12 col-lg-3 contentItem"
          *ngFor="let servicios of post.acf.servicios"
        >
          <div
            class="item-servicio"
            [ngStyle]="{ 'background-image': 'url(' + servicios.icono + ')' }"
          >
            <div
              class="hoverContent h-100"
              [ngStyle]="{ 'background-image': 'url(' + servicios.icono + ')' }"
            >
              <div class="hoverContentTxt w-100 my-auto">
                <h2 [innerHTML]="servicios.titulo"></h2>
                <p [innerHTML]="servicios.bajada"></p>
                <a *ngIf="servicios.link" href="{{ servicios.link }}"
                  ><div class="boton">{{ servicios.label }}</div></a
                >
              </div>
            </div>
            <h3>{{ servicios.titulo }}</h3>
          </div>
        </div>

        <div
          *ngIf="post.acf.servicio_recomendado.habilitar"
          [ngClass]="{
            'col-lg-3': post.acf.servicio_recomendado.medida == '25%',
            'col-lg-4': post.acf.servicio_recomendado.medida == '33%',
            'col-lg-6': post.acf.servicio_recomendado.medida == '50%',
            'col-lg-8': post.acf.servicio_recomendado.medida == '66%',
            'col-lg-9': post.acf.servicio_recomendado.medida == '75%',
            'col-lg-12': post.acf.servicio_recomendado.medida == '100%'
          }"
          class="col-12 data"
        >
          <div class="contentData">
            <div class="contentTxt">
              <img
                *ngIf="post.acf.servicio_recomendado.imagen"
                class="imgDesk"
                src="{{ post.acf.servicio_recomendado.imagen }}"
                alt=""
              />
              <img
                *ngIf="post.acf.servicio_recomendado.imagen_mobile"
                class="imgMobile"
                src="{{ post.acf.servicio_recomendado.imagen_mobile }}"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="ventajas" *ngIf="post.acf.adicionales.habilitar">
      <div class="row">
        <div *ngFor="let listado of post.acf.adicionales.listado" class="col">
          <h2>{{ listado.titulo }}</h2>
          <ul>
            <li *ngFor="let item of listado.item">
              {{ item.dato }}
            </li>
          </ul>
        </div>
      </div>
    </section>

    <div class="row">
      <div class="container">
        <div class="col-12 contentItem aclaraciones">
          <div class="">
            <p *ngIf="post.acf.espicificaciones">
              {{ post.acf.espicificaciones }}
            </p>
            <a
              *ngIf="post.acf.legales.texto"
              data-toggle="modal"
              data-target="#modalLegales"
              class="legales"
              >{{ post.acf.legales.label }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-nav-planes></app-nav-planes>

  <!-- Modal -->
  <div
    class="modal fade bd-example-modal-lg"
    id="modalPlanesSingle"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="mobile; else comparativaDesktop">
            <div class="comparativaMobile">
              <div class="row titulos">
                <div class="col col-lg-5 my-auto">
                  <span>Servicios según su plan</span>
                </div>
                <div class="col my-auto"><span>Plan One</span></div>
                <div class="col my-auto"><span>Plan Mix</span></div>
                <div class="col my-auto"><span>Plan Plus</span></div>
              </div>
              <div class="row filas">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Emergencias y urgencias médicas 24hs</span>
                </div>
                <div class="col-3 my-auto">
                  <span>Sin tope/Sin límite</span>
                </div>
                <div class="col-3 my-auto">
                  <span>Sin tope/Sin límite</span>
                </div>
                <div class="col-3 my-auto">
                  <span>Sin tope/Sin límite</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Médico a domicilio 24hs</span>
                </div>
                <div class="col-3 my-auto"><span class="icon no"></span></div>
                <div class="col-3 my-auto">
                  <span class="icon ok-concopago">con cargo adicional</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Médico de familia | vittaldoc</span>
                </div>
                <div class="col-3 my-auto"><span class="icon no"></span></div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Consultas médicas online</span>
                </div>
                <div class="col-3 my-auto"><span class="icon no"></span></div>
                <div class="col-3 my-auto">
                  <span class="icon ok-concopago">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Odontología de urgencia</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-concopago">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-concopago">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Asistencia internacional al viajero</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Cartilla médica online</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Descuentos en farmacias, ópticas y ortopedias</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row resultados">
                <div class="col-3 col-lg-5 my-auto">
                  <span>Precio final por persona desde:</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="result">{{ planes[4]?.precio }}</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="result">{{ planes[2]?.precio }}</span>
                </div>
                <div class="col-3 my-auto">
                  <span class="result">{{ planes[1]?.precio }}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #comparativaDesktop>
            <div class="comparativaMobile">
              <div class="row titulos">
                <div class="col col-lg-5 my-auto">
                  <span>Servicios según su plan</span>
                </div>
                <div class="col my-auto"><span>Plan One</span></div>
                <div class="col my-auto"><span>Plan Mix</span></div>
                <div class="col my-auto"><span>Plan Plus</span></div>
              </div>
              <div class="row filas">
                <div class="col col-lg-5 my-auto">
                  <span>Emergencias y urgencias médicas 24hs</span>
                </div>
                <div class="col my-auto"><span>Sin tope/Sin límite</span></div>
                <div class="col my-auto"><span>Sin tope/Sin límite</span></div>
                <div class="col my-auto"><span>Sin tope/Sin límite</span></div>
              </div>
              <div class="row filas">
                <div class="col col-lg-5 my-auto">
                  <span>Médico a domicilio 24hs</span>
                </div>
                <div class="col my-auto"><span class="icon no"></span></div>
                <div class="col my-auto">
                  <span class="icon ok-concopago">con cargo adicional</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col col-lg-5 my-auto">
                  <span>Médico de familia vittalIdoc</span>
                </div>
                <div class="col my-auto"><span class="icon no"></span></div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col col-lg-5 my-auto">
                  <span>Consultas médicas online</span>
                </div>
                <div class="col my-auto"><span class="icon no"></span></div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col col-lg-5 my-auto">
                  <span>Odontología de urgencia</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col col-lg-5 my-auto">
                  <span>Asistencia internacional al viajero</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col col-lg-5 my-auto">
                  <span>Cartilla médica online</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row filas">
                <div class="col col-lg-5 my-auto">
                  <span>Descuentos en farmacias, ópticas y ortopedias</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
                <div class="col my-auto">
                  <span class="icon ok-sincargo">sin cargo</span>
                </div>
              </div>
              <div class="row resultados">
                <div class="col col-lg-5 my-auto">
                  <span>Precio final por persona desde:</span>
                </div>
                <div class="col my-auto">
                  <span class="result">${{ planes[4]?.precio }}</span>
                </div>
                <div class="col my-auto">
                  <span class="result">${{ planes[2]?.precio }}</span>
                </div>
                <div class="col my-auto">
                  <span class="result">${{ planes[1]?.precio }}</span>
                </div>
                <!-- <div class="col my-auto"><span class="result">{{planes[3]?.precio}}</span></div>
                  <div class="col my-auto"><span class="result">{{planes[0]?.precio}}</span></div> -->
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade bd-example-modal-lg"
    id="modalLegales"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header" style="height: 23px">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="modal-body"
          [innerHTML]="transform(post.acf.legales.texto)"
          style="height: 500px; overflow-y: scroll"
        ></div>
      </div>
    </div>
  </div>

  <ng-template #mymodal let-modal>
    <div class="modal-header" style="padding: 15px 0 0 0">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container>
        <div class="row" id="formCotizar">
          <div
            class="col-12 col-lg-4 contentImg"
            style="background-image: url('assets/img/pic-cotizar.png')"
          ></div>
          <div class="col-12 col-lg-8 boxForm">
            <app-form-cotizadores
              [tituloForm]="'¡Cotizá tu plan ideal!'"
              [codePrice]="post.acf.precio"
              [planName]="post.acf.portada.titulo"
              [codeForm]="post.acf.codigo_form"
              [isMobile]="mobile"
              [isBitrix]="isBitrix"
            ></app-form-cotizadores>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <!-- <app-cotizacion [codePricePlan]="post.acf.precio" [codeFormPlan]="post.acf.codigo_form"></app-cotizacion> -->
</div>
