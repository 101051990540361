<section class="headerEmpresas">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9">
                <h2>Gestiones Online</h2>
                <p>
                    Bienvenido <strong>{{datosEmpresa.empresa_nombre}}</strong> <br>
                    Cuit: <strong>{{datosEmpresa.empresa_cuit}}</strong> <br>          
                    <a routerLink="/atencion-al-socio/gestion-online/empresas/perfil-empresa">VER MIS DATOS</a><br>
                    <a routerLink="/" (click)='logout()'>CERRAR SESIÓN</a> <br>
                    <a routerLink="/atencion-al-socio/gestion-online/empresas" class="topVolver">Volver</a>

                </p>
            </div>
        </div>
    </div>
</section>
<section class="pedido">
    <div class="container">
        <div class="content">
            <h2><img src="assets/img/icon-socios-01.png" alt=""> Facturación</h2>
            
            <ul>
                <div *ngFor="let factura of facturas; let i = index">
                    <li *ngIf="i == 0" class="noDisponible">
                        <div class="fecha"><strong>{{getMonth(factura.fecha)}}</strong> {{getYear(factura.fecha)}}</div>
                        <div class="download">EN GESTIÓN DE COBRANZA</div>
                        <div class="clearfix"></div>
                    </li>
                    <li *ngIf="i != 0">
                        <div class="fecha"><strong>{{getMonth(factura.fecha)}}</strong> {{getYear(factura.fecha)}}</div>
                        <div class="download">
                            <a (click)="obtenerFacturaPDF(factura.letra, factura.numero)">Descargar PDF <img src="assets/img/icon-download-pdf.png" alt=""/></a>
                        </div>
                        <div class="clearfix"></div>
                    </li>    
                </div>
            </ul>
            
            <div class="clearfix"></div>

        </div>
    </div>
</section>

<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>