<div class="boxForm contacto" id="contentAll">
  <form action="" [formGroup]="contactForm" (ngSubmit)="submitForm(codeForm)">
    <div class="row m-0">
      <div class="col-12">
        <h2>{{ tituloForm }}</h2>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ': nombre.invalid && (nombre.dirty || nombre.touched)
        }"
      >
        <input type="text" formControlName="nombre" id="nombre" />
        <span
          [ngClass]="{
            spanInvalido: nombre.invalid && (nombre.dirty || nombre.touched)
          }"
          >Nombre *</span
        >
        <div
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"
          class="invalido"
        >
          <div *ngIf="nombre.errors.required">El nombre es requerido</div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            apellido.invalid && (apellido.dirty || apellido.touched)
        }"
      >
        <input type="text" formControlName="apellido" id="apellido" />
        <span
          [ngClass]="{
            spanInvalido:
              apellido.invalid && (apellido.dirty || apellido.touched)
          }"
          >Apellido *</span
        >
        <div
          *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)"
          class="invalido"
        >
          <div *ngIf="apellido.errors.required">El apellido es requerido</div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ': email.invalid && (email.dirty || email.touched)
        }"
      >
        <input type="text" formControlName="email" id="email" />
        <span
          [ngClass]="{
            'spanInvalido ': email.invalid && (email.dirty || email.touched)
          }"
          >Email *</span
        >

        <div
          *ngIf="email.invalid && (email.dirty || email.touched)"
          class="invalido"
        >
          <div *ngIf="email.errors.required">El email es requerido.</div>
          <div *ngIf="email.errors.email">
            Debe colocar una direccion de email valida.
          </div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            telefono.invalid && (telefono.dirty || telefono.touched)
        }"
      >
        <input type="tel" id="telefono" formControlName="telefono" />
        <span
          [ngClass]="{
            'spanInvalido ':
              telefono.invalid && (telefono.dirty || telefono.touched)
          }"
          >Teléfono *</span
        >

        <div
          *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)"
          class="invalido"
        >
          <div *ngIf="telefono.errors.required">El telefono es requerido.</div>
          <div *ngIf="telefono.invalid">Debe colocar un telefono valido.</div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            localidad.invalid && (localidad.dirty || localidad.touched)
        }"
      >
        <input type="text" formControlName="localidad" id="localidad" />
        <span
          [ngClass]="{
            spanInvalido:
              localidad.invalid && (localidad.dirty || localidad.touched)
          }"
          >Localidad *</span
        >
        <div
          *ngIf="localidad.invalid && (localidad.dirty || localidad.touched)"
          class="invalido"
        >
          <div *ngIf="localidad.errors.required">
            La localidad es requerida.
          </div>
        </div>
      </div>

      <div class="col-12 contentButton">
        <p>
          <button
            (click)="sendForm()"
            [ngx-scroll-to]="'#sendOk'"
            [disabled]="contactForm.invalid"
          >
            Enviar
          </button>
        </p>
      </div>
    </div>
  </form>

  <div class="placaEnviado">
    <div class="row h-100 m-0">
      <div class="col-12 my-auto">
        <div>
          <h2>Gracias por contactarnos</h2>
          <p>Nos comunicaremos a la brevedad<br /><br /><br /></p>
          <p>
            <a href="{{ environment.API_BASE_URL }}" target="_blank"
              ><button class="otraConsulta">Ver sitio web</button></a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
