/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./menu/menu.component.ngfactory";
import * as i3 from "./data-wp.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./menu/menu.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./footer/footer.component.ngfactory";
import * as i8 from "./footer/footer.component";
import * as i9 from "@angular/forms";
import * as i10 from "ngx-ui-loader";
import * as i11 from "@angular/common";
import * as i12 from "@angular/router";
import * as i13 from "../../node_modules/ngx-ui-loader/ngx-ui-loader.ngfactory";
import * as i14 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-menu", [], null, null, null, i2.View_MenuComponent_0, i2.RenderType_MenuComponent)), i1.ɵprd(512, null, i3.DataWpService, i3.DataWpService, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i5.MenuComponent, [i3.DataWpService, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i8.FooterComponent, [i9.FormBuilder, i4.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["blur", "5"], ["class", "loader"], ["fgsColor", "red"], ["ngxUiLoaderBlurred", ""]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i10.ɵc, [i1.ElementRef, i1.Renderer2, i10.NgxUiLoaderService], { blur: [0, "blur"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ngx-ui-loader", [], null, null, null, i13.View_ɵb_0, i13.RenderType_ɵb)), i1.ɵdid(9, 770048, null, 0, i10.ɵb, [i6.DomSanitizer, i1.ChangeDetectorRef, i10.NgxUiLoaderService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "5"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showTemplate; _ck(_v, 3, 0, currVal_1); _ck(_v, 5, 0); var currVal_2 = _co.showTemplate; _ck(_v, 7, 0, currVal_2); _ck(_v, 9, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i14.AppComponent, [i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
