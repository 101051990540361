<ng-container *ngFor="let post of posts$ | async">

    <div id="planesAreas">
    
    
        <!-- FOTO + BULLET -->
        <ng-container *ngIf="post.acf.tipo_de_plantilla =='template1'">
            <section class="servicios-single" id="template1Empresas">
                <div class="row m-0 h-100" >
                    <div class="col-12 col-lg-6 contentLeft p-5" [ngStyle]="{'background-image':'url(' + post.acf.foto_bajada.fondo + ')'}">
                        <div class="row m-0 h-100">
                            <div class="col-12 py-5">
                                <h2 [innerHTML]='post.acf.foto_bajada.titulo'></h2>
                                <p [innerHTML]='post.acf.foto_bajada.subtitulo'></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 contentRight p-5" >
                        <div class="content" [innerHTML]="post.acf.foto_bajada.bajada"></div>            
                    </div>
                </div>
            </section>
        </ng-container>
    
        <!-- SECCIONES -->
        <div id="template2Empresas">
            <ng-container *ngIf="post.acf.tipo_de_plantilla =='template2'">
                <section class="cobertura header3"  [ngStyle]="{'background-image':'url(' + post.acf.header.fondo + ')'}">
                    <div class="container containerHeader">
                        <div class="row h-100">
                            <div class="col-12 col-lg-6 my-auto">
                                <h2 [innerHTML]="post.acf.header.titulo"></h2>
                                <p [innerHTML]="post.acf.header.bajada"></p>
                            </div> 
                        </div>
                    </div>
                </section>
                <ng-container *ngFor="let secciones of post.acf.secciones">            
                    <section class="cobertura box2col box2colImg">
                        <div class="container">
                            <div class="box" style="background-image: url(assets/img/back-box-cobertura.jpg);">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <h2 [innerHTML]="secciones.introduccion.titulo"></h2>
                                        <p [innerHTML]="secciones.introduccion.bajada"></p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <img src="{{ secciones.introduccion.foto }}" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="cobertura servicios">
                        <div class="container content-servicios">
                            <h2 *ngIf="secciones.servicios.titulo" class="topServicios"  [innerHTML]="secciones.servicios.titulo"></h2>
                            <p *ngIf="secciones.servicios.subtitulo" class="topServicios" [innerHTML]="secciones.servicios.subtitulo"></p>
                            <div class="contentItems">            
                                <div class="row justify-content-center">
                                    <div *ngFor="let box_servicio of secciones.servicios.box_servicio" class="col-12 col-lg-6" >
                                        <div class="contentItem">
                                            <img src="{{ box_servicio.imagen }}" alt="" width="100%">
                                            <div class="contentTitle">
                                                <h2 [innerHTML]="box_servicio.titulo"></h2>
                                            </div>
                                            <div class="contentTxt">
                                                <p [innerHTML]="box_servicio.bajada"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>    
                </ng-container>
            </ng-container>
        </div>
    
    
            
        
    
    </div>
    
</ng-container>    


