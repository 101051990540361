
<ng-container *ngIf="post">
  <section  class="servicios-single">
      <div class="row m-0 h-100" >
          <div *ngIf="!mobile" class="col-12 col-lg-6 contentLeft p-5" [ngStyle]="{'background-image': 'url(' + post.acf.interior.imagen_fondo + ')'}"  >
              <div class="row m-0 h-100">
                  <div class="col-12 py-5">
                      <h2 [innerHTML]="post.acf.interior.titulo"></h2>
                      <p [innerHTML]="post.acf.interior.subtitulo"></p>
                  </div>
              </div>
          </div>
          <div *ngIf="mobile"  class="col-12 col-lg-6 contentLeft p-5" [ngStyle]="{'background-image': 'url(' + post.acf.interior.imagenMobile + ')'}"  >
            <div class="row m-0 h-100">
                <div class="col-12 py-5">
                    <h2 [innerHTML]="post.acf.interior.titulo"></h2>
                    <p [innerHTML]="post.acf.interior.subtitulo"></p>
                </div>
            </div>
        </div>
          <div class="col-12 col-lg-6 contentRight p-5" >
              <div class="content" [innerHTML]="post.acf.interior.contenido"></div>
              <p class="contentLink" *ngIf="post.acf.interior.link.url">
                  <a  href="{{post.acf.interior.link.url}}">{{post.acf.interior.link.label}}</a>
              </p>
              
          </div>
      </div>
  </section>

  <div *ngIf="post" class="row m-0" id="legalesPromos">
      <div class="container">
        <div class="col-12 contentItem aclaraciones">
            <div class="">
              <a *ngIf="post.acf.interior.legales"  data-toggle="modal" data-target="#modalLegales" class="legales">Ver legales</a>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal LEGALES -->
    <div class="modal fade bd-example-modal-lg" id="modalLegales" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header" style="height: 23px;">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" [innerHTML]="transform(post.acf.interior.legales)" style="height: 500px; overflow-y: scroll;"></div>

        </div>
      </div>
    </div>

    <app-nav-promos [slugId]='post.slug'></app-nav-promos>  
    
</ng-container>