import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DopplerService {
    constructor(http) {
        this.http = http;
        this.apiUrl = environment.dopplerApiUrl;
    }
    submitForm(formData) {
        return this.http.post(`${this.apiUrl}/subscribers`, {
            email: formData.email,
            fields: {
                NOMBRE: formData.nombre,
                APELLIDO: formData.apellido,
                TELEFONO: formData.telefono,
                LOCALIDAD: formData.localidad
            }
        });
    }
}
DopplerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DopplerService_Factory() { return new DopplerService(i0.ɵɵinject(i1.HttpClient)); }, token: DopplerService, providedIn: "root" });
