<div class="contentModalGral">
    <div class="row titulos" >
        <div class="col-12">
            <h2><img src="assets/img/icon-socios-06.png" alt="">Solicitud de credencial</h2>
        </div>        
    </div>
    <form class="formSolicitarCredencial" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="row formulario">
            <mat-radio-group aria-label="Select an option" formControlName='radiobutton'>
                <mat-radio-button value="1" (change)='radioValue($event)' id="radio1">
                    <div class="col-12">
                        <div class="contentField">
                            <label style="white-space: initial;">
                                ENVIAR A: {{direccionSocio}}
                            </label>                        
                        </div>                
                    </div>
                </mat-radio-button>
                <mat-radio-button value="2" (change)='radioValue($event)' id="radio2" style="width: 100%;">
                    <div class="col-12" [ngClass]="{'campoInvalido ': dirNueva.invalid && (dirNueva.dirty || dirNueva.touched)  }" >
                        <input type="text" formControlName="dirNueva"  id="dirNueva"  style="width: 100%;">
                        <span [ngClass]="{'spanInvalido ': dirNueva.invalid && (dirNueva.dirty || dirNueva.touched)  } ">Nueva dirección</span>
                        <div *ngIf="dirNueva.invalid && (dirNueva.dirty || dirNueva.touched)" class="invalido">
                            <div *ngIf="dirNueva.errors.required">
                                La nueva direccion es requerida es requerida.
                            </div>
                        </div>  
                    </div>
                </mat-radio-button>
              </mat-radio-group>

            <div  class="col-12 col-lg-8 boxObligatoriosForms">
                <p><small>* Campos obligatorios</small></p>
            </div>
            <div class="col-12 col-lg-4 contentButton">
                <button [disabled]="contactForm.invalid" >ENVIAR</button>
            </div>  
        </div>
    </form>

</div>

<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>
