<div class="contentModalGral px-3 pt-4">
    <div class="row titulos " >
        <div class="col-12 px-4">
            <h2><img src="assets/img/icon-socios-04.png" alt="">Extensión de Área Protegida</h2>
        </div>        
    </div>
    <form class="formSolicitarCredencial" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="row justify-content-end formulario">
            <div class="col-12 col-lg-6">
                <div class="row ">
                    <div class="col-12" [ngClass]="{'campoInvalido ': fecha.invalid && (fecha.dirty || fecha.touched)  }" >
                        <input type="date" formControlName="fecha" id="fecha" [min]="hoy" cdkTouchInitial>
                        <span [ngClass]="{'spanInvalido ': fecha.invalid && (fecha.dirty || fecha.touched)  } ">Fecha *</span>
                        <div *ngIf="fecha.invalid && (fecha.dirty || fecha.touched)" class="invalido">
                            <div *ngIf="fecha.errors.required">
                                La fecha es requerida.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': direccion.invalid && (direccion.dirty || direccion.touched)  }" >
                        <input type="text" formControlName="direccion" id="direccion" cdkTouchInitial>
                        <span [ngClass]="{'spanInvalido ': direccion.invalid && (direccion.dirty || direccion.touched)  } ">Dirección *</span>
                        <div *ngIf="direccion.invalid && (direccion.dirty || direccion.touched)" class="invalido">
                            <div *ngIf="direccion.errors.required">
                                La dirección es requerida.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': cantidadPersonas.invalid && (cantidadPersonas.dirty || cantidadPersonas.touched)  }" >
                        <input type="number" formControlName="cantidadPersonas" id="cantidadPersonas" cdkTouchInitial>
                        <span [ngClass]="{'spanInvalido ': cantidadPersonas.invalid && (cantidadPersonas.dirty || cantidadPersonas.touched)  } ">Cantidad de Personas *</span>
                        <div *ngIf="cantidadPersonas.invalid && (cantidadPersonas.dirty || cantidadPersonas.touched)" class="invalido">
                            <div *ngIf="cantidadPersonas.errors.required">
                                La cantidad de personas es requerida.
                            </div>
                            <div *ngIf="cantidadPersonas.errors.min">
                                La cantidad de personas debe ser como mínimo 1.
                            </div> 
                        </div>  
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row  ">
                    <div class="col-12" [ngClass]="{'campoInvalido ': horario.invalid && (horario.dirty || horario.touched)  }" >
                        <input type="text" formControlName="horario" id="horario">
                        <span [ngClass]="{'spanInvalido ': horario.invalid && (horario.dirty || horario.touched)  } ">Horario *</span>
                        <div *ngIf="horario.invalid && (horario.dirty || horario.touched)" class="invalido">
                            <div *ngIf="horario.errors.required">
                                El horario es requerido.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': localidad.invalid && (localidad.dirty || localidad.touched)  }" >
                        <input type="text" formControlName="localidad" id="localidad">
                        <span [ngClass]="{'spanInvalido ': localidad.invalid && (localidad.dirty || localidad.touched)  } ">Localidad *</span>
                        <div *ngIf="localidad.invalid && (localidad.dirty || localidad.touched)" class="invalido">
                            <div *ngIf="localidad.errors.required">
                                La localidad es requerida.
                            </div>
                        </div>  
                    </div>
                </div>                
            </div> 
            <div class="col-12" [ngClass]="{'campoInvalido ': comentario.invalid && (comentario.dirty || comentario.touched)  }" >
                <textarea type="text" formControlName="comentario" id="comentario"></textarea>
                <span [ngClass]="{'spanInvalido ': comentario.invalid && (comentario.dirty || comentario.touched)  } ">Escribí aquí tu comentario </span>
                <div *ngIf="comentario.invalid && (comentario.dirty || comentario.touched)" class="invalido">
                    <div *ngIf="comentario.errors.required">
                        El Comentario es requerido.
                    </div>
                </div>  
            </div>
            <div  class="col-12 col-lg-8 boxObligatoriosForms">
                <p><small>* Campos obligatorios</small></p>
            </div>
            <div class="col-12 col-lg-4 contentButton">
                <button [disabled]="contactForm.invalid" >ENVIAR</button>
            </div>  
        </div>
    </form>

</div>
<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4 style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>