<div *ngIf="!isMobile">
<section class="banner" *ngFor="let post of posts$ | async" [ngStyle]="{'background-image':'url(' + post.acf.imagen_fondo.url + ')'}">
    <div class="fondo"></div>
    <div class="container h-100 ">
        <div class="row h-100">
            <div class="col my-auto">
                <h2 [innerHTML]='post.acf.titulo'></h2>
                <p class="bajadaDes" [innerHTML]='post.acf.bajada'></p>
                <p class="contentLink"><a href="#" routerLink="/{{post.acf.link}}">MÁS INFORMACIÓN</a></p>                
            </div>
        </div>
    </div>
</section>
</div>

<div *ngIf="isMobile">
<section class="banner" *ngFor="let post of posts$ | async" [ngStyle]="{'background-image':'url(' + post.acf.imagenmobile + ')'}">
    <div class="fondo"></div>
    <div class="container h-100 ">
        <div class="row h-100">
            <div class="col my-auto">
                <h2 [innerHTML]='post.acf.titulo'></h2>
                <p class="bajadaDes" [innerHTML]='post.acf.bajada'></p>
                <p class="contentLink"><a href="#" routerLink="/{{post.acf.link}}">MÁS INFORMACIÓN</a></p>                
            </div>
        </div>
    </div>
</section>
</div>