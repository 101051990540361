/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./validacion-socios.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./validacion-socios.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common/http";
var styles_ValidacionSociosComponent = [i0.styles];
var RenderType_ValidacionSociosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValidacionSociosComponent, data: {} });
export { RenderType_ValidacionSociosComponent as RenderType_ValidacionSociosComponent };
export function View_ValidacionSociosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "padding: 300px;"]], null, null, null, null, null))], null, null); }
export function View_ValidacionSociosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-validacion-socios", [], null, null, null, View_ValidacionSociosComponent_0, RenderType_ValidacionSociosComponent)), i1.ɵdid(1, 114688, null, 0, i2.ValidacionSociosComponent, [i3.ActivatedRoute, i4.HttpClient, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValidacionSociosComponentNgFactory = i1.ɵccf("app-validacion-socios", i2.ValidacionSociosComponent, View_ValidacionSociosComponent_Host_0, {}, {}, []);
export { ValidacionSociosComponentNgFactory as ValidacionSociosComponentNgFactory };
