/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./planes.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../data-wp.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./planes.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/platform-browser";
var styles_PlanesComponent = [i0.styles];
var RenderType_PlanesComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PlanesComponent, data: {} });
export { RenderType_PlanesComponent as RenderType_PlanesComponent };
function View_PlanesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "back"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "gradient1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "gradient2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.parent.parent.context.$implicit.acf.portada.imagen_fondo) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PlanesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "back"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "gradient1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "gradient2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.parent.parent.context.$implicit.acf.portada.imagen_fondo_mobile) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PlanesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "section-plan"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "promos": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 10, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contratar(_v.parent.context.$implicit.slug) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanesComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanesComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "p", [["class", "contentLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contratar(_v.parent.context.$implicit.slug) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CONTRATAR"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "section-plan"; var currVal_1 = _ck(_v, 3, 0, _v.parent.context.$implicit.acf.portada.promo); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.mobile; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.mobile; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit.acf.portada.titulo; _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.parent.context.$implicit.acf.portada.bajada; _ck(_v, 11, 0, currVal_5); }); }
function View_PlanesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content-plan"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanesComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.acf.portada.plan_oculto; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PlanesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "planes"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanesComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PlanesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-planes", [], null, null, null, View_PlanesComponent_0, RenderType_PlanesComponent)), i1.ɵprd(512, null, i3.DataWpServicePlanesChild, i3.DataWpServicePlanesChild, [i4.HttpClient]), i1.ɵprd(512, null, i3.DataWpServicePlanes, i3.DataWpServicePlanes, [i4.HttpClient]), i1.ɵdid(3, 114688, null, 0, i5.PlanesComponent, [i3.DataWpServicePlanesChild, i3.DataWpServicePlanes, i6.Router, i7.Title, i7.Meta], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var PlanesComponentNgFactory = i1.ɵccf("app-planes", i5.PlanesComponent, View_PlanesComponent_Host_0, {}, {}, []);
export { PlanesComponentNgFactory as PlanesComponentNgFactory };
