<section class="headerComponent" *ngFor="let post of posts" >


    <ngb-carousel [showNavigationArrows]="post.acf.header.slider.length > 1" [showNavigationIndicators]="post.acf.header.slider.length > 1" interval="7500" [keyboard]="true" [pauseOnHover]="true" [wrap]="true" [activeId]="'secondSlide'" *ngIf="post.acf.header.slider" >
        <ng-template ngbSlide *ngFor="let slider of post.acf.header.slider; let i = index">
            
                <div *ngIf="!isMobile" class="img" [ngStyle]="{'background-image': 'url(' + slider.imagen + ')'}"></div>
                <div *ngIf="isMobile" class="img" [ngStyle]="{'background-image': 'url(' + slider.imagenmobile + ')'}"></div>
                <div class="container h-100">
                    <div class="row m-0 h-100 ">
                        <div class="col-lg-6 col-12 my-auto p-0" >
                            <h2 [innerHTML]="slider.titulo" class="slide-left"></h2>
                            <p [innerHTML]="slider.bajada" class="slide-left"></p>
                        </div>            
                    </div>
                </div>    
                
            </ng-template>
        </ngb-carousel>
        
 </section>



<div class="anchor" id="anchorResultados"></div>    

<section class="resultadosCobertura" id="resultados">
    <div class="container">

        <div class="row" id="btnsItem">
            <div class="col-12 col-lg-6 my-auto "></div>
            <div class="col-12 col-lg-6 my-auto botones">
                <a [ngx-scroll-to]="'#anchorResultados'" class="btnLista active" id="btnLista" (click)="verLista()"><span>Ver lista</span></a>
                <a [ngx-scroll-to]="'#anchorResultados'" class="btnLista " id="btnMapa" (click)="verMapaList()"><span>Ver mapa</span></a>
            </div>
        </div> 
        
        <div class="row justify-content-center" id="itemsResultados" *ngFor="let post of posts" >

            <div class="col-12 col-lg-6 py-3" *ngFor="let resultado of post.acf.item" >
                <div class="itemResultado">
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div *ngIf="resultado.imagen.sizes.medium" class="imagen" [ngStyle]="{'background-image':'url(' + resultado.imagen.sizes.medium + ')'}"></div>
                            <div *ngIf="resultado.imagen_mobile" class="imagenMobile" [ngStyle]="{'background-image':'url(' + resultado.imagen_mobile + ')'}"></div>
                            <div *ngIf="!resultado.imagen_mobile" class="imagenMobile" [ngStyle]="{'background-image':'url(' + resultado.imagen.sizes.medium + ')'}"></div>
                        </div>
                        <div class="col-lg-8 col-12 pl-lg-0">

                            <div class="titulo">
                                <div class="nombre">
                                    <h2>{{resultado.nombre}}</h2>
                                </div>                        
                            </div>
                            <div class="clearfix"></div>
                            <div class="infoResultado">
                                <ul>
                                    <li *ngIf="resultado.direccion" class="dir">{{resultado.direccion}}</li>
                                    <li *ngIf="resultado.telefono_administrativo && (viewSize > 500)" class="tel">Adm. / Comercial: {{resultado.telefono_administrativo}}</li>
                                    <li *ngIf="resultado.telefono_emergencia && (viewSize > 500)" class="eme"><strong>Urgencias: {{resultado.telefono_emergencia}}</strong></li>
                                    <li *ngIf="(resultado.telefono_administrativo) && (viewSize < 500)" class="tel">Adm. / Comercial:  <a href= "tel:{{resultado.telefono_administrativo}}">{{resultado.telefono_administrativo}}</a></li>
                                    <li *ngIf="resultado.telefono_emergencia && (viewSize < 500)" class="eme"><strong>Urgencias: <a href= "tel:{{resultado.telefono_emergencia}}">{{resultado.telefono_emergencia}}</a></strong></li>
                                    <li *ngIf="resultado.email" class="mai"><a href="mailto:{{resultado.email}}">{{resultado.email}}</a></li>
                                    <li *ngIf="resultado.horario" class="hor">{{resultado.horario}}</li>
                                    <li *ngIf="resultado.web" class="web"><a href="{{resultado.web}}" target="_blank">{{resultado.web}}</a></li>
                                </ul>
                            </div>
                            
                        </div>
                        
                        <span [ngx-scroll-to]="'#anchorResultados'" class="resultados" (click)="verSingleResults( resultado.nombre, resultado.direccion, resultado.telefono_administrativo, resultado.telefono_emergencia, resultado.email, resultado.horario, resultado.lat, resultado.long )">Ver en mapa</span>
                        <span [ngx-scroll-to]="'#anchorResultadosMobile'" class="resultados mobile" (click)="verSingleResults( resultado.nombre, resultado.direccion, resultado.telefono_administrativo, resultado.telefono_emergencia, resultado.email, resultado.horario, resultado.lat, resultado.long )">Ver en mapa</span>
                        
                    </div>

                </div>
            </div>


        </div>

        <div class="anchor" id="anchorResultadosMobile"></div> 
        <section class="mapa none" id="mapaSlingle">

            <div class="contentItem">

                <div class="itemResultado">
                    <div class="titulo">
                        <div class="nombre">
                            <h2 id="nombreSingle">{{nombre}}</h2>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="infoResultado">
                        <ul>
                            <li class="dir" id="dirSingle">{{direccion}}</li>
                            <li *ngIf="telefono_administrativo && (viewSize > 500)" class="tel">Administración/Comercial: {{telefono_administrativo}}</li>
                            <li *ngIf="telefono_emergencia && (viewSize > 500)" class="eme"><strong>Urgencias: {{telefono_emergencia}}</strong></li>
                            <li *ngIf="(telefono_administrativo) && (viewSize < 500)" class="tel">Administración/Comercial:  <a href= "tel:{{telefono_administrativo}}">{{telefono_administrativo}}</a></li>
                            <li *ngIf="telefono_emergencia && (viewSize < 500)" class="eme"><strong>Urgencias: <a href= "tel:{{telefono_emergencia}}">{{telefono_emergencia}}</a></strong></li>
                            <li class="mai" id="maiSingle"><a href= "mailto:{{email}}">{{email}}</a></li>
                                                        
                        </ul>
                    </div>
                </div>

            </div> 


            <agm-map [latitude]="latSingle" [longitude]="lngSingle"  [zoom]="zoomSingle" [scrollwheel]="scrollwheel">
        
                
                <agm-marker [latitude]="latSingle" [longitude]="lngSingle" [iconUrl]="{url:'assets/img/green-dot.png'}" >
                    <agm-info-window [disableAutoPan]="true">
                        <p>
                            <span id="nombreSingleMk"></span> <br>
                            <strong>Direccion: </strong><span id="dirSingleMk">{{direccion}}</span> <br>
                            <strong>Telefono: </strong><span id="telSingleMk">{{telefono_administrativo}}</span>
                        </p> 
                    </agm-info-window>
                </agm-marker>
        
            </agm-map>
        </section>



        <section class="mapa none" id="mapaList">

            <agm-map *ngFor="let post of posts" [latitude]="lat" [longitude]="lng"  [zoom]="zoom" [scrollwheel]="scrollwheel" >
        
                
                <agm-marker *ngFor="let marker of post.acf.item" [latitude]="marker.lat" [longitude]="marker.long" [iconUrl]="{url:'assets/img/green-dot.png'}" (markerClick)="verSingleResults( marker.nombre, marker.direccion, marker.telefono_administrativo, marker.telefono_emergencia, marker.email, marker.horario, marker.lat, marker.long )"></agm-marker>
        
            </agm-map>
        </section>



    </div>
</section>
