

<section id="formRRHH">
    <section class="contacto rrhh">
        <div class="row m-0">
            <div class="col-12 col-lg-4 contentBack"   style="background-image: url('assets/img/rrhh.jpg');"></div>
            <div class="col-12 col-lg-8 px-5 contentRight">
                <div class="row contentForm">
                    <div class="col-12">
                        <h2><strong>Te invitamos a cargar tu CV para seguir sumando valor a nuestro equipo de trabajo.</strong></h2>
                    </div>
                    <div class="col-12">
                        <form  [formGroup]="contactForm" (ngSubmit)="submitForm()">
                            <div class="row">
                                <div class="col-12 col-lg-6" style="display: none;">
                                    <span id="areaSpan" class="top">Area de consulta</span>
                                    <div class="contentSelect ok" id="contentSelect" (click)="spanTop()" >
                                        <select  formControlName="area" id="area" >
                                            <option value="RRHH" selected>RRHH</option>
                                            <option value="Planes">Planes</option>
                                            <option value="Socios">Socios</option>
                                            <option value="Servicios Adicionales">Servicios Adicionales</option>
                                            <option value="Areas Protegidas">Areas Protegidas</option>
                                            <option value="Empresas">Empresas</option>                                            
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': nombres.invalid && (nombres.dirty || nombres.touched) }">
                                    <input type="text" formControlName="nombres"  id="nombres">
                                    <span [ngClass]="{'spanInvalido': nombres.invalid && (nombres.dirty || nombres.touched)}" >Nombres *</span>
                                    <div *ngIf="nombres.invalid && (nombres.dirty || nombres.touched)" class="invalido">
                                        <div *ngIf="nombres.errors.required">
                                            El nombre es requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': apellido.invalid && (apellido.dirty || apellido.touched) }">
                                    <input type="text" formControlName="apellido"  id="apellido">
                                    <span [ngClass]="{'spanInvalido': apellido.invalid && (apellido.dirty || apellido.touched)}">Apellido *</span>
                                    <div *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)" class="invalido">
                                        <div *ngIf="apellido.errors.required">
                                            El apellido es requerido
                                        </div>
                                    </div>
                                </div>   
                                <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': email.invalid && (email.dirty || email.touched)  }" >
                                    <input type="text" formControlName="email"  id="email">
                                    <span [ngClass]="{'spanInvalido ': email.invalid && (email.dirty || email.touched)  } ">Email *</span>
    
    
                                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalido">
                                        <div *ngIf="email.errors.required">
                                            El email es requerido.
                                        </div>
                                        <div *ngIf="email.errors.email">
                                            Debe colocar una direccion de email valida.
                                        </div>
                                    </div>  
                                </div>
                                <div class="col-4 col-lg-2" [ngClass]="{'campoInvalido ': telefono_car.invalid && (telefono_car.dirty || telefono_car.touched)  }">
                                    <input type="number" formControlName="telefono_car"  id="telefono_car">
                                    <span  [ngClass]="{'spanInvalido': telefono_car.invalid && (telefono_car.dirty || telefono_car.touched) }">Cod. Área*</span>
                                </div>
                                <div class="col-8 col-lg-4" [ngClass]="{'campoInvalido ': telefono_num.invalid && (telefono_num.dirty || telefono_num.touched)  }" >
                                    <input type="number" formControlName="telefono_num"  id="telefono_num">
                                    <span [ngClass]="{'spanInvalido': telefono_num.invalid && (telefono_num.dirty || telefono_num.touched) }">Teléfono*</span>
                                </div>
                                
                                <div class="col-12 offset-lg-6 col-lg-6 msjTels" >
    
    
                                    <p *ngIf="telefono_car.invalid && (telefono_car.dirty || telefono_car.touched)" class="invalido">
                                        <span *ngIf="telefono_car.errors.required">
                                            El codigo de area es requerido.
                                        </span>
                                        <span *ngIf="telefono_car.invalid">
                                            Debe colocar un codigo de area valido.
                                        </span>
                                    </p>  
    
                                    <p *ngIf="telefono_num.invalid && (telefono_num.dirty || telefono_num.touched)" class="invalido">
                                        <span *ngIf="telefono_num.errors.required">
                                            El telefono es requerido.
                                        </span>
                                        <span *ngIf="telefono_num.invalid">
                                            Debe colocar un telefono valido.
                                        </span>
                                    </p> 
    
                                </div>

                                <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': provincia.invalid && (provincia.dirty || provincia.touched)  }">
                                    <input type="text" formControlName="provincia" id="provincia">                                           
                                    <span id="provSpan" [ngClass]="{'spanInvalido ': provincia.invalid && (provincia.dirty || provincia.touched)  }">Provincia *</span>
                                    <div *ngIf="provincia.invalid && (provincia.dirty || provincia.touched)" class="invalido">
                                        <div *ngIf="provincia.errors.required">
                                            Ingrese su ciudad
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': ciudad.invalid && (ciudad.dirty || ciudad.touched)  }">
                                    <input type="text" formControlName="ciudad" id="ciudad">
                                    <span id="ciuSpan" [ngClass]="{'spanInvalido ': ciudad.invalid && (ciudad.dirty || ciudad.touched)  }">Ciudad *</span>
                                    <div *ngIf="ciudad.invalid && (ciudad.dirty || ciudad.touched)" class="invalido">
                                        <div *ngIf="ciudad.errors.required">
                                            Ingrese su provincia
                                        </div>
                                    </div> 
                                </div>
    
                                <div class="col-12 col-lg-6 my-auto" [ngClass]="{'campoInvalido ': importFile.invalid && (importFile.dirty || importFile.touched)  }">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" multiple formControlName="importFile" id="importFile" (change)="onFileChange($event.target.files)" (click)="setDirty()">
                                        <label class="custom-file-label" #labelImport for="importFile"></label>
                                    </div>   
                                    <div *ngIf="importFile.invalid && (importFile.dirty || importFile.touched)" class="invalido" style="width: 100px; left:135px; top: 30px;  z-index: 20;" >
                                        <div  *ngIf="importFile.errors.required">
                                            Falta Adjuntar CV
                                        </div>
                                    </div> 
                                </div>
    

    
                                <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': mensaje.invalid && (mensaje.dirty || mensaje.touched)  }">
                                    <textarea formControlName="mensaje" id="mensaje"></textarea>
                                    <span [ngClass]="{'spanInvalido': mensaje.invalid && (mensaje.dirty || mensaje.touched) }">Mensaje *</span>
                                    <div *ngIf="mensaje.invalid && (mensaje.dirty || mensaje.touched)" class="invalido">
                                        <div *ngIf="mensaje.errors.required">
                                            El mensaje es requerido.
                                        </div>
                                        <div *ngIf="mensaje.errors.minlength">
                                            El mensaje debe tener al menos 20 caracteres.
                                        </div>
                                    </div> 
                                </div>
    
                                <div class="col-12 col-lg-12 contentButton">
                                    <button  [ngx-scroll-to]="'#sendOk'" [disabled]="contactForm.invalid" >Enviar</button>
                                </div>                              
                            </div>
                        </form>
                    </div>
                </div>
                <div class="boxEnviado my-auto" id="sendOk">
                    <div class="row h-100 m-0">
                        <div class="col-12 my-auto">
                            <div class="contentSendOk">
                                <img src="assets/img/noun_send.png" alt="">
                                <h2>Tu CV fue enviado con éxito. Gracias.</h2>
                                <!-- <p>¡Pronto uno de nuestros asesores se <br> contactara con vos!</p> -->
                                <button (click)="otraConsulta()">Hacer otra consulta</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="boxEnviado my-auto" id="sendError">
                    <div class="row h-100 m-0">
                        <div class="col-12 my-auto">
                            <div class="contentSendOk">
                                <img src="assets/img/noun_send.png" alt="">
                                <h2>{{errorMessage}}</h2>
                                <!-- <p>¡Pronto uno de nuestros asesores se <br> contactara con vos!</p> -->
                                <button (click)="otraConsulta()">Hacer otra consulta</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
    
            </div>
        </div>
        <!-- <section class="franquicias">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <a routerLink='/franquicias'>
                            <img src="assets/img/banner_franquicias_3.png" alt="">
                        </a>
                    </div>
                    <div class="col-12 col-lg-6">                        
                        <img src="assets/img/banner-_2.png" alt="">
                    </div>
                </div>    
            </div>
        </section> -->
    
    </section>
</section>