<div class="contentModalGral px-3 pt-4">
    <div class="row titulos " >
        <div class="col-12 px-4">
            <h2><img src="assets/img/icon-socios-04.png" alt=""> Constancia de asociación</h2>
        </div>        
    </div>
    <div>
        <p style="text-align: center; color: #005ab8; font-weight: 700; margin-bottom: 50px;">Solicite su constancia de asociación.</p>
    </div>
    <div class="col-12 col-lg-4 contentButton asociacion" style="margin: auto;">
        <button (click)="onClick()" >SOLICITAR</button>
    </div>
</div>


<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>

