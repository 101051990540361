/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./en-construccion.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./en-construccion.component";
var styles_EnConstruccionComponent = [i0.styles];
var RenderType_EnConstruccionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnConstruccionComponent, data: {} });
export { RenderType_EnConstruccionComponent as RenderType_EnConstruccionComponent };
export function View_EnConstruccionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "construccion"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Secci\u00F3n en construcci\u00F3n"]))], null, null); }
export function View_EnConstruccionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-en-construccion", [], null, null, null, View_EnConstruccionComponent_0, RenderType_EnConstruccionComponent)), i1.ɵdid(1, 114688, null, 0, i2.EnConstruccionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnConstruccionComponentNgFactory = i1.ɵccf("app-en-construccion", i2.EnConstruccionComponent, View_EnConstruccionComponent_Host_0, {}, {}, []);
export { EnConstruccionComponentNgFactory as EnConstruccionComponentNgFactory };
