import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PostI } from './post.interface';
import { Observable } from 'rxjs';
import { GenericResponse } from './generic-response.interface';
import { environment } from 'src/environments/environment';

/* CLASE GENERAL PARA TODAS LAS PAGINAS */
const baseUrl = environment.API_BASE_URL;
export const API_BASE_URL = `${baseUrl}/gestion`;
export const API_BASE_URL_JSON = `${API_BASE_URL}/wp-json`;
export const API_BASE_URL_V = `${API_BASE_URL_JSON}/wp/v2`;

@Injectable()
export class DataWpServicePages {
  private _wpBase = API_BASE_URL_V + '/pages';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `/?slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR MENU */

@Injectable()
export class DataWpService {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=42';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}
/* CLASE PARA LEVANTAR SLIDER */
@Injectable()
export class DataWpServiceSlider {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=76';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR MENU SECCIONES PRINCIPALES */
@Injectable()
export class DataWpServicePrincipales {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=108';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR MENU PLANES */
@Injectable()
export class DataWpServicePlanes {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=116';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR LISTADO DE PLANES */
@Injectable()
export class DataWpServicePlanesChild {
  private _wpBase = API_BASE_URL_V + '/pages?parent=116&order=asc';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR BANNER DESTACADO */
@Injectable()
export class DataWpServiceBannerDestacado {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=140';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR PADRE SERVICIOS ADICIONALES  */
@Injectable()
export class DataWpServiceServAdicPadre {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=381';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}


/* CLASE PARA LEVANTAR Contacto  */
@Injectable()
export class DataWpServiceContacto {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=5487';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}


/* CLASE PARA LEVANTAR HIJOS SERVICIOS ADICIONALES */
@Injectable()
export class DataWpServiceServAdic {
  private _wpBase = API_BASE_URL_V + '/pages?parent=381&order=asc&per_page=100';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR HIJOS SERVICIOS ADICIONALES HOME */
@Injectable()
export class DataWpServiceServAdicHome {
  private _wpBase = API_BASE_URL_V + '/pages?parent=381&order=asc&per_page=6';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR PROMO PADRE */
@Injectable()
export class DataWpServicePromosPadre {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=180';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR HIJOS DE PROMOS */
@Injectable()
export class DataWpServicePromos {
  private _wpBase = API_BASE_URL_V + '/pages?parent=180&order=asc&per_page=100';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR SOCIOS PADRE */
@Injectable()
export class DataWpServiceSociosPadre {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=426';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR HIJOS DE SOCIOS */
@Injectable()
export class DataWpServiceSociosChild {
  private _wpBase = API_BASE_URL_V + '/pages?parent=426&filter[orderby]=date&order=desc';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR LISTADO DE COBERTURA */
@Injectable()
export class DataWpServiceCobertura {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=428';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR LISTADO DE FAQS */
@Injectable()
export class DataWpServiceFaqs {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=607';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR LISTADO DE FAQS */
@Injectable()
export class DataWpServiceTelefonosUtiles {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=9087';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}




/* CLASE PARA LEVANTAR GESTION ONLINE */
@Injectable()
export class DataWpServiceGestionOnline {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=627';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }

  login(dni, email) {
    let socio_dni = dni;
    let socio_email = email;
    return this.http.post<GenericResponse>(API_BASE_URL_V + '/signin?', { socio_dni: socio_dni, socio_email: socio_email });
  }

  loginEmpresa(cuit, password) {
    let empresa_cuit = cuit;
    let empresa_password = password;
    return this.http.post<GenericResponse>(API_BASE_URL_V + '/signin?', { empresa_cuit: empresa_cuit, empresa_password: empresa_password });
  }

  register(nombre, apellido, numero, dni, email, codigo_area, numero_tel) {
    let socio_nombre = nombre;
    let socio_apellido = apellido;
    let socio_numero = numero;
    let socio_dni = dni;
    let socio_email = email;
    let socio_area = codigo_area;
    let socio_telefono = numero_tel;
    return this.http.post<GenericResponse>(API_BASE_URL_V + '/signup', {
      socio_nombre: socio_nombre,
      socio_apellido: socio_apellido,
      socio_numero: socio_numero,
      socio_dni: socio_dni,
      socio_email: socio_email,
      socio_area: socio_area,
      socio_telefono: socio_telefono
    })
  }

  registerEmpresa(nombre, apellido, numero, dni, email, codigo_area, numero_tel) {
    let socio_nombre = nombre;
    let socio_apellido = apellido;
    let socio_numero = numero;
    let socio_dni = dni;
    let socio_email = email;
    let socio_area = codigo_area;
    let socio_telefono = numero_tel;
    return this.http.post<GenericResponse>(API_BASE_URL_V + '/signup', {
      socio_nombre: socio_nombre,
      socio_apellido: socio_apellido,
      socio_numero: socio_numero,
      socio_dni: socio_dni,
      socio_email: socio_email,
      socio_area: socio_area,
      socio_telefono: socio_telefono
    })
  }

}

/* CLASE PARA LEVANTAR GESTION ONLINE */
@Injectable()
export class DataWpServiceCartillaMedica {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=629';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }


  //interaccion con wp - api
  getRubros(url: string) {
    return this.http.get(url);
  }
  getEspecialidades(url: string, value: string) {
    return this.http.get(url, {
      params: {
        rubro: value
      }
    });
  }
  /*
  getSucursales(url:string, rubro:integer){
  */
  getSucursales(url: string, provincia: string, localidad: string, rubro: string, especialidad: string, practica: string, entidad: string, usr_lat?: string, usr_lng?: string) {
    const fullUrl = `${url}?provincia=${provincia}&localidad=${localidad}&rubro=${rubro}&especialidad=${especialidad}&practica=${practica}&nombre=${entidad}&usr_lat=${usr_lat}&usr_lng=${usr_lng}&per_page=100`;
    console.log('URL de la API:', fullUrl);
    return this.http.get(url, {
      params: {
        provincia: provincia,
        localidad: localidad,
        rubro: rubro,
        especialidad: especialidad,
        practica: practica,
        nombre: entidad,
        usr_lat: usr_lat,
        usr_lng: usr_lng,
        per_page: '100'
      }
    });
  }


  getProvincias(url: string) {
    return this.http.get(url);
  }
  getLocalidades(url: string, value: string) {
    return this.http.get(url, {
      params: {
        provincia: value
      }
    });
  }

}

/* API - traer los rubros */
@Injectable()
export class DataWpServiceRubros {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/rubros/';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}


/* CLASE PARA LEVANTAR AREAS PROTEGIDAS */
@Injectable()
export class DataWpServiceAreasProtegidas {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=686';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR AREAS PROTEGIDAS HIJOS */
@Injectable()
export class DataWpServiceAreasProtegidasHijos {
  private _wpBase = API_BASE_URL_V + '/pages?parent=686&filter[orderby]=menu_order&order=asc';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR EMPRESAS PADRE */
@Injectable()
export class DataWpServiceEmpresasPadre {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=716';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR HIJOS DE EMPRESAS 716  DataWpServiceEmpresas*/
@Injectable()
export class DataWpServiceEmpresas {
  private _wpBase = API_BASE_URL_V + '/pages?parent=716&filter[orderby]=menu_order&order=asc';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR FUNDACION VITTAL 1469 DataWpServiceFundacion*/
@Injectable()
export class DataWpServiceFundacion {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=1469';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR INSTITUCIONAL 1552 DataWpServiceFundacion*/
@Injectable()
export class DataWpServiceInstitucional {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=1552';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR FRANQUICIAS 1645 DataWpServiceFranquicias*/
@Injectable()
export class DataWpServiceFranquicias {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=1645';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR LANDINGS */
@Injectable()
export class DataWpServiceLandings {
  private _wpBase = API_BASE_URL_V + '/pages?parent=727&order=asc';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA TITULOS BLOG */
@Injectable()
export class DataWpServiceTituloBlog {
  private _wpBase = API_BASE_URL_V + '/pages?include[]=1856';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR LISTADO DE CATEGORIAS */
@Injectable()
export class DataWpServiceCategorias {
  private _wpBase = API_BASE_URL_V + '/categories?per_page=100&exclude=1';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}



/* CLASE PARA LEVANTAR BLOG */
@Injectable()
export class DataWpServiceBlog {
  private _wpBase = API_BASE_URL_V + '/posts?per_page=100';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}


/* CLASE PARA LISTADO DE POSTS CON FILTRADO DE CATEGORIAS */
@Injectable()
export class DataWpServiceListadoPostFiltroCategoria {
  private _wpBase = API_BASE_URL_V + '/posts?per_page=100';
  constructor(private http: HttpClient) { }
  getPosts(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&filter[category_name]=${slug}`);
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&filter[category_name]=${slug}`);
  }
}


/* CLASE PARA LEVANTAR LISTADO DE PLANES */
@Injectable()
export class DataWpServiceBlogSingles {
  private _wpBase = API_BASE_URL_V + '/posts';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `?slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR PRECIOS COTIZACIONES 
http://client.elpixel.net/vittal/wp-test/wp-json/wp/v2/pages?parent=1006&filter[meta_key]=tipo_de_precio&filter[meta_value]=Plan
*/

@Injectable()
export class DataWpServicePrecios {
  private _wpBase = API_BASE_URL_V + '/maestro_de_precios?filter[meta_key]=tipo_de_precio&filter[meta_value]=Plan';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR LISTADO DE PAGINAS PARA BUSCADOR */
@Injectable()
export class DataWpServiceBuscador {
  private _wpBase = API_BASE_URL_V + '/pages?parent_exclude=727,40&per_page=100&exclude=727,40';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR BANNER COTIZADORES */
@Injectable()
export class DataWpServiceBannerCotizadores {
  private _wpBase = API_BASE_URL_V + '/pages?include[]=1957';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}

/* CLASE PARA LEVANTAR Footer */
@Injectable()
export class DataWpServiceFooter {
  private _wpBase = API_BASE_URL_V + '/pages?parent=3806&order=asc';
  constructor(private http: HttpClient) { }
  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + '');
  }
  getPost(slug: string): Observable<PostI[]> {
    return this.http.get<PostI[]>(this._wpBase + `&slug=${slug}`);
  }
}


/* CLASE PARA LEVANTAR thank you page general*/
@Injectable()
export class DataWpServiceThankYouPage {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=9731';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

/* CLASE PARA LEVANTAR thank you page general*/
@Injectable()
export class DataWpServiceThankYouPageEcommerce {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=10736';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}

// /* CLASE PARA LEVANTAR thank you page del ecommerce*/
// @Injectable()
// export class DataWpServiceThankYouPageEcommerce {
//   constructor(private http: HttpClient) { }
//   urlApi = API_BASE_URL_V + '/pages?include[]=10736';

//   getPosts(): Observable<PostI[]> {
//     return this.http.get<PostI[]>(this.urlApi, {
//       params: {
//         per_page: '1'
//       }
//     });
//   }
// }



/* CLASE PARA LEVANTAR CHATBOT */
@Injectable()
export class DataWpServiceChatbot {
  constructor(private http: HttpClient) { }
  urlApi = API_BASE_URL_V + '/pages?include[]=2014';

  getPosts(): Observable<PostI[]> {
    return this.http.get<PostI[]>(this.urlApi, {
      params: {
        per_page: '1'
      }
    });
  }
}


