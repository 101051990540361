/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner-destacado.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../data-wp.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./banner-destacado.component";
import * as i7 from "@angular/platform-browser";
var styles_BannerDestacadoComponent = [i0.styles];
var RenderType_BannerDestacadoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannerDestacadoComponent, data: {} });
export { RenderType_BannerDestacadoComponent as RenderType_BannerDestacadoComponent };
function View_BannerDestacadoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "section", [["class", "banner"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "fondo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "container h-100 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "row h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "col my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "p", [["class", "bajadaDes"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "p", [["class", "contentLink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["href", "#"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["M\u00C1S INFORMACI\u00D3N"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.context.$implicit.acf.imagen_fondo.url) + ")")); _ck(_v, 2, 0, currVal_0); var currVal_5 = i1.ɵinlineInterpolate(1, "/", _v.context.$implicit.acf.link, ""); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.acf.titulo; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.acf.bajada; _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 12).target; var currVal_4 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_3, currVal_4); }); }
function View_BannerDestacadoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BannerDestacadoComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.posts$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BannerDestacadoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "section", [["class", "banner"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "fondo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "container h-100 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "row h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "col my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "p", [["class", "bajadaDes"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "p", [["class", "contentLink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["href", "#"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["M\u00C1S INFORMACI\u00D3N"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.context.$implicit.acf.imagenmobile) + ")")); _ck(_v, 2, 0, currVal_0); var currVal_5 = i1.ɵinlineInterpolate(1, "/", _v.context.$implicit.acf.link, ""); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.acf.titulo; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.acf.bajada; _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 12).target; var currVal_4 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_3, currVal_4); }); }
function View_BannerDestacadoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BannerDestacadoComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.posts$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BannerDestacadoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannerDestacadoComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannerDestacadoComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isMobile; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isMobile; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BannerDestacadoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-banner-destacado", [], null, null, null, View_BannerDestacadoComponent_0, RenderType_BannerDestacadoComponent)), i1.ɵprd(512, null, i4.DataWpServiceBannerDestacado, i4.DataWpServiceBannerDestacado, [i5.HttpClient]), i1.ɵdid(2, 114688, null, 0, i6.BannerDestacadoComponent, [i4.DataWpServiceBannerDestacado, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var BannerDestacadoComponentNgFactory = i1.ɵccf("app-banner-destacado", i6.BannerDestacadoComponent, View_BannerDestacadoComponent_Host_0, {}, {}, []);
export { BannerDestacadoComponentNgFactory as BannerDestacadoComponentNgFactory };
