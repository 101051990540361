/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./secciones-principales.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../data-wp.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./secciones-principales.component";
import * as i7 from "@angular/platform-browser";
var styles_SeccionesPrincipalesComponent = [i0.styles];
var RenderType_SeccionesPrincipalesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeccionesPrincipalesComponent, data: {} });
export { RenderType_SeccionesPrincipalesComponent as RenderType_SeccionesPrincipalesComponent };
function View_SeccionesPrincipalesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "itemDestacado col-12 col-md-3 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "w-100 h-100 row"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "small", [["class", "w-100"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.link, ""); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.nombre; _ck(_v, 4, 0, currVal_3); var currVal_4 = _v.context.$implicit.descripcion; _ck(_v, 5, 0, currVal_4); }); }
function View_SeccionesPrincipalesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row h-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SeccionesPrincipalesComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.acf.menu; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SeccionesPrincipalesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "principales"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SeccionesPrincipalesComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.posts$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SeccionesPrincipalesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-secciones-principales", [], null, null, null, View_SeccionesPrincipalesComponent_0, RenderType_SeccionesPrincipalesComponent)), i1.ɵprd(512, null, i4.DataWpServicePrincipales, i4.DataWpServicePrincipales, [i5.HttpClient]), i1.ɵdid(2, 114688, null, 0, i6.SeccionesPrincipalesComponent, [i4.DataWpServicePrincipales, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SeccionesPrincipalesComponentNgFactory = i1.ɵccf("app-secciones-principales", i6.SeccionesPrincipalesComponent, View_SeccionesPrincipalesComponent_Host_0, {}, {}, []);
export { SeccionesPrincipalesComponentNgFactory as SeccionesPrincipalesComponentNgFactory };
