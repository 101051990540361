/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cotizacion.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../landings/forms/form-cotizadores/form-cotizadores.component.ngfactory";
import * as i3 from "../data-wp.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../landings/forms/form-cotizadores/form-cotizadores.component";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/router";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "./cotizacion.component";
var styles_CotizacionComponent = [i0.styles];
var RenderType_CotizacionComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CotizacionComponent, data: {} });
export { RenderType_CotizacionComponent as RenderType_CotizacionComponent };
function View_CotizacionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header"], ["style", "padding: 15px 0 0 0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "row"], ["id", "formCotizar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "col-12 col-lg-4 contentImg"], ["style", "background-image: url('assets/img/pic-cotizar.png');"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "col-12 col-lg-8 boxForm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "app-form-cotizadores", [], null, null, null, i2.View_FormCotizadoresComponent_0, i2.RenderType_FormCotizadoresComponent)), i1.ɵprd(512, null, i3.DataWpServicePrecios, i3.DataWpServicePrecios, [i4.HttpClient]), i1.ɵdid(11, 114688, null, 0, i5.FormCotizadoresComponent, [i6.FormBuilder, i4.HttpClient, i3.DataWpServicePrecios, i7.Router, i8.NgbModal], { codePrice: [0, "codePrice"], codeForm: [1, "codeForm"], tituloForm: [2, "tituloForm"], isMobile: [3, "isMobile"], isBitrix: [4, "isBitrix"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 1059; var currVal_1 = 1168; var currVal_2 = "\u00A1Cotiz\u00E1 tu plan ideal!"; var currVal_3 = _co.mobile; var currVal_4 = _co.bitrix; _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_CotizacionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flotante"], ["id", "flotante"]], [[4, "display", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModalCotizar(i1.ɵnov(_v, 3)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A1Cotiz\u00E1 tu plan ideal!"])), (_l()(), i1.ɵand(0, [["mymodal", 2]], null, 0, null, View_CotizacionComponent_1))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isHome ? "block" : "none"); _ck(_v, 0, 0, currVal_0); }); }
export function View_CotizacionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-cotizacion", [], null, null, null, View_CotizacionComponent_0, RenderType_CotizacionComponent)), i1.ɵprd(512, null, i3.DataWpServicePrecios, i3.DataWpServicePrecios, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i9.CotizacionComponent, [i6.FormBuilder, i3.DataWpServicePrecios, i7.Router, i8.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CotizacionComponentNgFactory = i1.ɵccf("app-cotizacion", i9.CotizacionComponent, View_CotizacionComponent_Host_0, { codePricePlan: "codePricePlan", codeFormPlan: "codeFormPlan", tituloForm: "tituloForm" }, {}, []);
export { CotizacionComponentNgFactory as CotizacionComponentNgFactory };
