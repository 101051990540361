/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../data-wp.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./landings.component";
import * as i6 from "@angular/router";
var styles_LandingsComponent = [i0.styles];
var RenderType_LandingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingsComponent, data: {} });
export { RenderType_LandingsComponent as RenderType_LandingsComponent };
function View_LandingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "/landings/", _v.context.$implicit.slug, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.title.rendered; _ck(_v, 2, 0, currVal_1); }); }
export function View_LandingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LandingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-landings", [], null, null, null, View_LandingsComponent_0, RenderType_LandingsComponent)), i1.ɵprd(512, null, i3.DataWpServiceLandings, i3.DataWpServiceLandings, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i5.LandingsComponent, [i3.DataWpServiceLandings, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LandingsComponentNgFactory = i1.ɵccf("app-landings", i5.LandingsComponent, View_LandingsComponent_Host_0, {}, {}, []);
export { LandingsComponentNgFactory as LandingsComponentNgFactory };
