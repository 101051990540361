<section class="headerSocios">
  <div class="container">
      <div class="row">
          <div class="col-12 col-lg-9">
              <h2>GESTIONES ONLINE</h2>
              <p>
                  Bienvenido <strong>{{socio_nombre +' '+ socio_apellido}}</strong> <br>
                  Nº de Socio: <strong>{{socio_numero}}</strong> <br>
                  <a routerLink="/atencion-al-socio/gestion-online/socios/perfil-usuario">VER MIS DATOS</a><br>
                  <a routerLink="/" (click)='logout()'>CERRAR SESIÓN</a>
              </p>
          </div>
          <div class="col-12 col-lg-3">
              <a routerLink="/atencion-al-socio/gestion-online/socios/credencial">
                  <div class="boxVisualizar">
                      <p><img src="assets/img/icon-socios-visualizar.png" alt=""></p>
                      <p>Credencial  <br> Digital</p>
                  </div>
              </a>
          </div>
      </div>
  </div>
</section>

<section class="contentButtons">
  <div class="container">
      <div class="row">
          <div class="col-lg col-12">
              <a routerLink="/atencion-al-socio/gestion-online/socios/pedido-de-factura">
                  <div class="itemsSocios">
                      <img src="assets/img/icon-socios-01.png" alt=""> <br>
                      Facturación
                  </div>
              </a>
          </div>
          <div class="col-lg col-12" (click)="openModalForms(modalAmpliacion)">
              <div class="itemsSocios">
                  <img src="assets/img/icon-socios-02.png" alt=""> <br>
                  Ampliación grupo familiar
              </div>
          </div>
          <!-- <div class="col-lg col-12">
              <div class="itemsSocios" (click)="openModalForms(modalReclamo)">
                  <img src="assets/img/icon-socios-03.png" alt=""> <br>
                  Tramites
              </div>
          </div> -->
          <div class="col-lg col-12">
              <div class="itemsSocios" (click)="openModalFormsLg(modalCambioDomicilio)">
                  <img src="assets/img/icon-socios-04.png" alt=""> <br>
                  cambio de domicilio
              </div>
          </div>
          <div class="col-lg col-12">
              <div class="itemsSocios" (click)="openModalForms(modalCambioFormaPago)">
                  <img src="assets/img/icon-socios-05.png" alt=""> <br>
                  cambio de forma de pago
              </div>
          </div>
      </div>
      <div class="row">
          <!-- <div class="col-lg col-12">
              <div class="itemsSocios" (click)="openModalForms(modalSolicitudCredencial)">
                  <img src="assets/img/icon-socios-06.png" alt=""> <br>
                  Solicitud de credencial
              </div>
          </div> -->
          <!-- ESTE NO VUELVE!!!!!!!!!!!!!!!!!!!!!!!!!!! <div class="col-lg col-12">
              <div class="itemsSocios" (click)="openModalForms(modalReclamoCorreoInicial)">
                  <img src="assets/img/icon-socios-07.png" alt=""> <br>
                  reclamo de correo inicial
              </div>
          </div> -->
          <!-- <div class="col-lg col-12">
              <div class="itemsSocios" (click)="openModalForms(modalModificacionDatos)">
                  <img src="assets/img/icon-socios-08.png" alt=""> <br>
                  Modificación de datos
              </div>
          </div> -->
          <div class="col-lg col-12">
              <div class="itemsSocios" (click)="openModalForms(modalConstanciaAtencion)">
                  <img src="assets/img/icon-socios-09.png" alt=""> <br>
                  constancia de atención
              </div>
          </div>
          <div class="col-lg col-12">
              <div class="itemsSocios" (click)="openModalForms(modalConsultas)">
                  <img src="assets/img/icon-socios-10.png" alt=""> <br>
                  Consultas / sugerencias
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col">
              <div class="itemVittalIdoc">
                  <div class="row h-100">
                      <div class="col-12 col-lg-4 my-auto contentImg">
                          <img src="assets/img/icon-idoc-socios.png" width="55px" alt="">
                      </div>
                      <div class="col-12 col-lg-8 px-lg-5 my-auto">
                          <p>¿Necesitás un médico ahora? <br> <strong><a href="https://idoc.vittal.com.ar/" target="_blank"> Ingresá a vittal iDoc</a></strong></p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>



<ng-template #modalAmpliacion let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-ampliacion-familiar (closeModal)="closeModal()"></app-ampliacion-familiar>
  </div>
</ng-template>

<!-- <ng-template #modalReclamo let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-reclamo-de-cobrador (closeModal)="closeModal()"></app-reclamo-de-cobrador>
  </div>
</ng-template> -->

<ng-template #modalCambioDomicilio let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-cambio-de-domicilio (closeModal)="closeModal()"></app-cambio-de-domicilio>
  </div>
</ng-template>

<ng-template #modalCambioFormaPago let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-cambio-de-forma-de-pago (closeModal)="closeModal()"></app-cambio-de-forma-de-pago>
  </div>
</ng-template>

<ng-template #modalSolicitudCredencial let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-solicitud-de-credencial (closeModal)="closeModal()"></app-solicitud-de-credencial>
  </div>
</ng-template>

<ng-template #modalReclamoCorreoInicial let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-reclamo-de-correo-inicial (closeModal)="closeModal()"></app-reclamo-de-correo-inicial>
  </div>
</ng-template>

<ng-template #modalModificacionDatos let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-modificacion-de-datos (closeModal)="closeModal()"></app-modificacion-de-datos>
  </div>
</ng-template>

<ng-template #modalConstanciaAtencion let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-constancia-de-atencion (closeModal)="closeModal()"></app-constancia-de-atencion>
  </div>
</ng-template>

<ng-template #modalConsultas let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <app-consultas-sugerencias (closeModal)="closeModal()"></app-consultas-sugerencias>
  </div>
</ng-template>

<!-- <ng-template #modalRespuesta let-modal>
  <div class="modal-body contentModal">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <h1>TEST</h1>
  </div>
</ng-template> -->

