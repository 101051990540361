<section class="headerSocios">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-9">
        <h2>Gestiones Online</h2>
        <p>
          Bienvenida
          <strong>{{
            datosSocio.socio_nombre + " " + datosSocio.socio_apellido
          }}</strong>
          <br />
          Nº de Socio: <strong>{{ datosSocio.socio_numero }}</strong> <br />
          <a
            routerLink="/atencion-al-socio/gestion-online/socios/perfil-usuario"
            >VER MIS DATOS</a
          >
          <br />
          <a routerLink="/" (click)="logout()">CERRAR SESIÓN</a> <br />
          <a
            routerLink="/atencion-al-socio/gestion-online/socios"
            class="topVolver"
            >Volver</a
          >
        </p>
      </div>
    </div>
  </div>
</section>
<section class="pedido">
  <div class="container">
    <div class="content">
      <h2><img src="assets/img/icon-socios-01.png" alt="" /> Facturación</h2>

      <ul>
        <div *ngFor="let factura of facturas">
          <li *ngIf="!esFacturaDescargable(factura.fecha)" class="noDisponible">
            <div class="fecha">
              <strong>{{ getMonth(factura.fecha) }}</strong>
              {{ getYear(factura.fecha) }}
            </div>
            <div class="download">EN GESTIÓN DE COBRANZA</div>
            <div class="clearfix"></div>
          </li>
          <li *ngIf="esFacturaDescargable(factura.fecha)">
            <div class="fecha">
              <strong>{{ getMonth(factura.fecha) }}</strong>
              {{ getYear(factura.fecha) }}
            </div>
            <div class="download">
              <button
                class="btn-download"
                (click)="
                  obtenerFacturaPDF(
                    factura.letra,
                    factura.numero,
                    factura.fecha
                  )
                "
              >
                Descargar PDF
                <img
                  src="assets/img/icon-download.png"
                  alt="Descargar PDF"
                  class="pdf-icon"
                />
              </button>
            </div>
            <div class="clearfix"></div>
          </li>
        </div>
      </ul>

      <div class="clearfix"></div>
    </div>
  </div>
</section>

<ng-template #modalRespuesta let-modal id="modalRespuesta">
  <div class="modal-body contentModal" style="height: 200px">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
      style="outline: none"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h4
      style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center"
    >
      {{ modalMessage }}
    </h4>
  </div>
</ng-template>
