/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./telefonos-utiles.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../data-wp.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./telefonos-utiles.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/platform-browser";
var styles_TelefonosUtilesComponent = [i0.styles];
var RenderType_TelefonosUtilesComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TelefonosUtilesComponent, data: {} });
export { RenderType_TelefonosUtilesComponent as RenderType_TelefonosUtilesComponent };
function View_TelefonosUtilesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "telefonos"], ["id", "telefonosUtiles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.content.rendered; _ck(_v, 2, 0, currVal_0); }); }
export function View_TelefonosUtilesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TelefonosUtilesComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TelefonosUtilesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-telefonos-utiles", [], null, null, null, View_TelefonosUtilesComponent_0, RenderType_TelefonosUtilesComponent)), i1.ɵprd(512, null, i3.DataWpServiceTelefonosUtiles, i3.DataWpServiceTelefonosUtiles, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i5.TelefonosUtilesComponent, [i3.DataWpServiceTelefonosUtiles, i6.Router, i7.DomSanitizer, i7.Title, i7.Meta], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TelefonosUtilesComponentNgFactory = i1.ɵccf("app-telefonos-utiles", i5.TelefonosUtilesComponent, View_TelefonosUtilesComponent_Host_0, {}, {}, []);
export { TelefonosUtilesComponentNgFactory as TelefonosUtilesComponentNgFactory };
