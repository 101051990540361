/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-contacto-bitrix.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./form-contacto-bitrix.component";
var styles_FormContactoBitrixComponent = [i0.styles];
var RenderType_FormContactoBitrixComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormContactoBitrixComponent, data: {} });
export { RenderType_FormContactoBitrixComponent as RenderType_FormContactoBitrixComponent };
export function View_FormContactoBitrixComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "boxForm contacto"], ["id", "contentAll"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tituloForm; _ck(_v, 2, 0, currVal_0); }); }
export function View_FormContactoBitrixComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-contacto-bitrix", [], null, null, null, View_FormContactoBitrixComponent_0, RenderType_FormContactoBitrixComponent)), i1.ɵdid(1, 114688, null, 0, i2.FormContactoBitrixComponent, [i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormContactoBitrixComponentNgFactory = i1.ɵccf("app-form-contacto-bitrix", i2.FormContactoBitrixComponent, View_FormContactoBitrixComponent_Host_0, { scriptBitrix: "scriptBitrix", tituloForm: "tituloForm" }, {}, []);
export { FormContactoBitrixComponentNgFactory as FormContactoBitrixComponentNgFactory };
