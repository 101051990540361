import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
export class AnalyticsService {
    initializeAnalytics() {
        if (typeof gtag === 'undefined') {
            this.loadGoogleAnalytics();
        }
    }
    trackFormSubmission(status) {
        gtag('event', 'form_submission', {
            'event_category': 'Landing Page',
            'event_label': status,
            'value': status === 'success' ? 1 : 0
        });
    }
    loadGoogleAnalytics() {
        // Add Google Analytics script dynamically
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
        document.head.appendChild(script);
        // Initialize gtag
        window.dataLayer = window.dataLayer || [];
        gtag('js', new Date());
        gtag('config', environment.googleAnalyticsId);
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(); }, token: AnalyticsService, providedIn: "root" });
