/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gestion-online.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login/login.component.ngfactory";
import * as i3 from "./login/login.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../services/session-storage.service";
import * as i6 from "./gestion-online.component";
import * as i7 from "@angular/router";
var styles_GestionOnlineComponent = [i0.styles];
var RenderType_GestionOnlineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GestionOnlineComponent, data: {} });
export { RenderType_GestionOnlineComponent as RenderType_GestionOnlineComponent };
export function View_GestionOnlineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, i2.View_LoginComponent_0, i2.RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoginComponent, [i4.NgbModal, i5.SessionStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_GestionOnlineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gestion-online", [], null, null, null, View_GestionOnlineComponent_0, RenderType_GestionOnlineComponent)), i1.ɵdid(1, 114688, null, 0, i6.GestionOnlineComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GestionOnlineComponentNgFactory = i1.ɵccf("app-gestion-online", i6.GestionOnlineComponent, View_GestionOnlineComponent_Host_0, {}, {}, []);
export { GestionOnlineComponentNgFactory as GestionOnlineComponentNgFactory };
