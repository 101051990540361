<section>
  <div class="contentHerders">
    <section class="header">
      <div class="links px-4">
        <div class="logo pl-3 pr-0" *ngFor="let post of posts$ | async">
          <a onclick="closeMenu()" routerLink="/home"
            ><img src="{{ post.acf.logo }}" alt=""
          /></a>
        </div>
        <div class="link-item">
          <ul class="linkMiddle">
            <li class="col-lg-12">
              <div class="socials-items" *ngFor="let post of posts$ | async">
                <p>
                  <a
                    href="{{ post.acf.redes_sociales.instagram }}"
                    target="_blank"
                    ><i class="fa fa-instagram" aria-hidden="true"></i
                  ></a>
                  <a
                    href="{{ post.acf.redes_sociales.facebook }}"
                    target="_blank"
                    ><i class="fa fa-facebook-square" aria-hidden="true"></i
                  ></a>
                  <a
                    href="{{ post.acf.redes_sociales.linkedin }}"
                    target="_blank"
                    ><i class="fa fa-linkedin" aria-hidden="true"></i
                  ></a>
                </p>
              </div>
            </li>
            <li class="col-lg-12 links_top" *ngFor="let post of posts$ | async">
              <ng-container *ngFor="let links_top of post.acf.links_top">
                <a
                  *ngIf="!links_top.blank"
                  onclick="closeMenu()"
                  routerLink="/{{ links_top.link }}"
                  >{{ links_top.nombre }} <img [src]="links_top.icon" alt="" />
                </a>
                <a
                  *ngIf="links_top.blank"
                  onclick="closeMenu()"
                  href="{{ links_top.link }}"
                  target="_blank"
                  >{{ links_top.nombre }} <img [src]="links_top.icon" alt="" />
                </a>
              </ng-container>
            </li>
            <li class="col-lg-12">
              <div class="buscador">
                <app-buscador></app-buscador>
              </div>
            </li>
          </ul>
        </div>

        <div class="triggerMenu">
          <div class="txt">MENU</div>
          <div id="menu-trigger" class="menu-trigger" (click)="addClassMenu()">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </section>
    <section class="subheader" onclick="closeMenu()">
      <div class="container">
        <p>
          <img src="assets/img/icon-emergencia.svg" alt="" /><span
            style="color: #fff"
            *ngFor="let post of posts$ | async"
            [innerHTML]="post.acf.claim"
          ></span>
        </p>
      </div>
    </section>
  </div>
  <div class="menuH menu-type" id="menu-type">
    <div
      class="row menuItemsDesk m-0"
      style="z-index: 100; position: relative"
      onclick="closeMenu()"
      *ngFor="let post of posts$ | async"
    >
      <div class="col pr-1" *ngFor="let columna of post.acf.menu.columna">
        <a onclick="closeMenu()" routerLink="/{{ columna.link }}">
          <h2>{{ columna.titulo_columna }}</h2>
          <img class="imgColumna" src="{{ columna.imagen.url }}" alt="" />
        </a>
        <ul>
          <li *ngFor="let item of columna.item">
            <a onclick="closeMenu()" routerLink="/{{ item.link }}">{{
              item.nombre
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col secciones">
        <h2 *ngFor="let link_adicional of post.acf.menu.links_adicionales">
          <a onclick="closeMenu()" routerLink="/{{ link_adicional.link }}">{{
            link_adicional.label
          }}</a>
        </h2>
        <a onclick="closeMenu()" routerLink="/servicios-adicionales/idoc"
          ><img src="assets/img/banner-idoc.png" class="w-100" alt=""
        /></a>
      </div>
    </div>
    <div class="row menuItemsMobile m-0" *ngFor="let post of posts$ | async">
      <div class="boxSearch" style="display: none">
        <div class="form-search">
          <input type="text" />
          <input
            type="submit"
            style="background-image: url('assets/img/icon-form-search.svg')"
            value=""
          />
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="itemsLinksMobile">
        <div id="accordion">
          <div
            class="card"
            *ngFor="let columna of post.acf.menu.columna; let i = index"
          >
            <div class="card-header" [attr.id]="'colHeader' + i">
              <h5 class="mb-0">
                <button
                  class="btn"
                  data-toggle="collapse"
                  [attr.data-target]="'#col' + i"
                  aria-expanded="true"
                  [attr.aria-controls]="'col' + i"
                >
                  {{ columna.titulo_columna }}
                </button>
              </h5>
            </div>

            <div
              [attr.id]="'col' + i"
              class="collapse"
              [attr.aria-labelledby]="'colHeader' + i"
              data-parent="#accordion"
            >
              <div class="card-body">
                <ul>
                  <li *ngFor="let item of columna.item">
                    <a onclick="closeMenu()" routerLink="/{{ item.link }}">{{
                      item.nombre
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="LastsItemsMobile">
          <h2
            *ngFor="
              let link_adicional_mobile of post.acf.menu.links_adicionales
            "
          >
            <a
              onclick="closeMenu()"
              routerLink="/{{ link_adicional_mobile.link }}"
              >{{ link_adicional_mobile.label }}</a
            >
          </h2>
        </div>
        <div class="socialIcons">
          <p>
            <a href="https://www.instagram.com/tuvidaestaprimero/"
              ><i class="fa fa-instagram" aria-hidden="true"></i
            ></a>
            <a href="https://www.facebook.com/Tuvidaestaprimero"
              ><i class="fa fa-facebook-square" aria-hidden="true"></i
            ></a>
            <a href="https://www.linkedin.com/company/vittal/"
              ><i class="fa fa-linkedin-square" aria-hidden="true"></i
            ></a>
          </p>
        </div>
        <div class="btns">
          <ul class="row">
            <li class="col-6 separador">
              <a
                href="{{ urlSitio }}/atencion-al-socio/gestion-online"
                class="gestiones"
              >
                <span>Gestiones Online</span>
                <img src="assets/img/icon-calender-1.png" alt="" />
              </a>
            </li>
            <li class="col-6">
              <a
                href="{{ urlSitio }}/atencion-al-socio/cartilla-medica"
                class="cartilla"
              >
                <span>Cartilla Médica</span>
                <img src="assets/img/icon-book.png" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="closer" onclick="closeMenu()"></div>
  </div>
</section>
