<div class="titulosRegistroSocios">
    <h2>Registrate en<br> Gestiones online</h2>
    <p>Completá los siguientes campos del formulario.</p>
    <!-- <div *ngIf='screenSize < 470 && (!isHome)' class="contentImgMobile" id="imgHover">
        <div (click)="volverGestion()" [ngx-scroll-to]="'#up'"  class="btnVolverGestion" id="volverGestion">Gestion Online</div>
    </div> -->
</div>
<form class="formLoginSocios" [formGroup]="contactForm" (ngSubmit)="submitForm()">
    <div class="row">

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': selectPlan.invalid && (selectPlan.dirty || selectPlan.touched) }" style="height: 72px; padding-top: 36px;">
            <mat-select formControlName="selectPlan" placeholder="Seleccionar Tipo de Plan *" (selectionChange)='tipoPlan($event.value)' id='selectplan'>
                <mat-option value = 0>
                    Convenio
                </mat-option>
                <mat-option value = 1>
                    Area Protegida
                </mat-option>
            </mat-select>
        </div>

        <div *ngIf="isConvenio == 1" class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': codigoEmpresaReg.invalid && (codigoEmpresaReg.dirty || codigoEmpresaReg.touched) }">
            <input  maxlength="15" type="string" formControlName="codigoEmpresaReg"  id="codigoEmpresaReg" >
            <span [ngClass]="{'spanInvalido': codigoEmpresaReg.invalid && (codigoEmpresaReg.dirty || codigoEmpresaReg.touched)}" >Código *</span>
            <div *ngIf="codigoEmpresaReg.invalid && (codigoEmpresaReg.dirty || codigoEmpresaReg.touched)" class="invalido">
                <div *ngIf="codigoEmpresaReg.errors.required">
                    El campo código es requerido
                </div>               
            </div>
        </div>
        
        <div *ngIf="isConvenio == 0" class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': facturaEmpresaReg.invalid && (facturaEmpresaReg.dirty || facturaEmpresaReg.touched) }">
            <input  maxlength="30" type="string" formControlName="facturaEmpresaReg"  id="facturaEmpresaReg" >
            <span [ngClass]="{'spanInvalido': facturaEmpresaReg.invalid && (facturaEmpresaReg.dirty || facturaEmpresaReg.touched)}" >Factura *</span>
            <div *ngIf="facturaEmpresaReg.invalid && (facturaEmpresaReg.dirty || facturaEmpresaReg.touched)" class="invalido">
                <div *ngIf="facturaEmpresaReg.errors.required">
                    El campo Factura es requerido
                </div> 
            </div>
        </div>


        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': cuitEmpresaReg.invalid && (cuitEmpresaReg.dirty || cuitEmpresaReg.touched) }">
            <input  maxlength="11" type="text" formControlName="cuitEmpresaReg"  id="cuitEmpresaReg" >
            <span [ngClass]="{'spanInvalido': cuitEmpresaReg.invalid && (cuitEmpresaReg.dirty || cuitEmpresaReg.touched)}" >CUIT *</span>
            <div *ngIf="cuitEmpresaReg.invalid && (cuitEmpresaReg.dirty || cuitEmpresaReg.touched)" class="invalido">
                <div *ngIf="cuitEmpresaReg.errors.required">
                    El CUIT es requerido, no poner espacios ni guiones.
                </div>
                <div *ngIf="cuitEmpresaReg.errors.pattern">
                    El CUIT debe tener como minimo 11 numeros sin guiones.
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': nombreEmpresaReg.invalid && (nombreEmpresaReg.dirty || nombreEmpresaReg.touched)  }" >
            <input type="text" formControlName="nombreEmpresaReg"  id="nombreEmpresaReg">
            <span [ngClass]="{'spanInvalido ': nombreEmpresaReg.invalid && (nombreEmpresaReg.dirty || nombreEmpresaReg.touched)  } ">Razón Social *</span>
            <div *ngIf="nombreEmpresaReg.invalid && (nombreEmpresaReg.dirty || nombreEmpresaReg.touched)" class="invalido">
                <div *ngIf="nombreEmpresaReg.errors.required">
                    La razón social es requerida. 
                </div>
            </div>  
        </div>

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': emailEmpresaReg.invalid && (emailEmpresaReg.dirty || emailEmpresaReg.touched)  }" >
            <input type="text" formControlName="emailEmpresaReg"  id="emailEmpresaReg">
            <span [ngClass]="{'spanInvalido ': emailEmpresaReg.invalid && (emailEmpresaReg.dirty || emailEmpresaReg.touched)  } ">Email *</span>
            <div *ngIf="emailEmpresaReg.invalid && (emailEmpresaReg.dirty || emailEmpresaReg.touched)" class="invalido">
                <div *ngIf="emailEmpresaReg.errors.required">
                    El Email es requerido. 
                </div>
                <div *ngIf="emailEmpresaReg.errors.email">
                    Debe colocar una direccion de email valida.
                </div>
            </div>  
        </div>

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': telefonoEmpresaReg.invalid && (telefonoEmpresaReg.dirty || telefonoEmpresaReg.touched) }">
            <input  maxlength="10" type="number" formControlName="telefonoEmpresaReg"  id="telefonoEmpresaReg" >
            <span [ngClass]="{'spanInvalido': telefonoEmpresaReg.invalid && (telefonoEmpresaReg.dirty || telefonoEmpresaReg.touched)}" >Telefono *</span>
            <div *ngIf="telefonoEmpresaReg.invalid && (telefonoEmpresaReg.dirty || telefonoEmpresaReg.touched)" class="invalido">
                <div *ngIf="telefonoEmpresaReg.errors.required">
                    El telefono es requerido 
                </div>
            </div>
        </div>

        
        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': passEmpresaReg.invalid && ( passEmpresaReg.touched)  }" >
            <input type="password" formControlName="passEmpresaReg"  id="passEmpresaReg">
            <span [ngClass]="{'spanInvalido ': passEmpresaReg.invalid && ( passEmpresaReg.touched)  } ">Contraseña *</span>
            <div *ngIf="passEmpresaReg.invalid && ( passEmpresaReg.touched)" class="invalido">
                <div *ngIf="passEmpresaReg.errors.required">
                    La contraseña es requerido.
                </div>
            </div>  
        </div>

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': pass2EmpresaReg.invalid && ( pass2EmpresaReg.touched)  }" >
            <input type="password" formControlName="pass2EmpresaReg"  id="pass2EmpresaReg" pattern={{passEmpresaReg.value}}>
            <span [ngClass]="{'spanInvalido ': pass2EmpresaReg.invalid && ( pass2EmpresaReg.touched)  } ">Confirmar Contraseña *</span>
            <div *ngIf="pass2EmpresaReg.invalid && ( pass2EmpresaReg.touched)" class="invalido">

                <div *ngIf="pass2EmpresaReg.errors.required">
                    La contraseña es requerido. 
                </div>
                <div *ngIf="pass2EmpresaReg.errors.pattern">
                    Las contraseñas no coinciden. 
                </div>                
            </div>  
        </div>






        <div class="col-12 col-lg-12 contentButton">
            <div class="row">
                <div class="col-12 col-lg-6"><button  [disabled]="contactForm.invalid" >ENVIAR</button></div>
                
            </div>
            <div class="row boxObligatorios">
                <p><small>* Campos obligatorios</small></p>
            </div>
            
            
        </div>                              
    </div>
</form>
<ng-template #modalEnvioOk let-modal>
    <div class="modal-body contentModal">  
        <h2>GESTIÓN ONLINE DE VITTAL</h2>
        <p>Tu registro se completó correctamente, <br> a la brevedad te confirmaremos
            por email el acceso a Gestiones online, y luego podrás loguearte para
            ingresar.</p>
        <div class="contentBtns">
            <p><span class="btnClose" aria-label="Close" (click)="volverGestion()">VOLVER</span></p>
        </div>
    </div>
</ng-template>

<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>