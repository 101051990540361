<section class="planes">

    <div class="content-plan" *ngFor="let post of posts">

        <div class="section-plan" *ngIf="!post.acf.portada?.plan_oculto">
            <a routerLink="/{{post.acf.link}}" >
                <div class="back" *ngIf="!mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada?.imagen_de_fondo + ')'}">
                    <div class="gradient1"></div>
                    <div class="gradient2"></div>
                </div>
                <div class="back" *ngIf="mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada?.imagen_de_fondo_mobile + ')'}">
                    <div class="gradient1"></div>
                    <div class="gradient2"></div>
                </div>     
                <div class="container">
                    <h2 [innerHTML]='post.acf.portada?.titulo'></h2>
                    <p [innerHTML]='post.acf.portada?.bajada'></p>
                    <p class="contentLink">
                         <ng-container *ngIf="post.acf.portada?.titulo_boton; else elseTemplate">
                            <span>{{post.acf.portada?.titulo_boton}}</span>  
                        </ng-container>
                        <ng-template #elseTemplate>
                            <span>Ver Plan</span>
                        </ng-template>
                    </p>
                </div>
            </a>
        </div>

    </div>


</section>