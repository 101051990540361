/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./areas-protegidas.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../data-wp.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./areas-protegidas.component";
import * as i7 from "@angular/platform-browser";
var styles_AreasProtegidasComponent = [i0.styles];
var RenderType_AreasProtegidasComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AreasProtegidasComponent, data: {} });
export { RenderType_AreasProtegidasComponent as RenderType_AreasProtegidasComponent };
function View_AreasProtegidasComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "back"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "gradient1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "gradient2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.parent.parent.context.$implicit.acf.portada.fondo) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AreasProtegidasComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "back"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "gradient1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "gradient2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.parent.parent.context.$implicit.acf.portada.imagen_de_fondo_mobile) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AreasProtegidasComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "a", [["routerLink", "/en-construccion/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AreasProtegidasComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AreasProtegidasComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "contentLink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["VER SERVICIO"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/en-construccion/"; _ck(_v, 1, 0, currVal_2); var currVal_3 = !_co.mobile; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.mobile; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.parent.context.$implicit.acf.portada.titulo; _ck(_v, 7, 0, currVal_5); var currVal_6 = _v.parent.context.$implicit.acf.portada.bajada; _ck(_v, 8, 0, currVal_6); }); }
function View_AreasProtegidasComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "back"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "gradient1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "gradient2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.parent.parent.context.$implicit.acf.portada.fondo) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AreasProtegidasComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "back"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "gradient1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "gradient2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + _v.parent.parent.context.$implicit.acf.portada.imagen_de_fondo_mobile) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AreasProtegidasComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AreasProtegidasComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AreasProtegidasComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "contentLink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["VER SERVICIO"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "/", _v.parent.context.$implicit.acf.link, ""); _ck(_v, 1, 0, currVal_2); var currVal_3 = !_co.mobile; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.mobile; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.parent.context.$implicit.acf.portada.titulo; _ck(_v, 7, 0, currVal_5); var currVal_6 = _v.parent.context.$implicit.acf.portada.bajada; _ck(_v, 8, 0, currVal_6); }); }
function View_AreasProtegidasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "content-plan"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "section-plan"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AreasProtegidasComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AreasProtegidasComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.acf.portada.en_construccion; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_v.context.$implicit.acf.portada.en_construccion; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AreasProtegidasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "planes"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AreasProtegidasComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AreasProtegidasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-areas-protegidas", [], null, null, null, View_AreasProtegidasComponent_0, RenderType_AreasProtegidasComponent)), i1.ɵprd(512, null, i4.DataWpServiceAreasProtegidasHijos, i4.DataWpServiceAreasProtegidasHijos, [i5.HttpClient]), i1.ɵprd(512, null, i4.DataWpServiceAreasProtegidas, i4.DataWpServiceAreasProtegidas, [i5.HttpClient]), i1.ɵdid(3, 114688, null, 0, i6.AreasProtegidasComponent, [i4.DataWpServiceAreasProtegidasHijos, i4.DataWpServiceAreasProtegidas, i3.Router, i7.Title, i7.Meta], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var AreasProtegidasComponentNgFactory = i1.ɵccf("app-areas-protegidas", i6.AreasProtegidasComponent, View_AreasProtegidasComponent_Host_0, {}, {}, []);
export { AreasProtegidasComponentNgFactory as AreasProtegidasComponentNgFactory };
