<section class="headerEmpresas">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9">
                <h2>GESTIONES ONLINE</h2>
                <p>
                    Bienvenido <strong>{{datosEmpresa.empresa_nombre}}</strong> <br>
                    Cuit: <strong>{{datosEmpresa.empresa_cuit}}</strong> <br>
                    <a routerLink="/atencion-al-socio/gestion-online/empresas/perfil-empresa">VER MIS DATOS</a><br>
                    <a routerLink="/" (click)='logout()'>CERRAR SESIÓN</a>
                </p>
            </div>
            <!-- <div class="col-12 col-lg-3">
                <a routerLink="/gestion-online/socios/credencial">
                    <div class="boxVisualizar">                    
                        <p><img src="assets/img/icon-socios-visualizar.png" alt=""></p>
                        <p>Credencial  <br> Digital</p>
                    </div>
                </a>
            </div> -->
        </div>
    </div>
</section>

<section class="contentButtons">
    <div class="container">
        <div class="row mb-4">
            <div class="col-lg col-12">
                <a routerLink="/atencion-al-socio/gestion-online/empresas/empresa-facturacion">
                    <div class="itemsEmpresas">
                        <img src="assets/img/icon-socios-01.png" alt=""> <br>
                        Facturación
                    </div>
                </a>
            </div>
            <div class="col-lg col-12" (click)="openModalFormsLg(modalContrato)">
                <div class="itemsEmpresas">
                    <img src="assets/img/icon-socios-03.png" alt=""> <br>
                    Pedido de Contrato
                </div>
            </div>
            <!-- <div class="col-lg col-12">
                <div class="itemsEmpresas" (click)="openModalForms(modalReclamo)">
                    <img src="assets/img/icon-socios-03.png" alt=""> <br>
                    Reclamo de cobrador 
                </div>
            </div> -->
            <div class="col-lg col-12">
                <div class="itemsEmpresas" (click)="openModalFormsLg(modalCambioDomicilio)">
                    <img src="assets/img/icon-socios-04.png" alt=""> <br>
                    cambio de domicilio
                </div>
            </div>
            <div class="col-lg col-12">
                <div class="itemsEmpresas" (click)="openModalForms(modalCambioTitularidad)"> 
                    <img src="assets/img/icon-socios-03.png" alt=""> <br>
                    cambio de titularidad
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg col-12">
                <div class="itemsEmpresas" (click)="openModalForms(modalExtensionAreaProtegida)">  
                    <img src="assets/img/icon-socios-08.png" alt=""> <br>
                    Extensión de Area Protegida
                </div>                
            </div>
            <div class="col-lg col-12">
                <div class="itemsEmpresas" (click)="openModalForms(modalReclamoCorreoInicial)"> 
                    <img src="assets/img/icon-socios-07.png" alt=""> <br>
                    Reclamo de correo inicial
                </div>                
            </div>
            <div class="col-lg col-12">
                <div class="itemsEmpresas" (click)="openModalForms(modalConstanciaAsociacion)">  
                    <img src="assets/img/icon-socios-09.png" alt=""> <br>
                    Constancia de Asociación
                </div>                
            </div>
            <div class="col-lg col-12">
                <div class="itemsEmpresas" (click)="openModalForms(modalConstanciaAtencion)"> 
                    <img src="assets/img/icon-socios-09.png" alt=""> <br>
                    constancia de atención
                </div>                
            </div>
            <div class="col-lg col-12">
                <div class="itemsEmpresas" (click)="openModalForms(modalConsultas)"> 
                    <img src="assets/img/icon-socios-10.png" alt=""> <br>
                    Consultas / sugerencias
                </div>                
            </div>
        </div>
        <!-- <div class="row">
            <div class="col">
                <div class="itemVittalIdoc">
                    <div class="row h-100">
                        <div class="col-12 col-lg-4 my-auto contentImg">
                            <img src="assets/img/icon-idoc-socios.png" width="60px" alt="">
                        </div>
                        <div class="col-12 col-lg-8 px-lg-5 my-auto">
                            <p>¿Necesitas un médico ahora? <br> <strong><a href="https://www.vittalidoc.com/" target="_blank"> Ingresá a vittal iDoc</a></strong></p>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div> -->
    </div>
</section>



<ng-template #modalContrato let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-pedido-de-contrato (closeModal)="closeModal()"></app-pedido-de-contrato>
    </div>
</ng-template>

<ng-template #modalReclamo let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-reclamo-de-cobrador (closeModal)="closeModal()"></app-reclamo-de-cobrador>
    </div>
</ng-template>

<ng-template #modalCambioDomicilio let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-cambio-domicilio-empresas (closeModal)="closeModal()"></app-cambio-domicilio-empresas>
    </div>
</ng-template>

<ng-template #modalCambioTitularidad let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-cambio-de-titularidad (closeModal)="closeModal()"></app-cambio-de-titularidad>        
    </div>
</ng-template>

<ng-template #modalSolicitudCredencial let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-solicitud-de-credencial (closeModal)="closeModal()"></app-solicitud-de-credencial>
    </div>
</ng-template>

<ng-template #modalReclamoCorreoInicial let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-reclamo-de-correo-inicial-empresas (closeModal)="closeModal()"></app-reclamo-de-correo-inicial-empresas>
    </div>
</ng-template>

<ng-template #modalExtensionAreaProtegida let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-extension-area-protegida (closeModal)="closeModal()"></app-extension-area-protegida>
    </div>
</ng-template>

<ng-template #modalConstanciaAtencion let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-constancia-atencion-empresas (closeModal)="closeModal()"></app-constancia-atencion-empresas>
    </div>
</ng-template>

<ng-template #modalConsultas let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-consulta-sugerencias-empresas (closeModal)="closeModal()"></app-consulta-sugerencias-empresas>        
    </div>
</ng-template>

<ng-template #modalConstanciaAsociacion let-modal>
    <div class="modal-body contentModal">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <app-constancia-de-asociacion (closeModal)="closeModal()"></app-constancia-de-asociacion>        
    </div>
</ng-template>
