<div [style.display]="isHome ? 'block' : 'none'"class="flotante" id="flotante" (click)="openModalCotizar(mymodal)">
    <span>¡Cotizá tu plan ideal!</span>
</div>

<ng-template #mymodal let-modal>
  <div class="modal-header"  style="padding: 15px 0 0 0;">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">

    <ng-container>    
      <div class="row" id="formCotizar">
        <div class="col-12 col-lg-4 contentImg" style="background-image: url('assets/img/pic-cotizar.png');"></div>
        <div class="col-12 col-lg-8 boxForm">
          <app-form-cotizadores [tituloForm]="'¡Cotizá tu plan ideal!'" [codePrice]="1059" [codeForm]="1168" [isBitrix]='bitrix' [isMobile]="mobile"></app-form-cotizadores>
        </div>  
      </div>                
    </ng-container>


  </div>
</ng-template>




