<ng-container *ngIf="posts$">
  <ng-container *ngFor="let post of posts$ | async">
    <div *ngIf="post.acf.whatsapp.habilitar" class="flotante-whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone={{
          post.acf.whatsapp.numero_sin_espacios
        }}"
        onclick="gtag('event', 'whatsapp');"
        target="_blank"
        ><img src="assets/img/icon-whatsapp.svg" alt=""
      /></a>
    </div>
  </ng-container>
</ng-container>
