<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-12 datos">
        <ul>
          <li *ngIf="viewSize <= 500">
            <img src="assets/img/footer-icon-1.svg" alt="" />
            <strong>Asociate</strong>
            <a href="tel:08102228488"> 0810-222-8488</a>
          </li>
          <li *ngIf="viewSize > 500">
            <img src="assets/img/footer-icon-1.svg" alt="" />
            <strong>Asociate</strong> 0810-222-8488
          </li>
          <li *ngIf="viewSize <= 500">
            <img src="assets/img/footer-icon-1.svg" alt="" />
            <strong>Centro de atención al socio</strong> <br />
            <a href="tel:08108884825"> 0810-888-48825</a>
          </li>
          <li *ngIf="viewSize > 500">
            <img src="assets/img/footer-icon-1.svg" alt="" />
            <strong>Centro de atención al socio</strong> <br />
            0810-888-48825
          </li>
          <li *ngIf="viewSize <= 500">
            <img src="assets/img/footer-icon-2.svg" alt="" />
            <strong>Whatsapp</strong>
            <a href="https://wa.me/+5491130326052"> +54 9 11 3032-4611</a>
          </li>
          <li *ngIf="viewSize > 500">
            <img src="assets/img/footer-icon-2.svg" alt="" />
            <strong>Whatsapp</strong>
            <a href="https://wa.me/+5491130326052" target="_blank">
              +54 9 11 3032-6052</a
            >
          </li>
          <li>
            <img src="assets/img/footer-icon-3.svg" alt="" />
            <strong>Av. Álvarez Thomas 1154</strong> <br />
            (C1427CCY) C.A.B.A
          </li>
          <li>
            <img src="assets/img/footer-icon-4.svg" alt="" />
            <strong
              ><a href="mailto:info@vittal.com.ar"
                >info@vittal.com.ar</a
              ></strong
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-12 form">
        <p>
          Para recibir nuestro newsletter con consejos de salud, por favor,
          completá el siguiente formulario:
        </p>

        <form [formGroup]="form" (ngSubmit)="submitForm()">
          <input placeholder="Nombre" formControlName="Nombre" />
          <input placeholder="Email" formControlName="email" />
          <button>Suscribirse</button>
        </form>
      </div>
      <div class="col-lg-3 col-12 mercado">
        <img src="assets/img/mercadopago.png" alt="" />
        <div class="libro">
          <h2>
            <i class="fa fa-book" aria-hidden="true"></i>
            <a
              [routerLink]="['/footer/libro-quejas']"
              routerLinkActive="router-link-active"
              >Libro de quejas online</a
            >
          </h2>
          <p>
            <strong>vittal</strong> Socorro Médico Privado S.A. <br />
            CUIT 30-61221341-7 <br />
            R.N.E.M.P. N° 1305 <br />
            <a href="https://www.argentina.gob.ar/sssalud" target="_blank"
              >www.sssalud.gov.ar</a
            >
          </p>
          <p>
            <a href="assets/terminos-y-condiciones.pdf" target="_blank"
              >VER TÉRMINOS Y CONDICIONES</a
            >
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-12 iso">
        <img src="assets/img/BV_Certification-footer_ISO9001-web.jpg" alt="" />
        <a href="assets/DNPDP.pdf" target="_blank"
          ><img src="assets/img/datos-qr-3.png" alt=""
        /></a>
      </div>
    </div>
  </div>
</footer>
<app-chatbot></app-chatbot>
