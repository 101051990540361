/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./libro-quejas.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../data-wp.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./libro-quejas.component";
var styles_LibroQuejasComponent = [i0.styles];
var RenderType_LibroQuejasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LibroQuejasComponent, data: {} });
export { RenderType_LibroQuejasComponent as RenderType_LibroQuejasComponent };
export function View_LibroQuejasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "min-height: 680px; margin-bottom: -6px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "Titulo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Libro de quejas online\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["id", "contentAll"], ["style", "width: 100%;"]], null, null, null, null, null))], null, null); }
export function View_LibroQuejasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-libro-quejas", [], null, null, null, View_LibroQuejasComponent_0, RenderType_LibroQuejasComponent)), i1.ɵprd(512, null, i2.DataWpServiceFooter, i2.DataWpServiceFooter, [i3.HttpClient]), i1.ɵdid(2, 114688, null, 0, i4.LibroQuejasComponent, [i2.DataWpServiceFooter, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LibroQuejasComponentNgFactory = i1.ɵccf("app-libro-quejas", i4.LibroQuejasComponent, View_LibroQuejasComponent_Host_0, {}, {}, []);
export { LibroQuejasComponentNgFactory as LibroQuejasComponentNgFactory };
