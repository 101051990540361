<div class="anchor" id="up"></div>
<section class="login">
  <div class="container">
    <div class="row">
      <div *ngIf="isMobile" class="col-12 titulosMobile" id="titulosMobile">
        <h2 *ngIf="!isRegister">
          Bienvenido a<br />
          Gestiones online
        </h2>
        <p *ngIf="screenSize < 470 && isHome">Seleccionar tipo de socio:</p>
        <div
          *ngIf="screenSize < 470 && !isHome"
          class="contentImgMobile"
          id="imgHover"
        >
          <div
            (click)="volverGestion()"
            [ngx-scroll-to]="'#up'"
            class="btnVolverGestion"
            id="volverGestion"
          >
            Volver atrás
          </div>
        </div>
      </div>
    </div>

    <div class="contentLogin">
      <div class="row m-0">
        <div class="col-12 order-lg-2 col-lg-6 px-lg-5 columnLogin">
          <div class="contentTxt my-auto show" id="contentLogin">
            <h2>
              Bienvenido a<br />
              Gestiones online
            </h2>
            <p *ngIf="isHome" style="position: relative; bottom: 60px">
              Seleccionar tipo de socio:
            </p>

            <div class="contentBtnsLogin show" id="loginFront">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <!-- <a id="btnSocio" (click)="mostrarSocio()" (mouseover)="hoverSocio()">INDIVIDUOS</a> -->
                  <a id="btnSocio" (click)="mostrarSocio()">INDIVIDUOS</a>
                </div>
                <div class="col-12 col-lg-6">
                  <!-- <a id="btnEmpresa" (click)="mostrarEmpresa()" (mouseover)="hoverEmpresa()">EMPRESA</a> -->
                  <a id="btnEmpresa" (click)="mostrarEmpresa()">EMPRESA</a>
                </div>
              </div>
              <div class="acceso" style="margin: 60px 0 0 0">
                <span (click)="openModalProblema(modalProblemasHome)"
                  ><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  ¿Problemas con el acceso?</span
                >
              </div>
            </div>

            <div class="contentLoginSocio" id="loginSocios">
              <app-login-socio></app-login-socio>
              <div class="acceso">
                <span (click)="openModalProblema(modalProblemasSocios)"
                  ><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  ¿Problemas con el acceso?</span
                >
              </div>
            </div>
            <div class="contentLoginEmpresa" id="loginEmpresa">
              <app-login-empresa></app-login-empresa>
              <div class="acceso">
                <span (click)="openModalProblema(modalProblemasEmpresas)"
                  ><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  ¿Problemas con el acceso?</span
                >
              </div>
            </div>
          </div>
          <div class="contentRegistro" id="contentRegistro">
            <div class="registroSocios" id="registroSocios">
              <app-registro-socio></app-registro-socio>
            </div>
            <div class="registroEmpresa" id="registroEmpresa">
              <app-registro-empresa></app-registro-empresa>
            </div>
          </div>
        </div>

        <div
          *ngIf="!isMobile"
          class="col-12 order-lg-1 col-lg-6 p-0"
          style="height: 758px"
        >
          <div class="contentImg" id="imgHover">
            <div
              (click)="volverGestion()"
              [ngx-scroll-to]="'#up'"
              class="btnVolverGestion"
              id="volverGestion"
            >
              Volver atrás
            </div>
          </div>
        </div>
        <div *ngIf="isMobile" class="col-12 order-lg-1 col-lg-6 p-0">
          <div class="contentImg" id="imgHover">
            <div
              (click)="volverGestion()"
              [ngx-scroll-to]="'#up'"
              class="btnVolverGestion"
              id="volverGestion"
            >
              Volver atrás
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #modalProblemasHome let-modal>
  <div class="modal-body contentModal">
    <h2>¿Problemas con el acceso?</h2>
    <p>- Presioná sobre "Ingreso por primera vez" y registrate.</p>
    <p>
      - Asegurate de validar tu cuenta ingresando a tu correo. Por favor revisá
      también la carpeta spam o correo no deseado.
    </p>
    <p style="text-align: justify">
      - Si tenés problemas al ingresar tus datos, podés comunicarte al
      <strong>0810-888-48825</strong> de lunes a viernes de 9 a 19 hs, por el
      email a
      <strong><a href="mailto:cas@vittal.com.ar">cas@vittal.com.ar</a></strong>
      o por Whatsapp al
      <strong
        ><a href="https://wa.me/+5491130324611" target="_blank"
          >+ 54-911-3032-4611</a
        ></strong
      >
    </p>
    <div class="contentBtns">
      <p>
        <span
          class="btnClose"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
          >VOLVER</span
        >
      </p>
    </div>
  </div>
</ng-template>

<ng-template #modalProblemasSocios let-modal>
  <div class="modal-body contentModal">
    <h2>¿Problemas con el acceso?</h2>
    <p>- Presioná sobre "Ingreso por primera vez" y registrate.</p>
    <p>
      - Asegurate de validar tu cuenta ingresando a tu correo. Por favor revisá
      también la carpeta spam o correo no deseado.
    </p>
    <p style="text-align: justify">
      - Si tenés problemas al ingresar tus datos, podés comunicarte al
      <strong>0810-888-48825</strong> de lunes a viernes de 9 a 19 hs, por el
      email a
      <strong><a href="mailto:cas@vittal.com.ar">cas@vittal.com.ar</a></strong>
      o por Whatsapp al
      <strong
        ><a href="https://wa.me/+5491130324611" target="_blank"
          >+ 54-911-3032-4611</a
        ></strong
      >
    </p>
    <div class="contentBtns">
      <p>
        <span
          class="btnClose"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
          >VOLVER</span
        >
      </p>
      <p>
        <span
          class="btnIngreso"
          aria-label="Close"
          (click)="mostrarRegistroSocios()"
          (click)="modal.dismiss('Cross click')"
          >Ingreso por primera vez</span
        >
      </p>
    </div>
  </div>
</ng-template>

<ng-template #modalProblemasEmpresas let-modal>
  <div class="modal-body contentModal">
    <h2>¿Problemas con el acceso?</h2>
    <p>- Presioná sobre "Ingreso por primera vez" y registrate.</p>
    <p>
      - Asegurate de validar tu cuenta ingresando a tu correo. Por favor revisá
      también la carpeta spam o correo no deseado.
    </p>
    <p style="text-align: justify">
      - Si tenés problemas al ingresar tus datos, podés comunicarte al
      <strong>0810-888-48825</strong> de lunes a viernes de 9 a 19 hs, por el
      email a
      <strong><a href="mailto:cas@vittal.com.ar">cas@vittal.com.ar</a></strong>
      o por Whatsapp al
      <strong
        ><a href="https://wa.me/+5491121559639" target="_blank"
          >+ 11-2155-9639</a
        ></strong
      >
    </p>
    <div class="contentBtns">
      <p>
        <span
          class="btnClose"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
          >VOLVER</span
        >
      </p>
      <p>
        <span
          class="btnIngreso"
          aria-label="Close"
          (click)="mostrarRegistroEmpresa()"
          (click)="modal.dismiss('Cross click')"
          >Ingreso por primera vez</span
        >
      </p>
    </div>
  </div>
</ng-template>
