<section class="blogHome">
    <div class="container">

        <div class="row featured " *ngFor="let postsPadre of postsPadre">
            <div class="col-12 contentTxt">
                <h2 [innerHTML]="postsPadre.acf.portada.titulo"></h2>
                <p class="bajada" [innerHTML]="postsPadre.acf.portada.bajada"></p>               
                
            </div>
        </div>

        <div class="w-100"></div>
        <div class="row">
            <div *ngFor="let post of posts; let i=index" class="itemNotaBlog col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div class="content">
                    <a routerLink="/servicios-adicionales/{{post.slug}}">
                        <div class="fotoPpal" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                            <img src="assets/img/back-items-home.png" alt="" class="w-100">
                        </div>
                        <h2 class="w-100 row mx-0"><span class="my-auto w-100">{{post.acf.portada.titulo}}</span></h2>
                        <div class="clearfix"></div>
                        <div class="hover w-100 h-100 row" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}" >
                            <div class="txt my-auto  w-100">
                                <h2>{{post.acf.portada.titulo}}</h2>
                                <p>{{post.acf.portada.descripcion}}</p>
                                <div class="boton">{{post.acf.portada.label_boton}}</div>
                            </div>
                        </div>
                    </a>
                </div>
                
            </div>
        </div>
        <div class="row featured bottom" *ngFor="let postsPadre of postsPadre">
            <div class="col-12 contentTxt">
                <p class="contentLink"><a routerLink="{{postsPadre.acf.portada.link}}" [innerHTML]="postsPadre.acf.portada.link_label"></a></p>
            </div>
        </div>
    </div>
</section>

