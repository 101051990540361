<section class="planes">

    <div class="content-plan" *ngFor="let post of posts">

        <div class="section-plan" *ngIf="!post.acf.portada.plan_oculto" [ngClass]="{'promos': post.acf.portada.promo}">
            <a (click)="contratar(post.slug)"> 
                <div class="back" *ngIf="!mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen_fondo + ')'}">
                    <div class="gradient1"></div>
                    <div class="gradient2"></div>
                </div>
                <div class="back" *ngIf="mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen_fondo_mobile + ')'}">
                    <div class="gradient1"></div>
                    <div class="gradient2"></div>
                </div>    
                <div class="container">
                    <h2 [innerHTML]='post.acf.portada.titulo'></h2>
                    <p [innerHTML]='post.acf.portada.bajada'></p>
                    <!-- <p class="contentLink"><span>VER PLAN</span></p> -->
                    <p class="contentLink" (click)="contratar(post.slug)"><span>CONTRATAR</span></p>

                </div>
            </a>
        </div>

    </div>


</section>