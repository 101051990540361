<section class="principales" >
    <div class="row h-100" *ngFor="let post of posts$ | async">
        <div *ngFor="let menu of post.acf.menu;" class="itemDestacado col-12 col-md-3 p-0"><a routerLink="{{menu.link}}" class="w-100 h-100 row"><span class="w-100">{{menu.nombre}} </span><small class="w-100" [innerHTML]='menu.descripcion'></small></a></div>        
    </div>
</section>





