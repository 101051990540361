<section id="anchor1"></section>

<section *ngFor="let postsT of postsTitulos"   class="headerBlog" [ngStyle]="{'background-image': 'url(' + postsT.acf?.imagen_fondo + ')'}">
    <div class="container h-100 ">
        <div class="row h-75">
            <div class="col my-auto">
                <div class="contentTxt">
                    <h2>{{postsT.acf.titulo}}</h2>
                    <p>{{postsT.acf.subtitulo}}</p>
                </div>
            </div>
        </div>
    </div>
</section> 



<section class="blog">
    <div class="container">
        <div class="row" *ngIf="post">
            <div class="col-12 order-last order-lg-0 col-lg-3">
                <app-side-categorias [slugCategoria]="post.name"></app-side-categorias>
            </div>

            <div class="col-12  col-lg-9" >
                <app-list [slugCategoria]="post.name"></app-list>
                

            </div>

        </div>

    </div>

</section>


