/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./singles.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../planes/promos/promos.component.ngfactory";
import * as i3 from "../data-wp.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../planes/promos/promos.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../planes/plan-single/plan-single.component.ngfactory";
import * as i9 from "../planes/plan-single/plan-single.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "@angular/common";
import * as i12 from "../planes/promos/promo-single/promo-single.component.ngfactory";
import * as i13 from "../planes/promos/promo-single/promo-single.component";
import * as i14 from "../blog/single-blog/single-blog.component.ngfactory";
import * as i15 from "../blog/single-blog/single-blog.component";
import * as i16 from "../page-not-found/page-not-found.component.ngfactory";
import * as i17 from "../page-not-found/page-not-found.component";
import * as i18 from "./singles.component";
var styles_SinglesComponent = [i0.styles];
var RenderType_SinglesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SinglesComponent, data: {} });
export { RenderType_SinglesComponent as RenderType_SinglesComponent };
function View_SinglesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-promos", [], null, null, null, i2.View_PromosComponent_0, i2.RenderType_PromosComponent)), i1.ɵprd(512, null, i3.DataWpServicePromos, i3.DataWpServicePromos, [i4.HttpClient]), i1.ɵprd(512, null, i3.DataWpServicePromosPadre, i3.DataWpServicePromosPadre, [i4.HttpClient]), i1.ɵdid(4, 114688, null, 0, i5.PromosComponent, [i3.DataWpServicePromos, i3.DataWpServicePromosPadre, i6.Router, i7.Title, i7.Meta], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
function View_SinglesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-plan-single", [], null, null, null, i8.View_PlanSingleComponent_0, i8.RenderType_PlanSingleComponent)), i1.ɵprd(512, null, i3.DataWpServicePlanesChild, i3.DataWpServicePlanesChild, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i9.PlanSingleComponent, [i10.NgbModal, i10.NgbCarouselConfig, i3.DataWpServicePlanesChild, i6.ActivatedRoute, i7.DomSanitizer, i7.Title, i7.Meta, i4.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_SinglesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SinglesComponent_3)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["singlePlan", 2]], null, 0, null, View_SinglesComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.post.id == 180); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SinglesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-promo-single", [], null, null, null, i12.View_PromoSingleComponent_0, i12.RenderType_PromoSingleComponent)), i1.ɵprd(512, null, i3.DataWpServicePromos, i3.DataWpServicePromos, [i4.HttpClient]), i1.ɵdid(3, 114688, null, 0, i13.PromoSingleComponent, [i3.DataWpServicePromos, i6.ActivatedRoute, i7.DomSanitizer, i7.Title, i7.Meta], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_SinglesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-single-blog", [], null, null, null, i14.View_SingleBlogComponent_0, i14.RenderType_SingleBlogComponent)), i1.ɵprd(512, null, i3.DataWpServiceBlogSingles, i3.DataWpServiceBlogSingles, [i4.HttpClient]), i1.ɵdid(3, 114688, null, 0, i15.SingleBlogComponent, [i3.DataWpServiceBlogSingles, i6.ActivatedRoute, i7.Title, i7.Meta], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_SinglesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SinglesComponent_2)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SinglesComponent_5)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SinglesComponent_6)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.post.parent == 116); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.post.parent == 180); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isBlog; _ck(_v, 6, 0, currVal_2); }, null); }
function View_SinglesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-page-not-found", [], null, null, null, i16.View_PageNotFoundComponent_0, i16.RenderType_PageNotFoundComponent)), i1.ɵdid(2, 114688, null, 0, i17.PageNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_SinglesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SinglesComponent_8)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.postSubscriptionCompleted && _co.blogSubscriptionCompleted); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SinglesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SinglesComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseBlock", 2]], null, 0, null, View_SinglesComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.post; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SinglesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-singles", [], null, null, null, View_SinglesComponent_0, RenderType_SinglesComponent)), i1.ɵprd(512, null, i3.DataWpServicePages, i3.DataWpServicePages, [i4.HttpClient]), i1.ɵprd(512, null, i3.DataWpServiceBlogSingles, i3.DataWpServiceBlogSingles, [i4.HttpClient]), i1.ɵdid(3, 114688, null, 0, i18.SinglesComponent, [i3.DataWpServicePages, i6.ActivatedRoute, i3.DataWpServiceBlogSingles], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var SinglesComponentNgFactory = i1.ɵccf("app-singles", i18.SinglesComponent, View_SinglesComponent_Host_0, {}, {}, []);
export { SinglesComponentNgFactory as SinglesComponentNgFactory };
