<section class="headerSocios">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9">
                <h2>Gestion Online - Socios</h2>
                <p>
                    Bienvenido <strong>{{datosSocio.socio_nombre +' '+ datosSocio.socio_apellido}}</strong> <br>
                    Nº de Socio: <strong>{{datosSocio.socio_numero}}</strong> <br>
                    <a routerLink="/atencion-al-socio/gestion-online/socios/perfil-usuario">VER MIS DATOS</a> <br>
                    <a routerLink="/" (click)='logout()'>CERRAR SESIÓN</a> <br> 
                    <a routerLink="/atencion-al-socio/gestion-online/socios" class="topVolver">Volver</a>
                </p>
            </div>
        </div>
    </div>
</section>
<section class="credenciales">
    <div class="container">
        <div class="content">
            <h2><img src="assets/img/icon-socios-06.png" alt=""> Credencial digital</h2>
            <p><strong>Grupo familiar:</strong></p>
            <div class="grupo-familiar">            
                <div class="credencial-individual">
                    <div class="containerCard" id="credencial">
                        <img src="assets/img/credencial-{{datosSocio.socio_dominio}}.png" alt="">
                        <div class="txt">
                            <h3>{{datosSocio.socio_nombre +' '+ datosSocio.socio_apellido}}</h3>
                            <p>Nº de socio <br> <strong>{{datosSocio.socio_numero}}</strong></p>
                        </div>
                    </div>
                    <div class="contentDescargar">
                        <p>
                            <button class="btn-download" (click)="printPdf();">
                                Descargar PDF <img src="assets/img/icon-download.png" alt="Descargar PDF" class="pdf-icon">
                            </button>
                        </p>
                    </div>
                </div>
            </div>                    
        </div>
    </div>
</section>