<div class="contentModalGral px-3 pt-4">
    <div class="row titulos " >
        <div class="col-12 px-4">
            <h2><img src="assets/img/icon-socios-09.png" alt=""> Constancia de atención</h2>
        </div>  
              
    </div>
    <form class="formSolicitarCredencial" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="row justify-content-end formulario">
            <div class="col-12 col-lg-12">
                <div class="row ">
                    <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': fecAtencion.invalid && (fecAtencion.dirty || fecAtencion.touched)  }" >
                        <input type="date" formControlName="fecAtencion"  id="fecAtencion" max={{hoy}} cdkTouchInitial >
                        <span [ngClass]="{'spanInvalido ': fecAtencion.invalid && (fecAtencion.dirty || fecAtencion.touched)  } ">Fecha de Atención *</span>
                        <div *ngIf="fecAtencion.invalid && (fecAtencion.dirty || fecAtencion.touched)" class="invalido">
                            <div *ngIf="fecAtencion.errors.required">
                                La fecha de Atención es requerida.
                            </div>
                        </div>  
                    </div>

                    <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': telefono.invalid && (telefono.dirty || telefono.touched)  }" >
                        <input type="tel" formControlName="telefono"  id="telefono">
                        <span [ngClass]="{'spanInvalido ': telefono.invalid && (telefono.dirty || telefono.touched)  } ">Teléfono *</span>
                        <div *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)" class="invalido">
                            <div *ngIf="telefono.errors.required">
                                El Teléfono es requerido.
                            </div>
                        </div>  
                    </div>

                </div>
            </div>

            <div  class="col-12 col-lg-8 boxObligatoriosForms">
                <p><small>* Campos obligatorios</small></p>
            </div>
            <div class="col-12 col-lg-4 contentButton">
                <button [disabled]="contactForm.invalid" >ENVIAR</button>
            </div>  
        </div>
    </form>

</div>


<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>