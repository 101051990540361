<div class="contentCategorias">
    <h2>CATEGORÍAS</h2>
    <ul>
        <li *ngFor="let post of posts">
            <a [ngClass]="{'active': slugCategoria == post.name }" href="blog/cat/{{post.slug}}"  [innerHTML]='post.name'></a>
        </li>        
        <li class="clearfix"></li>
    </ul>    
    <div class="clearfix"></div>
</div>
