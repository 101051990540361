/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-categorias.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../data-wp.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./side-categorias.component";
import * as i6 from "@angular/router";
var styles_SideCategoriasComponent = [i0.styles];
var RenderType_SideCategoriasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideCategoriasComponent, data: {} });
export { RenderType_SideCategoriasComponent as RenderType_SideCategoriasComponent };
function View_SideCategoriasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[8, "href", 4], [8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "active": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, (_co.slugCategoria == _v.context.$implicit.name)); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "blog/cat/", _v.context.$implicit.slug, ""); var currVal_1 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SideCategoriasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "contentCategorias"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CATEGOR\u00CDAS"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideCategoriasComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "li", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posts; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_SideCategoriasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-side-categorias", [], null, null, null, View_SideCategoriasComponent_0, RenderType_SideCategoriasComponent)), i1.ɵprd(512, null, i3.DataWpServiceCategorias, i3.DataWpServiceCategorias, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i5.SideCategoriasComponent, [i3.DataWpServiceCategorias, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SideCategoriasComponentNgFactory = i1.ɵccf("app-side-categorias", i5.SideCategoriasComponent, View_SideCategoriasComponent_Host_0, { slugCategoria: "slugCategoria" }, {}, []);
export { SideCategoriasComponentNgFactory as SideCategoriasComponentNgFactory };
