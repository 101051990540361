<ng-container *ngIf="post">
    <!-- Maquetado diferente para IDOC -->
    <ng-container *ngIf="post.acf.plantilla == 'idoc'; else elseBlock">
      <app-idoc
        [headerImange]='post.acf.idoc.header.imagen'
        [headerLogo]='post.acf.idoc.header.logo'
        [headerBajada]='post.acf.idoc.header.bajada'
        [headerIntroduccion]='post.acf.idoc.header.introduccion'
        [headerImgBanner]='post.acf.idoc.header.imagen_banner'

        [Bullets1Icono]='post.acf.idoc.bullets.items_1.icono'
        [Bullets1Titulo]='post.acf.idoc.bullets.items_1.titulo'
        [Bullets1Bajada]='post.acf.idoc.bullets.items_1.bajada'
        [Bullets2Icono]='post.acf.idoc.bullets.items_2.icono'
        [Bullets2Titulo]='post.acf.idoc.bullets.items_2.titulo'
        [Bullets2Bajada]='post.acf.idoc.bullets.items_2.bajada'
        [Bullets3Icono]='post.acf.idoc.bullets.items_3.icono'
        [Bullets3Titulo]='post.acf.idoc.bullets.items_3.titulo'
        [Bullets3Bajada]='post.acf.idoc.bullets.items_3.bajada'
        [BulletsImg]='post.acf.idoc.bullets.imagen'

        [DescargaTitulo]='post.acf.idoc.descarga.titulo'
        [DescargaSubTitulo]='post.acf.idoc.descarga.subtitulo'
        [DescargaTitulo_box_1]='post.acf.idoc.descarga.titulo_box_1'
        [DescargaLink_1_box_1]='post.acf.idoc.descarga.link_1_box_1'
        [DescargaLink_2_box_1]='post.acf.idoc.descarga.link_2_box_1'
        [DescargaTitulo_box_2]='post.acf.idoc.descarga.titulo_box_2'
        [DescargaLink_box_2]='post.acf.idoc.descarga.link_box_2'

        [pasosPaso_1Imagen]='post.acf.idoc.pasos.paso_1.imagen'
        [pasosPaso_1Bajada]='post.acf.idoc.pasos.paso_1.bajada'
        [pasosPaso_2Imagen]='post.acf.idoc.pasos.paso_2.imagen'
        [pasosPaso_2Bajada]='post.acf.idoc.pasos.paso_2.bajada'
        [pasosPaso_3Imagen]='post.acf.idoc.pasos.paso_3.imagen'
        [pasosPaso_3Bajada]='post.acf.idoc.pasos.paso_3.bajada'
        [pasosTextoFinal]='post.acf.idoc.pasos.texto_final'
        [legales]='post.acf.idoc.legales'


      ></app-idoc>

    </ng-container>

  <!-- Maquetado normal para servicios que no sean IDOC -->
  <ng-template #elseBlock>
    
    <section  class="servicios-single">
      <div class="row m-0 h-100" >
          <div class="col-12 col-lg-6 contentLeft p-5" [ngStyle]="{'background-image': 'url(' + post.acf.interior.imagen_fondo + ')'}"  >
              <div class="row m-0 h-100">
                  <div class="col-12 py-5">
                      <h2 [innerHTML]="post.acf.interior.titulo"></h2>
                      <p [innerHTML]="post.acf.interior.subtitulo"></p>
                  </div>
              </div>
          </div>

          <div class="col-12 col-lg-6 contentRight p-5" >
            <div class="content" [innerHTML]='post.acf.interior.contenido' >
              
            </div>
              <p class="contentLink" *ngIf="post.acf.interior.link.url">
                  
                  <a *ngIf="post.acf.interior.link.externo" href="{{post.acf.interior.link.url}}" target="_blank">{{post.acf.interior.link.label}}</a>
                  <a *ngIf="!post.acf.interior.link.externo" routerLink="/{{post.acf.interior.link.url}}">{{post.acf.interior.link.label}}</a>
              </p>

              <div class="contentOnDemand" *ngIf="post.acf.interior.on_demand.titulo">
                <p [innerHTML]='post.acf.interior.on_demand.titulo' class="tituloOnDemand"></p>
                <p [innerHTML]='post.acf.interior.on_demand.subtitulo' class="subTituloOnDemand"></p>
                <p class="contentLinkOnDemand" *ngIf="post.acf.interior.on_demand.link">
                  <a href="{{post.acf.interior.on_demand.link}}" class="btnOndemand" target="_blank">{{post.acf.interior.on_demand.label}}</a>
                </p>

              </div>


          </div>
          
      </div>
  
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="contentLegales" *ngIf="post.acf.interior.legales">
                <a class="btnLegales" (click)="open(popupLegales)">Ver legales</a>
            </div>
          </div>
        </div>
      </div>
  
      <ng-template #popupLegales let-modal>
        <div class="contentPopUp">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()"><span aria-hidden="true">×</span></button>
            <div *ngIf="post.acf.interior.legales" class="modal-body" [innerHTML]="transform(post.acf.interior.legales)"></div>
        </div>                        
      </ng-template>
  

  
    </section>
    
    <app-nav-servicios [slugId]='post.slug'></app-nav-servicios>

  </ng-template>

</ng-container>







