<section class="header3 back-fixed" style="background-image:url('assets/img/medevac.jpg')">
    <div class="container containerHeader">
        <div class="row h-100">
            <div class="col-12 col-lg-6 my-auto">
                <img src="assets/img/avion-portada.png" alt="">
            </div>
            <div class="col-12 col-lg-6 my-auto">
                <h2>Medevac</h2>
                <p>Servicios Aeromédicos</p>
            </div> 
        </div>
    </div>
</section>
<section class="box2col">
    <div class="container">
        <div class="box">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <p><strong>Más de 30 años innovando</strong> en el mercado de servicios médicos extra-hospitalarios nos han posicionado como la empresa de emergencias médicas más grande de la Argentina.</p>
                </div>
                <div class="col-12 col-lg-6">
                    <p>Disponemos de <strong>personal médico altamente calificado</strong>, la flota más completa de móviles de alta complejidad con equipamiento de avanzada y contamos con la mayor cobertura geográfica del país.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="txt-foto">
    <div class="container content-txt-foto">
        <div class="row">
            <div class="col-12 col-lg-6">
                <h2>Licencias y certificados</h2>
                <p><strong>Medevac</strong> dispone de todos los certificados habilitantes y la logística necesaria propia para realizar traslados aéreos, cumpliendo con los requerimientos de los programas de servicios aeromédicos más demandantes.</p>
            </div>
            <div class="col-12 col-lg-6">
                <img src="assets/img/medevac-pic-1.jpg" alt="">
            </div>
        </div>
    </div>
</section>
<section class="bullets">
    <div class="container content-bullets">
        <div class="row">
            <div class="col-12 col-lg-6">
                <ul>
                    <li>Certificación de Explotador de Servicios Aéreos (CESA) ante la Administración Nacional de Aviación Civil (ANAC).</li>
                    <li>Certificación INMAE de nuestros profesionales médicos.</li>
                </ul>
            </div>
            <div class="col-12 col-lg-6">
                <ul>
                    <li>Curso Periódico Anual + inspección en vuelo con un inspector de ANAC para todos nuestros pilotos.</li>
                    <li>Certificación ISO 9001 2015 en Traslados Sanitarios.</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="servicios">
    <div class="container content-servicios">
        <h2>Nuestros servicios </h2>
        <p>Ofrecemos servicios a la medida de las necesidades de nuestros clientes:</p>
    </div>
    <div class="carrusel">


        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <div class="contentItem">
                    <img src="{{ slide.img }}" alt="" width="100%">
                    <div class="contentTxt">
                        <h2>{{ slide.titulo }}</h2>
                        <p>{{ slide.bajada }}</p>
                    </div>
                </div>

            </div>
        </ngx-slick-carousel>


    </div>
</section>
<section class="header3" style="background-image:url('assets/img/medevac-2.jpg')">
    <div class="container containerHeader">
        <div class="row h-100">
            <div class="col-12 col-lg-6 my-auto"></div>
            <div class="col-12 col-lg-6 my-auto"></div> 
        </div>
    </div>
</section>

<section class="box2col box2colImg">
    <div class="container">
        <div class="box">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>Servicios Aeromédicos</h2>
                    <p><strong>Más de 30 años innovando</strong> en el mercado de servicios médicos extra-hospitalarios nos han posicionado como la empresa de emergencias médicas más grande de la Argentina.</p>
                </div>
                <div class="col-12 col-lg-6">
                    <img src="assets/img/medevac-pic-2.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bullets">
    <div class="container content-bullets">
        <div class="row">
            <div class="col-12 col-lg-6">
                <ul>
                    <li>Diseñado y configurado desde fábrica para su utilización médica, con cabina presurizada y equipamiento LifePort©, marca homologada para el traslado sanitario aéreo.</li>
                    <li>Capacidad pasajeros: 7 pasajeros + auxiliar de abordo.</li>
                    <li>Cabina presurizada, velocidad crucero de 650 km/hora, techo operativo de 43.000 pies, ofreciendo un mayor alcance en vuelos prolongados.</li>
                    <li>Disponemos del más completo equipamiento médico y la mejor tecnología para la atención de pacientes neonatales, pediátricos y adultos.</li>
                </ul>
            </div>
            <div class="col-12 col-lg-6">
                <ul>
                    <li>Mayor velocidad con altos techos operativos: otorga mayor estabilidad al paciente durante el vuelo.</li>
                    <li>Estratégicamente ubicado en el Aeroparque Metropolitano Jorge Newbery (Aeroparque).</li>
                    <li>Estratégicamente ubicado en el Aeroparque Metropolitano Jorge Newbery (Aeroparque).</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="header3" style="background-image:url('assets/img/medevac-3.jpg')">
    <div class="container containerHeader">
        <div class="row h-100">
            <div class="col-12 col-lg-6 my-auto"></div>
            <div class="col-12 col-lg-6 my-auto"></div> 
        </div>
    </div>
</section>
<section class="box2col box2colImg">
    <div class="container">
        <div class="box">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>Rescates Aeromédicos</h2>
                    <p>El helicóptero Jet Ranger III ofrece una variedad de prestaciones para rescates aeromédicos. Entre sus ventajas se destacan la posibilidad de acceso a zonas restringidas, la rapidez, versatilidad y capacidad de maniobra.</p>
                </div>
                <div class="col-12 col-lg-6">
                    <img src="assets/img/medevac-pic-3.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bullets">
    <div class="container content-bullets">
        <div class="row">
            <div class="col-12 col-lg-6">
                <ul>
                    <li>Velocidad de 200 Km/h y autonomía de 500 Km.</li>
                    <li>Puertas de apertura inversa para una mejor asistencia al paciente y sistemas de posicionamiento de última generación.</li>                    
                </ul>
            </div>
            <div class="col-12 col-lg-6">
                <ul>
                    <li>Camilla especialmente diseñada de fábrica para realizar traslados médicos aéreos.</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="servicios">
    <div class="container content-servicios">
        <h2>Nuestra estructura </h2>
        <p>Ofrecemos servicios a la medida de las necesidades de nuestros clientes:</p>
        <div class="noCarrusel">
    
            <div class="row">
                <div *ngFor="let slide2 of slides2" class="slide col-12 col-lg-6" >
                    <div class="contentItem">
                        <img src="{{ slide2.img }}" alt="" width="100%">
                        <div class="contentTxt">
                            <h2>{{ slide2.titulo }}</h2>
                            <p>{{ slide2.bajada }}</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>
