/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../blog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../side-categorias/side-categorias.component.ngfactory";
import * as i4 from "../../data-wp.service";
import * as i5 from "@angular/common/http";
import * as i6 from "../side-categorias/side-categorias.component";
import * as i7 from "@angular/router";
import * as i8 from "./list/list.component.ngfactory";
import * as i9 from "./list/list.component";
import * as i10 from "./categoria.component";
var styles_CategoriaComponent = [i0.styles];
var RenderType_CategoriaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoriaComponent, data: {} });
export { RenderType_CategoriaComponent as RenderType_CategoriaComponent };
function View_CategoriaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "section", [["class", "headerBlog"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "container h-100 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "row h-75"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "col my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "contentTxt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (("url(" + ((_v.context.$implicit.acf == null) ? null : _v.context.$implicit.acf.imagen_fondo)) + ")")); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.acf.titulo; _ck(_v, 9, 0, currVal_1); var currVal_2 = _v.context.$implicit.acf.subtitulo; _ck(_v, 11, 0, currVal_2); }); }
function View_CategoriaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-12 order-last order-lg-0 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-side-categorias", [], null, null, null, i3.View_SideCategoriasComponent_0, i3.RenderType_SideCategoriasComponent)), i1.ɵprd(512, null, i4.DataWpServiceCategorias, i4.DataWpServiceCategorias, [i5.HttpClient]), i1.ɵdid(4, 114688, null, 0, i6.SideCategoriasComponent, [i4.DataWpServiceCategorias, i7.Router], { slugCategoria: [0, "slugCategoria"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-12  col-lg-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-list", [], null, null, null, i8.View_ListComponent_0, i8.RenderType_ListComponent)), i1.ɵprd(512, null, i4.DataWpServiceListadoPostFiltroCategoria, i4.DataWpServiceListadoPostFiltroCategoria, [i5.HttpClient]), i1.ɵdid(8, 114688, null, 0, i9.ListComponent, [i4.DataWpServiceListadoPostFiltroCategoria], { slugCategoria: [0, "slugCategoria"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.post.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.post.name; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_CategoriaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "section", [["id", "anchor1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriaComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "section", [["class", "blog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriaComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.postsTitulos; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.post; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_CategoriaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-categoria", [], null, null, null, View_CategoriaComponent_0, RenderType_CategoriaComponent)), i1.ɵprd(512, null, i4.DataWpServiceTituloBlog, i4.DataWpServiceTituloBlog, [i5.HttpClient]), i1.ɵprd(512, null, i4.DataWpServiceBlog, i4.DataWpServiceBlog, [i5.HttpClient]), i1.ɵprd(512, null, i4.DataWpServiceCategorias, i4.DataWpServiceCategorias, [i5.HttpClient]), i1.ɵdid(4, 114688, null, 0, i10.CategoriaComponent, [i4.DataWpServiceTituloBlog, i4.DataWpServiceBlog, i4.DataWpServiceCategorias, i7.ActivatedRoute, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var CategoriaComponentNgFactory = i1.ɵccf("app-categoria", i10.CategoriaComponent, View_CategoriaComponent_Host_0, {}, {}, []);
export { CategoriaComponentNgFactory as CategoriaComponentNgFactory };
