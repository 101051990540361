/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../empresa-gestion-online.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./constancia-de-asociacion.component";
import * as i3 from "@angular/common/http";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "ngx-ui-loader";
var styles_ConstanciaDeAsociacionComponent = [i0.styles];
var RenderType_ConstanciaDeAsociacionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConstanciaDeAsociacionComponent, data: {} });
export { RenderType_ConstanciaDeAsociacionComponent as RenderType_ConstanciaDeAsociacionComponent };
function View_ConstanciaDeAsociacionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-body contentModal"], ["style", "height: 200px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["style", "color: #005ab8; width: 95%; margin-top: 50px; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalMessage; _ck(_v, 5, 0, currVal_0); }); }
export function View_ConstanciaDeAsociacionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { modalRespuesta: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "contentModalGral px-3 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "row titulos "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col-12 px-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/img/icon-socios-04.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Constancia de asociaci\u00F3n"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["style", "text-align: center; color: #005ab8; font-weight: 700; margin-bottom: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Solicite su constancia de asociaci\u00F3n."])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-12 col-lg-4 contentButton asociacion"], ["style", "margin: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["SOLICITAR"])), (_l()(), i1.ɵand(0, [[1, 2], ["modalRespuesta", 2]], null, 0, null, View_ConstanciaDeAsociacionComponent_1))], null, null); }
export function View_ConstanciaDeAsociacionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-constancia-de-asociacion", [], null, null, null, View_ConstanciaDeAsociacionComponent_0, RenderType_ConstanciaDeAsociacionComponent)), i1.ɵdid(1, 114688, null, 0, i2.ConstanciaDeAsociacionComponent, [i3.HttpClient, i4.NgbModal, i5.NgxUiLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConstanciaDeAsociacionComponentNgFactory = i1.ɵccf("app-constancia-de-asociacion", i2.ConstanciaDeAsociacionComponent, View_ConstanciaDeAsociacionComponent_Host_0, {}, { closeModal: "closeModal" }, []);
export { ConstanciaDeAsociacionComponentNgFactory as ConstanciaDeAsociacionComponentNgFactory };
