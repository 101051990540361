import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DopplerService } from './service/doppler.service';
import { AnalyticsService } from './service/analytics.service';

@Component({
  selector: 'app-landing-nea',
  templateUrl: './landing-nea.component.html',
  styleUrls: ['./landing-nea.component.css']
})
export class LandingNeaCompoment implements OnInit {
  public contactForm: FormGroup;
  public isMobile: boolean;
  public isSubmitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dopplerService: DopplerService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.initForm();
    this.checkDeviceType();
    this.initializeAnalytics();
  }

  private initForm() {
    this.contactForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      localidad: ['', Validators.required]
    });
  }

  private checkDeviceType() {
    this.isMobile = window.innerWidth < 481;
  }

  private initializeAnalytics() {
    this.analyticsService.initializeAnalytics();
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.dopplerService.submitForm(this.contactForm.value).subscribe(
        response => {
          this.isSubmitted = true;
          this.analyticsService.trackFormSubmission('success');
        },
        error => {
          console.error('Error submitting form:', error);
          this.analyticsService.trackFormSubmission('error');
        }
      );
    }
  }
}