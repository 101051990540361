import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../data-wp.service";
import * as i4 from "ngx-ui-loader";
export class AuthService {
    constructor(http, router, serviceGo, spinner) {
        this.http = http;
        this.router = router;
        this.serviceGo = serviceGo;
        this.spinner = spinner;
        this.authStatusListener = new BehaviorSubject(false);
    }
    getIsAuth() {
        console.log(this.isAuthenticated);
        return this.isAuthenticated;
    }
    getAuthStatusListener() {
        return this.authStatusListener;
    }
    getDataLogin() {
        return JSON.parse(sessionStorage.getItem("data"));
    }
    save(res) {
        this.datosSocio = res.data;
        this.saveAuthData(this.datosSocio);
        this.isAuthenticated = true;
        this.authStatusListener.next(true);
    }
    loginEmpresa(socio_dni, socio_email) {
        const authData = {
            socio_dni: socio_dni,
            socio_email: socio_email,
        };
        this.serviceGo
            .login(authData.socio_dni, authData.socio_email)
            .subscribe((res) => {
            if (res.success) {
                this.datosSocio = res.data;
                this.saveAuthData(this.datosSocio);
                this.isAuthenticated = true;
                this.authStatusListener.next(true);
                this.router.navigate(["/gestion-online/empresas"]);
            }
            else {
                alert("Error:" + res.message);
            }
        });
    }
    saveAuthData(data) {
        sessionStorage.setItem("data", JSON.stringify(data));
        sessionStorage.setItem("isLogged", "true");
    }
    logout() {
        sessionStorage.clear();
        this.router.navigate(["atencion-al-socio/gestion-online"]);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.DataWpServiceGestionOnline), i0.ɵɵinject(i4.NgxUiLoaderService)); }, token: AuthService, providedIn: "root" });
