<section class="headerComponent" *ngFor="let postsPadre of postsPadre" >


    <ngb-carousel *ngIf="postsPadre.acf.slider" [showNavigationArrows]="postsPadre.acf.slider.length > 1"  [showNavigationIndicators]="postsPadre.acf.slider.length > 1" interval="7500" [keyboard]="true" [pauseOnHover]="true" [wrap]="true" [activeId]="'secondSlide'" >
        <ng-template ngbSlide *ngFor="let slider of postsPadre.acf.slider">
            
                <div class="img" [ngStyle]="{'background-image': 'url(' + slider.imagen + ')'}"></div>
                <div class="container h-100">
                    <div class="row m-0 h-100 ">
                        <div class="col-lg-6 col-12 my-auto p-0" >
                            <h2 [innerHTML]="slider.titulo" class="slide-left"></h2>
                            <p [innerHTML]="slider.bajada" class="slide-left"></p>
                        </div>            
                    </div>
                </div>    
                
            </ng-template>
        </ngb-carousel>
        
  </section>



<section class="servicios">
    <div class="container">
        <div class="row d-flex justify-content-center">

            <div class="col-12 col-lg-3 contentItem wow fadeInDown" *ngFor="let post of posts; let i=index" >
                <div class="item-servicio" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                  <div class="hoverContent  h-100" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                    <div class="hoverContentTxt w-100 my-auto ">
                      <h2 [innerHTML]="post.acf.portada.titulo"></h2>
                      <p [innerHTML]="post.acf.portada.descripcion"></p>
                      <!-- <a routerLink="/servicios-adicionales/{{post.slug}}"><div class="boton">Más información</div></a> -->
                      <a *ngIf="!post.acf.portada.redirect.utilizar_redireccionamiento" routerLink="/servicios-adicionales/{{post.slug}}">
                        <div class="boton">{{post.acf.portada.label_boton}}</div>
                      </a>
                      <a *ngIf="post.acf.portada.redirect.utilizar_redireccionamiento" href="{{post.acf.portada.redirect.url}}" target="_blank">
                        <div class="boton">{{post.acf.portada.label_boton}}</div>
                      </a>
                    </div>
    
                  </div>
                  <h3>{{post.acf.portada.titulo}}</h3>
                </div>
            </div>

        </div>
    </div>
</section>
