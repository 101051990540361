<section class="destacadas" *ngFor="let post of posts$ | async" style="background-image: url('assets/img/bk-destacados-home.jpg');">
    <h2>{{post.acf.titulo}}</h2>
    <p>{{post.acf.subtitulo}}</p>
    <!-- itemPromo  -->
    


    <div class="row m-0 seven-cols">
        
        <a *ngFor="let botton of post.acf.boton" routerLink="/{{botton.link}}" class="col-6 col-lg">
            <div *ngIf="botton.promo == true" class="itemPlan itemPromo">
                <div class="row h-100 itemPlanBoxInterno">
                    <div class="col-12 my-auto">
                        <p><img src="{{botton.icono}}" alt=""></p>
                        <p><span [innerHTML]='botton.nombre'></span></p>
                    </div>
                </div>
            </div>
            <div *ngIf="botton.promo == false" class="itemPlan">
                <div class="row h-100 itemPlanBoxInterno">
                    <div class="col-12 my-auto">
                        <p><img src="{{botton.icono}}" alt=""></p>
                        <p><span [innerHTML]='botton.nombre'></span></p>
                    </div>
                </div>
            </div>
        </a>
        
    </div>

    
</section>


