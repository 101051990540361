<section id="anchor1"></section>

<section *ngFor="let postsT of postsTitulos"   class="headerBlog" [ngStyle]="{'background-image': 'url(' + postsT.acf.imagen_fondo + ')'}">
    <div class="container h-100 ">
        <div class="row h-75">
            <div class="col my-auto">
                <div class="contentTxt">
                    <h2>{{postsT.acf.titulo}}</h2>
                    <p>{{postsT.acf.subtitulo}}</p>
                </div>
            </div>
        </div>
    </div>
</section>  

<section class="blog">
    <div class="container">
        <div class="row">
            <div class="col-12 order-last order-lg-0 col-lg-3">
                <app-side-categorias></app-side-categorias>
            </div>
            
            <div class="col-12  col-lg-9">
                <div class="row">

                    <ng-template ngFor let-post  let-i="index" [ngForOf]="posts | paginate: config">

                        <ng-template [ngIf]="i == 0" >
                            <div class="col-12 itemNota primera">
                                <div class="contentNota">
                                    <div class="row">
                                        <div class="col-12 col-lg-6" >
                                            <img [src]="post.better_featured_image?.source_url" [alt]="post.better_featured_image?.alt_text" [ngStyle]="{'object-fit': 'cover', 'object-position':'center', 'height':'100%'}">
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <span class="fecha"><i class="fa fa-calendar" aria-hidden="true"></i> {{post.date_gmt | date:'fullDate' }}</span>   
                                            <a class="titleLink" routerLink="/{{post.slug}}"><h2 [innerHTML]='post.title.rendered'></h2></a>
                                            <div class="contentBajada" [innerHTML]="post.excerpt.rendered.substr(0,155)+'...'"></div>
                                            <div class="w-100"><p class="contentLink"><a routerLink="/{{post.slug}}">MÁS INFO</a></p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="i > 0">

                            <div class="col-12 col-lg-6 itemNota {{i}}">
                                <div class="contentNota">
                                    <div class="row">
                                        <div class="col-12">
                                            <img [src]="post.better_featured_image?.source_url" [alt]="post.better_featured_image?.alt_text">
                                        </div>
                                        <div class="col-12">
                                            <span class="fecha"><i class="fa fa-calendar" aria-hidden="true"></i> {{post.date_gmt | date:'fullDate' }}</span>   
                                            <a class="titleLink" routerLink="/{{post.slug}}"><h2 [innerHTML]='post.title.rendered'></h2></a>
                                            <p [innerHTML]="post.excerpt.rendered.substr(0,120)+'...'"></p>
                                        </div>
                                            <p class="contentLink"><a routerLink="/{{post.slug}}">MÁS INFO</a></p>
                                    </div>
                                </div>
                            </div>

                        </ng-template>


                    </ng-template>

                    <div class="col-12 contentPageNavi">

                        <pagination-controls 
                        [maxSize]="maxSize"
                        [directionLinks]="directionLinks"
                        [autoHide]="autoHide"
                        [responsive]="responsive"
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        [ngx-scroll-to]="'#anchor1'"                        
                        (pageChange)="onPageChange($event)"></pagination-controls>

                    </div>


                    


                </div>

            </div>


        </div>
    </div>
</section>