/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../blog.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "ngx-pagination";
import * as i5 from "../../../../../node_modules/ngx-pagination/dist/ngx-pagination.ngfactory";
import * as i6 from "@nicky-lenaers/ngx-scroll-to";
import * as i7 from "../../../data-wp.service";
import * as i8 from "@angular/common/http";
import * as i9 from "./list.component";
var styles_ListComponent = [i0.styles];
var RenderType_ListComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
function View_ListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "col-12 itemNota primera"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "contentNota"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "contentImg w-100 h-100"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "background-image": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["class", "col-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "span", [["class", "fecha"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", ""])), i1.ɵppd(12, 2), (_l()(), i1.ɵeld(13, 0, null, null, 2, "a", [["class", "titleLink"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "contentBajada"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "p", [["class", "contentLink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["M\u00C1S INFO"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 7, 0, (("url(" + ((_v.parent.context.$implicit.better_featured_image == null) ? null : _v.parent.context.$implicit.better_featured_image.source_url)) + ")")); _ck(_v, 6, 0, currVal_0); var currVal_4 = i1.ɵinlineInterpolate(1, "/", _v.parent.context.$implicit.slug, ""); _ck(_v, 14, 0, currVal_4); var currVal_9 = i1.ɵinlineInterpolate(1, "/", _v.parent.context.$implicit.slug, ""); _ck(_v, 20, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.date_gmt, "fullDate")); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 14).target; var currVal_3 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_5 = _v.parent.context.$implicit.title.rendered; _ck(_v, 15, 0, currVal_5); var currVal_6 = (_v.parent.context.$implicit.excerpt.rendered.substr(0, 155) + "..."); _ck(_v, 16, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 20).target; var currVal_8 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_7, currVal_8); }); }
function View_ListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "contentNota"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["class", "fecha"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", ""])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [["class", "titleLink"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "p", [["class", "contentLink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["M\u00C1S INFO"]))], function (_ck, _v) { var currVal_5 = i1.ɵinlineInterpolate(1, "/", _v.parent.context.$implicit.slug, ""); _ck(_v, 11, 0, currVal_5); var currVal_10 = i1.ɵinlineInterpolate(1, "/", _v.parent.context.$implicit.slug, ""); _ck(_v, 16, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "col-12 col-lg-6 itemNota ", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_v.parent.context.$implicit.better_featured_image == null) ? null : _v.parent.context.$implicit.better_featured_image.source_url); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.date_gmt, "fullDate")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 11).target; var currVal_4 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_6 = _v.parent.context.$implicit.title.rendered; _ck(_v, 12, 0, currVal_6); var currVal_7 = (_v.parent.context.$implicit.excerpt.rendered.substr(0, 120) + "..."); _ck(_v, 13, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 16).target; var currVal_9 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_8, currVal_9); }); }
function View_ListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_3)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.index == 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.index > 0); _ck(_v, 3, 0, currVal_1); }, null); }
function View_ListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ListComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i4.PaginatePipe, [i4.PaginationService]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-12 contentPageNavi"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "pagination-controls", [], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.onPageChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_PaginationControlsComponent_0, i5.RenderType_PaginationControlsComponent)), i1.ɵdid(7, 4210688, null, 0, i6.ScrollToDirective, [i1.ElementRef, i6.ScrollToService, i1.Renderer2], { ngxScrollTo: [0, "ngxScrollTo"] }, null), i1.ɵdid(8, 49152, null, 0, i4.PaginationControlsComponent, [], { maxSize: [0, "maxSize"], directionLinks: [1, "directionLinks"], autoHide: [2, "autoHide"], responsive: [3, "responsive"], previousLabel: [4, "previousLabel"], nextLabel: [5, "nextLabel"], screenReaderPaginationLabel: [6, "screenReaderPaginationLabel"], screenReaderPageLabel: [7, "screenReaderPageLabel"], screenReaderCurrentLabel: [8, "screenReaderCurrentLabel"] }, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.posts, _co.config)); _ck(_v, 3, 0, currVal_0); var currVal_1 = "#anchor1"; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.maxSize; var currVal_3 = _co.directionLinks; var currVal_4 = _co.autoHide; var currVal_5 = _co.responsive; var currVal_6 = _co.labels.previousLabel; var currVal_7 = _co.labels.nextLabel; var currVal_8 = _co.labels.screenReaderPaginationLabel; var currVal_9 = _co.labels.screenReaderPageLabel; var currVal_10 = _co.labels.screenReaderCurrentLabel; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
export function View_ListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i1.ɵprd(512, null, i7.DataWpServiceListadoPostFiltroCategoria, i7.DataWpServiceListadoPostFiltroCategoria, [i8.HttpClient]), i1.ɵdid(2, 114688, null, 0, i9.ListComponent, [i7.DataWpServiceListadoPostFiltroCategoria], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ListComponentNgFactory = i1.ɵccf("app-list", i9.ListComponent, View_ListComponent_Host_0, { slugCategoria: "slugCategoria" }, {}, []);
export { ListComponentNgFactory as ListComponentNgFactory };
