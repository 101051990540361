import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  API_BASE_URL_V,
  DataWpServicePlanesChild,
  DataWpServicePrecios,
} from "../../data-wp.service";
import { PostI } from "../../post.interface";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { Title, Meta } from "@angular/platform-browser";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { map } from "jquery";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-plan-single",
  templateUrl: "./plan-single.component.html",
  styleUrls: ["./plan-single.component.css"],
  providers: [DataWpServicePlanesChild],
  encapsulation: ViewEncapsulation.None,
})
export class PlanSingleComponent implements OnInit {
  product;
  title;
  posts$: Observable<PostI[]>;

  planes;
  post: PostI;
  isBitrix: boolean;
  mobile: boolean;
  environment = environment;

  constructor(
    private modalService: NgbModal,
    config: NgbCarouselConfig,
    private postsService: DataWpServicePlanesChild,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    private http: HttpClient
  ) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    this.planes = [{ nombre: "", precio: "" }];
  }

  openModalCotizar(content) {
    this.modalService.open(content, {
      size: "xl",
      windowClass: "modal-cotizaciones",
    });
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  max(x, y): Number {
    return Math.max(x, y);
  }

  getPost(slug) {
    this.postsService.getPost(slug).subscribe((res) => {
      this.post = res[0];
      console.log(this.post);
      
    });
  }

  ngOnInit() {
    window.innerWidth < 500 ? (this.mobile = true) : (this.mobile = false);
    this.route.params.forEach((params: Params) => {
      let slug = params["slug"];
      this.getPost(slug);
      this.posts$ = this.postsService.getPost(slug);
    });

    this.posts$.subscribe((data) => {
      try {
        /*Levantamos MetaTags*/
        var titleSeo = (this.product = data[0].acf.seo.titulo);
        var descripcionSeo = (this.product = data[0].acf.seo.descripcion);
        var keywordsSeo = (this.product = data[0].acf.seo.keywords);
        data[0].acf["activarbitrix"]
          ? (this.isBitrix = true)
          : (this.isBitrix = false);
  
        /*Seteamos Metatags en header*/
        this.titleService.setTitle("Vittal - " + titleSeo);
        this.metaService.updateTag({ name: "keywords", content: keywordsSeo });
        this.metaService.updateTag({
          name: "description",
          content: descripcionSeo,
        });
        this.metaService.updateTag({ name: "robots", content: "index, follow" });
      } catch (error) {
        console.error("Error in ngOnInit (MetaTag handling):", error);
      }
    },
    (error) => {
      // Added error handling for the Observable subscription
      console.error("Error in posts$ subscription:", error);
    });
  }

  getPrecios() {
    this.http
      .get(
        `${API_BASE_URL_V}/maestro_de_precios?filter[meta_key]=tipo_de_precio&filter[meta_value]=Plan`
      )
      .subscribe((e) => {
        this.planes = map(e, (d) => {
          return { nombre: d["acf"].nombre, precio: d["acf"].price_1p };
        });
      });
  }
}
