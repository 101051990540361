<iframe
  [src]="url | safe"
  frameborder="0"
  class="e2e-inner-html-interpolated"
  id="miIframe"
  scrolling="yes"
></iframe>
<script>
  var iframe = window.parent.document.getElementById("miIframe");
  window.onresize = function () {
    iframe.style.width = window.style.width;
  };
  iframe.onload = function () {
    iframe.style.height =
      iframe.contentWindow.document.body.scrollHeight + "px";
  };
</script>

<script></script>
<div
  id="myModal2"
  class="modal fade"
  role="dialog"
  style="z-index: 99999; position: fixed; top: 230px"
>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header" style="display: contents">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          style="margin-right: 0px"
        >
          &times;
        </button>
        <h4 class="modal-title" id="modalTitulo"></h4>
      </div>
      <div class="modal-body">
        <p>
          Debido a la zona ingresada no se podr&aacute; realizar la compra
          online.<br />A la brevedad un representante se comunicar&aacute; con
          Ud.<br />Muchas gracias
        </p>
      </div>
    </div>
  </div>
</div>
