<section class="headerComponent" id="headerFaqs" *ngFor="let post of posts" >


    <ngb-carousel *ngIf="post.acf.header.slider" [showNavigationArrows]="post.acf.header.slider.length > 1" [showNavigationIndicators]="post.acf.header.slider.length > 1" interval="7500" [keyboard]="true" [pauseOnHover]="true" [wrap]="true" [activeId]="'secondSlide'" >
        <ng-template ngbSlide *ngFor="let slider of post.acf.header.slider">
            
            <div *ngIf="!isMobile" class="img" [ngStyle]="{'background-image': 'url(' + slider.imagen + ')'}"></div>
            <div *ngIf="isMobile" class="img" [ngStyle]="{'background-image': 'url(' + slider.imagenmobile + ')'}"></div>
                <div class="container h-100">
                    <div class="row m-0 h-100 ">
                        <div class="col-lg-6 col-12 my-auto p-0" >
                            <h2 [innerHTML]="slider.titulo" class="slide-left"></h2>
                            <p [innerHTML]="slider.bajada" class="slide-left"></p>
                        </div>            
                    </div>
                </div>    
                
            </ng-template>
        </ngb-carousel>
        
 </section>


<section class="preguntas" *ngFor="let post of posts">
    <div class="container">


            <div class="accordion" id="accordionExample">
                <div  class="card  z-depth-0 bordered" *ngFor="let pregunta of post.acf.preguntas; let i=index">
                    <div class="card-header" [attr.id]="'heading'+i" >
                        <h5 class="mb-0">
                            <button class="btn btn-link"  type="button"  data-toggle="collapse" [attr.data-target]="'#item'+i" aria-expanded="false" [attr.aria-controls]="'item'+i">
                                {{pregunta.pregunta}}
                            </button>
                        </h5>
                    </div>              

                    <div [attr.id]="'item'+i" class="collapse" [attr.aria-labelledby]="'heading'+i" >
                        <div class="card-body" [innerHTML]="transform(pregunta.respuesta)">
                        </div>
                    </div>
                </div>
            </div>
            

    </div>
</section>
