
<section class="headerComponent" *ngFor="let post of posts" >

    <ngb-carousel *ngIf="post.acf.header.slider" [showNavigationArrows]="post.acf.header.slider.length > 1" [showNavigationIndicators]="post.acf.header.slider.length > 1" interval="7500" [keyboard]="true" [pauseOnHover]="true" [wrap]="true" [activeId]="'secondSlide'" >
        <ng-template ngbSlide *ngFor="let slider of post.acf.header.slider; let i = index">
            
                <div class="img" [ngStyle]="{'background-image': 'url(' + slider.imagen + ')'}"></div>
                <div class="container h-100">
                    <div class="row m-0 h-100 ">
                        <div class="col-lg-6 col-12 my-auto p-0" >
                            <h2 [innerHTML]="slider.titulo" class="slide-left"></h2>
                            <p [innerHTML]="slider.bajada" class="slide-left"></p>
                        </div>            
                    </div>
                </div>    
                
            </ng-template>
        </ngb-carousel>
        
 </section>



<section class="selector">
    <div class="container">
        <!-- Nav pills -->
    <ul class="nav nav-pills" role="tablist">
        <li class="nav-item">
            <ng-container *ngIf="currentScreenSize == 'Small' || currentScreenSize == 'XSmall'; else elseEspecialidad" >              
              <a #icoEsp class="nav-link active" id="tituloEspecialidad" data-toggle="pill" href="#esp" (click)="isEsp()">
                <div class="iconBuscadorEsp"><span>ESPECIALIDAD</span></div>                  
                </a>
            </ng-container>
            <ng-template #elseEspecialidad>
                <a #icoEsp class="nav-link" id="tituloEspecialidad" data-toggle="pill" href="#esp" (click)="isEsp()">
                    <div class="iconBuscadorEsp"><span>BUSCAR POR ESPECIALIDAD</span></div>
                </a>
            </ng-template>       
        </li>
        <li class="nav-item">
          <ng-container *ngIf="currentScreenSize == 'Small' || currentScreenSize == 'XSmall'; else elseGeolocalizacion">
              <a #icoGeo class="nav-link " id="tituloGeolocalizacion" data-toggle="pill" href="#geo" (click)="isGeo()">                  
                    <div class="iconBuscadorGeo"><span>GEOLOCALIZACIÓN</span></div>                 
                </a>                  
            </ng-container>
            <ng-template #elseGeolocalizacion>
                <a #icoGeo class="nav-link" id="tituloGeolocalizacion" data-toggle="pill" href="#geo" (click)="isGeo()">
                    <div class="iconBuscadorGeo"><span>BUSCAR POR GEOLOCALIZACIÓN</span></div>
                </a>
            </ng-template>
        </li>
      </ul>
      <!-- Tab panes -->
     
      <div class="tab-content">
        <div id="esp" class="container tab-pane active">
          <mat-grid-list [cols]="breakpoint" rowHeight="55px" gutterSize="5px" (window:resize)="onResize($event)">
              <form [formGroup]="busquedaEsp">            
                  <mat-grid-tile>
                          <mat-form-field class="select-cartilla" appearance="standard">
                              <mat-label>Provincia</mat-label>
                              <mat-select name="provincia" id="provincia" formControlName="provincia" (selectionChange)="onChangeProvincia($event.value)">
                                <mat-option value="">Todas las provincias</mat-option>  
                                <mat-option *ngFor="let provincia of provincias" value="{{provincia}}" >{{provincia}}</mat-option>                                
                              </mat-select>                
                          </mat-form-field>
                  </mat-grid-tile>
  
                  <mat-grid-tile>
                      <mat-form-field class="select-cartilla" appearance="standard">
                          <mat-label class="labelLocalidad">Localidad</mat-label>                        
                              <mat-select name="localidad" id="localidad" formControlName="localidad">
                                <mat-option value="">Todas las localidades</mat-option>                            
                                <mat-option *ngFor="let localidad of localidades" value="{{localidad}}" >{{localidad}}</mat-option>
                              </mat-select>
                      </mat-form-field>
                  </mat-grid-tile>
                  
                      <!--(change)="onChangeRubro($event.target.value)"-->
                  <mat-grid-tile>
                      <mat-form-field class="select-cartilla" appearance="standard">  
                          <mat-label>Rubro</mat-label> 
                              <mat-select name="rubro" id="rubro" formControlName="rubro" (selectionChange)="onChangeRubro($event.value)">  
                                <mat-option value="">Todos los rubros</mat-option>
                                <mat-option *ngFor="let rubro of rubros" value="{{rubro.term_id}}" >{{rubro.name}}</mat-option>
                              </mat-select> 
                          </mat-form-field>                     
                      </mat-grid-tile>
                      <mat-grid-tile>
                          <mat-form-field class="select-cartilla" appearance="standard">
                          <mat-label>Especialidad</mat-label>                            
                              <mat-select name="especialidad" id="especialidad" formControlName="especialidad" (selectionChange)="onChangeEspecialidad($event.value)" >
                                <mat-option value="">Todas las especialidades</mat-option>
                                <mat-option *ngFor="let especialidad of especialidades" value="{{especialidad.term_id}}" >{{especialidad.name}}</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </mat-grid-tile>
                      <mat-grid-tile>
                          <mat-form-field class="select-cartilla" appearance="standard"> 
                              <mat-label>Práctica</mat-label>                      
                              <mat-select name="practica" id="practica" formControlName="practica">
                                <mat-option value="">Todas las prácticas</mat-option>                           
                                <mat-option *ngFor="let practica of practicas" value="{{practica.term_id}}" >{{practica.name}}</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </mat-grid-tile>
                  <mat-grid-tile>
                      <mat-form-field class="select-cartilla" appearance="standard"> 
                          <mat-label>Nombre de la Entidad o Profesional</mat-label>
                              <input matInput type="text" name="entidad" id="entidad" formControlName="entidad">
                          </mat-form-field>
                  </mat-grid-tile> 
                  </form>
              </mat-grid-list>
        </div>
        <div id="geo" class="container tab-pane fade">
            <mat-grid-list [cols]="breakpoint" rowHeight="55px" gutterSize="5px" (window:resize)="onResize($event)">
                <form [formGroup]="busquedaGeo">
                    <mat-grid-tile [colspan]="breakpoint">
                        <mat-form-field class="input-direccion" appearance="standard">
                        <mat-label >Dirección</mat-label>
                        <ng-container *ngIf="isGeoEnable; else elseGeocheck">
                            <input  matInput type="text" name="direccion" id="direccion" formControlName="direccion" value='Tu Ubicación' readonly>
                        </ng-container>
                        <ng-template #elseGeocheck>
                            <input  matInput  type="text" name="direccion" id="direccion" formControlName="direccion" value='Necesitas Activar la Geolocalización' readonly >
                        </ng-template>                        
                            
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field class="select-cartilla" appearance="standard">  
                            <mat-label>Rubro</mat-label> 
                                <mat-select name="rubro" id="rubro" formControlName="rubro" (selectionChange)="onChangeRubro($event.value)">                                    
                                    <mat-option value="">Todos los rubros</mat-option>
                                    <mat-option *ngFor="let rubro of rubros" value="{{rubro.term_id}}" >{{rubro.name}}</mat-option>
                                </mat-select> 
                        </mat-form-field>                     
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field class="select-cartilla" appearance="standard">
                        <mat-label>Especialidad</mat-label>                            
                            <mat-select name="especialidad" id="especialidad" formControlName="especialidad" (selectionChange)="onChangeEspecialidad($event.value)" >
                                <mat-option value="">Todas las especialidades</mat-option>
                                <mat-option *ngFor="let especialidad of especialidades" value="{{especialidad.term_id}}" >{{especialidad.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field class="select-cartilla" appearance="standard"> 
                            <mat-label>Práctica</mat-label>                      
                            <mat-select name="practica" id="practica" formControlName="practica">
                                <mat-option value="">Todas las prácticas</mat-option>                           
                                <mat-option *ngFor="let practica of practicas" value="{{practica.term_id}}" >{{practica.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field class="select-cartilla" appearance="standard"> 
                            <mat-label>Nombre de la Entidad o Profesional</mat-label>
                            <input matInput type="text" name="entidad" id="entidadGeo" formControlName="entidad">
                        </mat-form-field>
                    </mat-grid-tile>                
                </form>   
            </mat-grid-list>   
        </div>
        <div class="botonesSelector">
            <div class="botonVerMapa" >
                <mat-button-toggle-group  [(ngModel)]="valueSelect" name="botonesSeleccion" (change)=onChange($event)>
                    <mat-button-toggle value="botonVerLista" id="botonListaweb">VER LISTA</mat-button-toggle>
                    <mat-button-toggle value="botonVerMapa" id="botonMapaweb">VER MAPA</mat-button-toggle>                            
                  </mat-button-toggle-group>
            </div>
            <button class="btnsearch" (click)="buscarCartilla()">BUSCAR</button>
        </div>      
      </div>    
    </div> 
</section>
<div class="anchor" id="anchorResultados"></div>
<section #sectionSinresultados class="sinresultados">
    <div *ngIf="!hayResultado" class="sinResultados"> <span>Su búsqueda no ha dado resultados</span></div>
</section>
<section #sectionResultados class="resultados" id="resultados">
    
    <div class="container">
        <ng-container *ngIf="currentScreenSize == 'XSmall'; else elseExpansion">
            <div class="row" id="itemsResults">                
                <mat-accordion *ngIf="hayResultado" class="col-12">           
                    <div class="col-12" *ngFor="let resultado of resultados | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage}" >
                        <div class="itemResult">                   
                            <mat-expansion-panel #matExpansionPanel>
                                <mat-expansion-panel-header collapsedHeight="70px">                                                           
                                    <div class="nombreInst  my-auto">
                                        {{resultado.title}}
                                    </div>
                                    <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small' " class="direc  my-auto">{{resultado.direccion}}</div>   
                                    <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small' " class="telef  my-auto">{{resultado.telefono[0]?.numero}}</div>
                                    
                                    <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small'" class="iconMapCol my-auto ">
                                        <mat-divider class="preDiv my-auto"  [vertical]="true" style="height: 30px; width: 2px; margin: 0;"></mat-divider>
                                        <div class="icoMap">
                                            <a><img src="assets/img/icon-lista-mapa.png" (click)="verSingleResults(resultado)"></a>
                                        </div>
                                        <mat-divider class="postDiv my-auto" [vertical]="true" style="height: 30px; width: 2px; margin: 0;"></mat-divider>
                                    </div>
                                    
                                    <div class="labelArrow  my-auto">VER MÁS</div>
                                </mat-expansion-panel-header>
                                <div class="infoResultado">
                                    <ul>
                                        <li *ngIf="resultado?.direccion" class="dirTitulo">
                                            <ul>
                                                <li class="dir">
                                                <span>{{resultado?.direccion}}</span>
                                                </li>
                                            </ul>
                                         </li>
                                        <li class="telTitulo">
                                            <ul>
                                                <span>TELEFONO:</span>
                                                <li class='tel'>
                                                    <a href= "tel:{{resultado.telefono[0].numero}}">{{resultado.telefono[0].numero}}</a>
                                                </li>
                                            </ul>  
                                        </li>
                                        <li *ngIf="resultado.telefono[1]" class="telTitulo">                                       
                                            <ul>
                                                <span>OTROS TELEFONOS:</span><br>
                                                <li class="otrosTel key{{indice}}" *ngFor="let r_tel of resultado.telefono.slice(1);let indice = index;">
                                                    <a href= "tel:{{r_tel.numero}}">{{r_tel.numero}}</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li *ngIf="resultado.whatsapp[0]" class="whaTitulo">
                                            <ul>
                                                <span>WHATSAPP:</span>
                                                <li class="wha" *ngFor="let r_wha of resultado.whatsapp">
                                                    <a href= "https://wa.me/{{r_wha.numero}}" target="_blank">{{r_wha.numero}}</a>
                                                </li>                                            
                                            </ul>
                                        <li *ngIf="resultado.email" class="emailTitulo">
                                            <ul>
                                                <span>EMAIL:</span>
                                                <li class="email">
                                                    <a href= "mailto:{{resultado.email}}">{{resultado.email}}</a>
                                                </li>                                            
                                            </ul>
                                        </li>
                                        <li *ngIf="resultado.atencion[0] "class="horTitulo">
                                            <ul>
                                                <span>HORARIO DE ATENCION:</span>
                                                <li class="hor" *ngFor="let r_hor of resultado.atencion">
                                                    <span>{{r_hor.dias}} de {{r_hor.horario}}</span>
                                                </li>                                          
                                            </ul>
                                        </li>
                                        <li *ngIf="resultado.web" class="webTitulo">
                                            <ul>
                                                <span>WEB:</span>
                                                <li class="web">
                                                    <a href= "{{resultado?.web}}" target="_blank">{{resultado?.web}}</a>
                                                </li>                                            
                                            </ul>
                                        </li> 
                                        <li *ngIf="resultado.web_turnos" class="webTurnoTitulo">
                                            <ul>
                                                <span>TURNOS:</span>
                                                <li class="webturno">
                                                    <a href= "{{resultado?.web_turnos}}" target="_blank">{{resultado?.web_turnos}}</a>
                                                </li>                                            
                                            </ul>
                                        </li> 
                                        <li *ngIf="((resultado?.rubros.length >= 1)&&(resultado?.rubros[0].name != ''))" class="rubroTitulo">
                                            <ul>                                        
                                                <span>RUBROS:</span>
                                                <mat-chip-list class="rubro">
                                                    <mat-chip *ngFor="let r_rubro of resultado.rubros">{{r_rubro.name}}</mat-chip>
                                                </mat-chip-list>
                                            </ul>
                                        </li> 
                                        <li *ngIf="verEspecialidad(resultado.rubros)" class="especialidadesTitulo">
                                            <ul>                                         
                                                <span>ESPECIALIDADES:</span>
                                                <mat-chip-list class="especialidades">
                                                    <div  *ngFor="let r_especial of resultado.rubros">
                                                    <mat-chip class="especial" *ngFor="let r_especialidades of r_especial.especialidades">                                                
                                                            {{r_especialidades.name}}</mat-chip>
                                                        </div>
                                                </mat-chip-list>
                                            </ul>
                                        </li>
                                        <li *ngIf="verPractica(resultado.rubros)" class="practicasTitulo">
                                            <ul>                                         
                                                <span>PRÁCTICAS:</span>
                                                <mat-chip-list class="practicas">                                                
                                                    <div  *ngFor="let r_especial of resultado.rubros">
                                                        <div  *ngFor="let r_especialidades of r_especial.especialidades">
                                                        <mat-chip class="especial" *ngFor="let r_practicas of r_especialidades.practicas">                                                
                                                                {{r_practicas.name}}</mat-chip>
                                                            </div>
                                                        </div>                                                 
                                                </mat-chip-list>
                                            </ul>
                                        </li>
                                    </ul>                            
                                </div>                               
                            </mat-expansion-panel>                
                        </div>
                    </div>
                </mat-accordion>
                    <div *ngIf="hayResultado" class="pagination">
                        <pagination-controls 
                        responsive="true" 
                        previousLabel="Anterior" 
                        nextLabel="Siguiente"
                        (pageChange)="currentPage = $event">
                        </pagination-controls> 
                    </div>
            </div>
        </ng-container>
        <ng-template #elseExpansion>
            <div class="row" id="itemsResults">               
                <mat-accordion *ngIf="hayResultado" class="col-12">           
                    <div  class="col-12" *ngFor="let resultado of resultados | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage}" >
                        <div class="itemResult">                   
                            <mat-expansion-panel #matExpansionPanel id= '{{resultado?.id}}' [expanded]="resultado?.id === paramId" (afterCollapse)="resetState()">
                                <mat-expansion-panel-header collapsedHeight="70px" (click)="expandPanel(matExpansionPanel, $event)">                                                           
                                    <div class="nombreInst  my-auto">
                                        {{resultado.title}}
                                    </div>
                                    <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small' " class="direc  my-auto">{{resultado.direccion}}</div>   
                                    <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small' " class="telef  my-auto">{{resultado.telefono[0]?.numero}}</div>
                                    
                                    <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small'" class="iconMapCol my-auto ">
                                        <mat-divider class="preDiv my-auto"  [vertical]="true" style="height: 30px; width: 2px; margin: 0;"></mat-divider>
                                        <div class="icoMap">
                                            <a><img src="assets/img/icon-lista-mapa.png" (click)="verSingleResults(resultado)"></a>
                                        </div>
                                        <mat-divider class="postDiv my-auto" [vertical]="true" style="height: 30px; width: 2px; margin: 0;"></mat-divider>
                                    </div>
                                    
                                    <div class="labelArrow  my-auto">VER MÁS</div>
                                </mat-expansion-panel-header>
                                <div class="infoResultado">
                                    <ul>
                                        <li *ngIf="resultado?.telefono[1]" class="telTitulo">                                        
                                            <ul>
                                                <span>OTROS TELEFONOS:</span>
                                                <li class="tel" *ngFor="let r_tel of resultado?.telefono.slice(1)">
                                                    <a href= "tel:{{r_tel.numero}}">{{r_tel.numero}}</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li *ngIf="resultado?.whatsapp[0]" class="whaTitulo">
                                            <ul>
                                                <span>WHATSAPP:</span>
                                                <li class="wha" *ngFor="let r_wha of resultado?.whatsapp">
                                                    <a href= "https://wa.me/{{r_wha.numero}}" target="_blank">{{r_wha.numero}}</a>
                                                </li>                                            
                                            </ul>
                                        <li *ngIf="resultado?.email" class="emailTitulo">
                                            <ul>
                                                <span>EMAIL:</span>
                                                <li class="email">
                                                    <a href= "mailto:{{resultado.email}}">{{resultado.email}}</a>
                                                </li>                                            
                                            </ul>
                                        </li>
                                        <li *ngIf="resultado?.atencion[0]" class="horTitulo">
                                            <ul>
                                                <span>HORARIO DE ATENCION:</span>
                                                <li class="hor" *ngFor="let r_hor of resultado?.atencion">
                                                    <span>{{r_hor.dias}} de {{r_hor.horario}}</span>
                                                </li>                                          
                                            </ul>
                                        </li>
                                        <li *ngIf="resultado?.web" class="webTitulo">
                                            <ul>
                                                <span>WEB:</span>
                                                <li class="web">
                                                    <a href= "{{resultado?.web}}" target="_blank">{{resultado?.web}}</a>
                                                </li>                                            
                                            </ul>
                                        </li> 
                                        <li *ngIf="resultado?.web_turnos" class="webTurnoTitulo">
                                            <ul>
                                                <span>TURNOS:</span>
                                                <li class="webturno">
                                                    <a href= "{{resultado?.web_turnos}}" target="_blank">{{resultado?.web_turnos}}</a>
                                                </li>                                            
                                            </ul>
                                        </li> 
                                        <li *ngIf="((resultado?.rubros.length >= 1)&&(resultado?.rubros[0].name != ''))  " class="rubroTitulo">
                                            <ul>                                        
                                                <span>RUBROS:</span>
                                                <mat-chip-list class="rubro">
                                                    <mat-chip *ngFor="let r_rubro of resultado?.rubros">{{r_rubro.name}}</mat-chip>
                                                </mat-chip-list>
                                            </ul>
                                        </li> 
                                        <li *ngIf="verEspecialidad(resultado.rubros)" class="especialidadesTitulo">
                                            <ul>                                         
                                                <span>ESPECIALIDADES:</span>
                                                <mat-chip-list class="especialidades">
                                                    <div  *ngFor="let r_especial of resultado?.rubros">
                                                    <mat-chip class="especial" *ngFor="let r_especialidades of r_especial.especialidades">                                                
                                                            {{r_especialidades.name}}</mat-chip>
                                                        </div>
                                                </mat-chip-list>
                                            </ul>
                                        </li>
                                        <li *ngIf="verPractica(resultado.rubros)" class="practicasTitulo">
                                            <ul>                                         
                                                <span>PRÁCTICAS:</span>
                                                <mat-chip-list class="practicas">                                                
                                                    <div  *ngFor="let r_especial of resultado?.rubros">
                                                        <div  *ngFor="let r_especialidades of r_especial.especialidades">
                                                        <mat-chip class="especial" *ngFor="let r_practicas of r_especialidades.practicas">                                                
                                                                {{r_practicas.name}}</mat-chip>
                                                            </div>
                                                        </div>                                                 
                                                </mat-chip-list>
                                            </ul>
                                        </li>
                                    </ul>                            
                                </div>                               
                            </mat-expansion-panel>                
                        </div>
                    </div>
                </mat-accordion>
                    <div *ngIf="hayResultado" class="pagination">
                        <pagination-controls 
                        responsive="true" 
                        previousLabel="Anterior" 
                        nextLabel="Siguiente"
                        (pageChange)="currentPage = $event">
                        </pagination-controls> 
                    </div>
            </div>  
        </ng-template>

        <section class="singleResultado none" id="singleResultado">
            <div *ngIf="isPress == true" class="test">
                <div class="itemResult">                   
                    <mat-expansion-panel #matExpansionPanel id= '{{datosMarker?.id}}' [expanded]="datosMarker?.id === paramId" (afterCollapse)="resetState()">
                        <mat-expansion-panel-header collapsedHeight="70px" (click)="expandPanel(matExpansionPanel, $event)">                                                           
                            <div class="nombreInst  my-auto">
                                {{datosMarker.title}}
                            </div>
                            <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small' " class="direc  my-auto">{{datosMarker?.direccion}}</div>   
                            <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small' " class="telef  my-auto">{{datosMarker.telefono[0]?.numero}}</div>
                            
                            <div *ngIf="currentScreenSize !== 'XSmall' && currentScreenSize !=='Small'" class="iconMapCol my-auto ">
                                <mat-divider class="preDiv my-auto"  [vertical]="true" style="height: 30px; width: 2px; margin: 0;"></mat-divider>
                                <div class="icoMap">
                                    <a><img src="assets/img/icon-lista-mapa.png" (click)="verSingleResults(datosMarker)"></a>
                                </div>
                                <mat-divider class="postDiv my-auto" [vertical]="true" style="height: 30px; width: 2px; margin: 0;"></mat-divider>
                            </div>
                            
                            <div class="labelArrow  my-auto">VER MÁS</div>
                        </mat-expansion-panel-header>
                        <div class="infoResultado">
                            <ul>
                                <li *ngIf="currentScreenSize == 'XSmall'"  class="dir">
                                    <span>{{datosMarker?.direccion}}</span>
                                </li>
                                <li class="telTitulo">
                                    <ul>
                                        <span>TELEFONO:</span>
                                        <li class='tel'>
                                            <a href= "tel:{{datosMarker.telefono[0].numero}}">{{datosMarker.telefono[0].numero}}</a>
                                        </li>
                                    </ul>  
                                </li>
                                <li *ngIf="datosMarker?.telefono[1]" class="telTitulo">                                        
                                    <ul>
                                        <span>OTROS TELEFONOS:</span>
                                        <li class="tel" *ngFor="let r_tel of datosMarker?.telefono.slice(1)">
                                            <a href= "tel:{{r_tel.numero}}">{{r_tel.numero}}</a>                                            
                                        </li>
                                    </ul>
                                </li>
                                <li *ngIf="datosMarker?.whatsapp[0]" class="whaTitulo">
                                    <ul>
                                        <span>WHATSAPP:</span>
                                        <li class="wha" *ngFor="let r_wha of datosMarker?.whatsapp">
                                            <a href= "https://wa.me/{{r_wha.numero}}" target="_blank">{{r_wha.numero}}</a>
                                        </li>                                            
                                    </ul>
                                <li *ngIf="datosMarker?.email" class="emailTitulo">
                                    <ul>
                                        <span>EMAIL:</span>
                                        <li class="email">
                                            <a href= "mailto:{{datosMarker?.email}}">{{datosMarker?.email}}</a>                                            
                                        </li>                                            
                                    </ul>
                                </li>
                                <li *ngIf="datosMarker?.atencion[0]" class="horTitulo">
                                    <ul>
                                        <span>HORARIO DE ATENCION:</span>
                                        <li class="hor" *ngFor="let r_hor of datosMarker?.atencion">
                                            <span>{{r_hor.dias}} de {{r_hor.horario}}</span>
                                        </li>                                          
                                    </ul>
                                </li>
                                <li *ngIf="datosMarker.web" class="webTitulo">
                                    <ul>
                                        <span>WEB:</span>
                                        <li class="web">
                                            <a href= "{{datosMarker?.web}}" target="_blank">{{datosMarker?.web}}</a>                                            
                                        </li>                                            
                                    </ul>
                                </li> 
                                <li *ngIf="datosMarker.web_turnos" class="webTurnoTitulo">
                                    <ul>
                                        <span>TURNOS:</span>
                                        <li class="webturno">
                                            <a href= "{{datosMarker?.web_turnos}}" target="_blank">{{datosMarker?.web_turnos}}</a>                                            
                                        </li>                                            
                                    </ul>
                                </li> 
                                <li *ngIf="((datosMarker?.rubros.length >= 1)&&(datosMarker?.rubros[0].name != ''))" class="rubroTitulo">
                                    <ul>                                        
                                        <span>RUBROS:</span>
                                        <mat-chip-list class="rubro">
                                            <mat-chip *ngFor="let r_rubro of datosMarker?.rubros">{{r_rubro.name}}</mat-chip>
                                        </mat-chip-list>
                                    </ul>
                                </li> 
                                <li *ngIf="verEspecialidad(datosMarker.rubros)" class="especialidadesTitulo">
                                    <ul>                                         
                                        <span>ESPECIALIDADES:</span>
                                        <mat-chip-list class="especialidades">
                                            <div  *ngFor="let r_especial of datosMarker?.rubros">
                                            <mat-chip class="especial" *ngFor="let r_especialidades of r_especial.especialidades">                                                
                                                    {{r_especialidades.name}}</mat-chip>
                                                </div>
                                        </mat-chip-list>
                                    </ul>
                                </li>
                                <li *ngIf="verPractica(datosMarker.rubros)" class="practicasTitulo">
                                    <ul>                                         
                                        <span>PRÁCTICAS:</span>
                                        <mat-chip-list class="practicas">                                                
                                            <div  *ngFor="let r_especial of datosMarker.rubros">
                                                <div  *ngFor="let r_especialidades of r_especial.especialidades">
                                                <mat-chip class="especial" *ngFor="let r_practicas of r_especialidades.practicas">                                                
                                                        {{r_practicas.name}}</mat-chip>
                                                    </div>
                                                </div>                                                 
                                        </mat-chip-list>
                                    </ul>
                                </li>
                            </ul>                            
                        </div>                               
                    </mat-expansion-panel>                
                </div>
            </div>
        </section>

        <section class="mapa none" id="mapaSlingle">
            <div class="contentItem">
                <div class="itemResultado">
                    <div class="boxTitle">
                        <div class="nombreInst  my-auto">
                            {{datosResultados?.title}}
                        </div>
                        <button class="labelArrow  my-auto" (click)="verSingleDataList(datosResultados?.id)">VER MÁS</button>
                    </div>               
                        <div class="infoResultado">
                            <ul>
                                <li *ngIf="datosResultados?.direccion" class="dirTitulo">
                                    <ul>
                                        <li class="dir">
                                        <span>{{datosResultados?.direccion}}</span>
                                        </li>
                                    </ul>
                                 </li>   
                                <li *ngIf="datosResultados?.telefono[0]" class="telTitulo">                                        
                                    <ul>                                           
                                        <li class="tel" *ngFor="let r_tel of datosResultados?.telefono">
                                            <a href= "tel:{{r_tel.numero}}">{{r_tel.numero}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li *ngIf="datosResultados?.whatsapp[0]" class="whaTitulo">
                                    <ul>                                            
                                        <li class="wha" *ngFor="let r_wha of datosResultados?.whatsapp">
                                            <a href= "https://wa.me/{{r_wha.numero}}" target="_blank">{{r_wha.numero}}</a>
                                        </li>                                            
                                    </ul>
                                <li *ngIf="datosResultados?.email" class="emailTitulo">
                                    <ul>                                            
                                        <li class="email">
                                            <a href= "mailto:{{datosResultados?.email}}">{{datosResultados?.email}}</a>                                            
                                        </li>                                            
                                    </ul>
                                </li>
                                <li *ngIf="datosResultados?.atencion[0]" class="horTitulo">
                                    <ul>                                            
                                        <li class="hor" *ngFor="let r_hor of datosResultados?.atencion">
                                            <span>{{r_hor.dias}} de {{r_hor.horario}}</span>
                                        </li>                                          
                                    </ul>
                                </li>                                    
                            </ul>                            
                        </div>
                </div>
            </div> 


            <agm-map [latitude]="latSingle" [longitude]="lngSingle"  [zoom]="zoomSingle" [scrollwheel]="scrollwheel" [styles]="styles">
                 
                <agm-marker id="mkSingle" [latitude]="latSingle" [longitude]="lngSingle" [iconUrl]="{url:'assets/img/green-dot.png'}" >
                </agm-marker>
        
            </agm-map>
        </section>

        <section class="mapa none" id="mapaList">
            <div class="contentItem none" id="markerInfo">
            <div class="itemResultado">
                <div class="boxTitle">
                    <div class="nombreInst  my-auto">
                        {{datosMarker?.title}}
                    </div>
                    <button class="labelArrow  my-auto" (click)="verSingleInfo(datosMarker)">VER MÁS</button>
                </div>               
                    <div class="infoResultado">
                        <ul>
                            <li *ngIf="datosMarker?.direccion" class="dirTitulo">
                                <ul>
                                    <li class="dir">
                                         <span>{{datosMarker?.direccion}}</span>
                                   </li>
                               </ul>
                            </li>
                            <li *ngIf="datosMarker?.telefono[0]" class="telTitulo">                                        
                                <ul>                                           
                                    <li class="tel" *ngFor="let r_tel of datosMarker?.telefono">
                                        <a href= "tel:{{r_tel.numero}}">{{r_tel.numero}}</a>
                                    </li>
                                </ul>
                            </li>
                            <li *ngIf="datosMarker?.whatsapp[0]" class="whaTitulo">
                                <ul>                                            
                                    <li class="wha" *ngFor="let r_wha of datosMarker?.whatsapp">
                                        <a href= "https://wa.me/{{r_wha.numero}}" target="_blank">{{r_wha.numero}}</a>
                                    </li>                                            
                                </ul>
                            <li *ngIf="datosMarker?.email" class="emailTitulo">
                                <ul>                                            
                                    <li class="email">
                                        <a href= "mailto:{{datosMarker?.email}}">{{datosMarker?.email}}</a>                                        
                                    </li>                                            
                                </ul>
                            </li>
                            <li *ngIf="datosMarker?.atencion[0]" class="horTitulo">
                                <ul>                                            
                                    <li class="hor" *ngFor="let r_hor of datosMarker?.atencion">
                                        <span>{{r_hor.dias}} de {{r_hor.horario}}</span>
                                    </li>                                          
                                </ul>
                            </li>                                    
                        </ul>                            
                    </div>
            </div>
            </div>
            <agm-map *ngIf='isGeoMap &&isGeoEnable' (mapReady)="getMapInstance($event)" [latitude]="usr_lat" [longitude]="usr_lng"  [zoom]="zoom" [scrollwheel]="scrollwheel" [styles]="styles" [fitBounds]="true" >
                <agm-marker  [latitude]="usr_lat" [longitude]="usr_lng" [iconUrl]="{url:'assets/img/icon-cartilla-marker.png'}" [agmFitBounds]="true">
                    <agm-info-window>
                        <strong>Usted está aquí</strong>
                      </agm-info-window>  
                </agm-marker>                
               <agm-marker *ngFor="let marker of resultados" (markerClick)="verInfoMarker(marker)" [latitude]="marker.latitud" [longitude]="marker.longitud" [iconUrl]="{url:'assets/img/green-dot.png'}" [agmFitBounds]="true"  >
               </agm-marker>        
            </agm-map>

            <agm-map *ngIf='isGeoMap && !isGeoEnable' (mapReady)="getMapInstance($event)" [latitude]="lat" [longitude]="lng"  [zoom]="zoom" [scrollwheel]="scrollwheel" [styles]="styles" [fitBounds]="true">                 
               <agm-marker *ngFor="let marker of resultados" (markerClick)="verInfoMarker(marker)" [latitude]="marker.latitud" [longitude]="marker.longitud" [iconUrl]="{url:'assets/img/green-dot.png'}"  >
               </agm-marker>        
            </agm-map>
            <agm-map *ngIf='!isGeoMap' (mapReady)="getMapInstance($event)" [latitude]="lat" [longitude]="lng"  [zoom]="zoom" [scrollwheel]="scrollwheel" [styles]="styles" [fitBounds]="true">
               <agm-marker *ngFor="let marker of resultados" (markerClick)="verInfoMarker(marker)" [latitude]="marker.latitud" [longitude]="marker.longitud" [iconUrl]="{url:'assets/img/green-dot.png'}" [agmFitBounds]= "true" >
               </agm-marker>        
            </agm-map>




        </section>

    </div>
</section>
<!-- Modal -->
<!-- <div class="modal fade bd-example-modal-lg" id="modalLogin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p><strong>Si sos socio vittal,</strong> podes agregarlo a tus favoritos ingresando a gestiones online</p>
            <form action="">
                <label for="">
                    Email
                    <input type="email" >
                </label>
                <label for="">
                    Contraseña
                    <input type="password"  >
                </label>
                <p class="contentBtn"><button type="button">Ingresar</button></p>
            </form>
            <p class="olvido"><a href="#" >¿Olvidaste tu contraseña?</a></p>
            <p class="registrarse"><a href="#" >Registrarse</a></p>
        </div>
      </div>
    </div>
  </div> -->