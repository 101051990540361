/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./secciones-destacadas.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../data-wp.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./secciones-destacadas.component";
import * as i7 from "@angular/platform-browser";
var styles_SeccionesDestacadasComponent = [i0.styles];
var RenderType_SeccionesDestacadasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeccionesDestacadasComponent, data: {} });
export { RenderType_SeccionesDestacadasComponent as RenderType_SeccionesDestacadasComponent };
function View_SeccionesDestacadasComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "itemPlan itemPromo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row h-100 itemPlanBoxInterno"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-12 my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icono, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.nombre; _ck(_v, 6, 0, currVal_1); }); }
function View_SeccionesDestacadasComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "itemPlan"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row h-100 itemPlanBoxInterno"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-12 my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icono, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.nombre; _ck(_v, 6, 0, currVal_1); }); }
function View_SeccionesDestacadasComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "col-6 col-lg"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SeccionesDestacadasComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SeccionesDestacadasComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "/", _v.context.$implicit.link, ""); _ck(_v, 1, 0, currVal_2); var currVal_3 = (_v.context.$implicit.promo == true); _ck(_v, 3, 0, currVal_3); var currVal_4 = (_v.context.$implicit.promo == false); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SeccionesDestacadasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["class", "destacadas"], ["style", "background-image: url('assets/img/bk-destacados-home.jpg');"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row m-0 seven-cols"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SeccionesDestacadasComponent_2)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.acf.boton; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.acf.titulo; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.acf.subtitulo; _ck(_v, 4, 0, currVal_1); }); }
export function View_SeccionesDestacadasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SeccionesDestacadasComponent_1)), i1.ɵdid(1, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.posts$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SeccionesDestacadasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-secciones-destacadas", [], null, null, null, View_SeccionesDestacadasComponent_0, RenderType_SeccionesDestacadasComponent)), i1.ɵprd(512, null, i4.DataWpServicePlanes, i4.DataWpServicePlanes, [i5.HttpClient]), i1.ɵdid(2, 114688, null, 0, i6.SeccionesDestacadasComponent, [i4.DataWpServicePlanes, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SeccionesDestacadasComponentNgFactory = i1.ɵccf("app-secciones-destacadas", i6.SeccionesDestacadasComponent, View_SeccionesDestacadasComponent_Host_0, {}, {}, []);
export { SeccionesDestacadasComponentNgFactory as SeccionesDestacadasComponentNgFactory };
