<div *ngIf="isMobile" class="boxCalculador" [ngClass]="{ first: primer }">
  <div class="fondo"></div>
  <div class="titulo">{{ nombre }}</div>
  <div class="txt">
    <p><img src="{{ icono }}" alt="" /></p>
    <p>
      <strong>Grupo familiar <br />{{ valor }}</strong> {{ textoPersonas }}
    </p>
    <h1 id="res{{ idPrecio }}"></h1>
    <p style="position: relative; bottom: 10px">
      <small>Es tu abono mensual</small>
    </p>
    <p><button id="{{ nombre }}" (click)="contratar()">CONTRATAR</button></p>
    <p>
      <a routerLink="/planes-de-salud/{{ slug_plan }}" target="_blank"
        >Ver plan detallado</a
      >
    </p>
  </div>
</div>

<div
  *ngIf="!isIdoc && !isMobile"
  class="boxCalculador"
  [ngClass]="{ first: primer }"
>
  <div class="fondo"></div>
  <div class="titulo">{{ nombre }}</div>
  <div class="txt">
    <p><img src="{{ icono }}" alt="" /></p>
    <p>
      <strong>Grupo familiar <br />{{ valor }}</strong> {{ textoPersonas }}
    </p>
    <h1 id="res{{ idPrecio }}"></h1>
    <p style="position: relative; bottom: 10px">
      <small>Es tu abono mensual</small>
    </p>
    <p><button id="{{ nombre }}" (click)="contratar()">CONTRATAR</button></p>
    <p>
      <a routerLink="/planes-de-salud/{{ slug_plan }}" target="_blank"
        >Ver plan detallado</a
      >
    </p>
  </div>
</div>

<div
  *ngIf="isIdoc && !isMobile"
  class="boxCalculador"
  style="padding: 0px 15px 0px 15px"
  [ngClass]="{ first: primer }"
>
  <div class="fondo"></div>
  <div class="row">
    <div class="col-5 col-lg-5" style="padding-left: 0px">
      <img
        src="assets/img/img-idoc.jpg"
        alt=""
        style="width: 70%"
        id="imgIdoc"
      />
      <div
        class="titulo"
        id="idocTitulo"
        style="width: 28%; border-bottom: 0px; top: 28px; position: absolute"
      >
        {{ nombre }}
        <br />
        <p><img src="{{ icono }}" alt="" /></p>
      </div>
    </div>
    <div class="col-2 col-lg-2 txt" style="align-self: center; height: 79px">
      <p>
        <strong>Grupo familiar <br />{{ valor }}</strong> {{ textoPersonas }}
      </p>
    </div>
    <div class="col-2 col-lg-2 txt" style="align-self: center; height: 79px">
      <h1 id="res{{ idPrecio }}"></h1>
      <p style="position: relative; bottom: 10px">
        <small>Es tu abono mensual</small>
      </p>
    </div>
    <div class="col-3 col-lg-3 txt" style="align-self: center; height: 79px">
      <p><button id="{{ nombre }}" (click)="contratar()">CONTRATAR</button></p>
      <p>
        <a routerLink="/planes-de-salud/{{ slug_plan }}" target="_blank"
          >Ver plan detallado</a
        >
      </p>
    </div>
  </div>
</div>

<ng-template #modalRespuesta let-modal id="modalRespuesta">
  <div class="modal-body contentModal" style="height: 200px">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
      style="outline: none"
    >
      <span aria-hidden="true">x</span>
    </button>
    <h4
      style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center"
    >
      {{ modalMessage }}
    </h4>
  </div>
</ng-template>
