<form class="formLoginSocios" [formGroup]="contactForm" (ngSubmit)="submitForm()">
    <div class="row">
        <mat-checkbox formControlName="checkbox" class="col-12 col-lg-12"  color='primary'>Marcar si es Pasaporte</mat-checkbox>
        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': dniSocio.invalid && (dniSocio.dirty || dniSocio.touched) }">
            <input *ngIf="ischecked"  maxlength="9" formControlName="dniSocio"  id="dniSocio" >
            <input  *ngIf="!ischecked" maxlength="8" formControlName="dniSocio"  id="dniSocio" >
            <span [ngClass]="{'spanInvalido': dniSocio.invalid && (dniSocio.dirty || dniSocio.touched)}" >{{dniOPass}} *</span>
            <div *ngIf="dniSocio.invalid && (dniSocio.dirty || dniSocio.touched)" class="invalido">
                <div *ngIf="dniSocio.errors.required && !ischecked">
                    El DNI es requerido
                </div>
                <div *ngIf="dniSocio.errors.required && ischecked">
                    El Pasaporte es requerido
                </div>   
                <div *ngIf="dniSocio.errors.pattern && !ischecked">
                    El DNI debe tener entre 7 y 8 números.
                </div>
                <div *ngIf="dniSocio.errors.pattern && ischecked">
                    El Pasaporte debe tener 6 numeros y 3 letras.
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': emailSocio.invalid && (emailSocio.dirty || emailSocio.touched)  }" >
            <input type="text" formControlName="emailSocio"  id="emailSocio">
            <span [ngClass]="{'spanInvalido ': emailSocio.invalid && (emailSocio.dirty || emailSocio.touched)  } ">Email *</span>
            <div *ngIf="emailSocio.invalid && (emailSocio.dirty || emailSocio.touched)" class="invalido">
                <div *ngIf="emailSocio.errors.required">
                    El Email es requerido.
                </div>
                <div *ngIf="emailSocio.errors.email">
                    Debe colocar una direccion de email valida.
                </div>
            </div>  
        </div>

        <div class="col-12 col-lg-12 contentButton">
            <div class="row">
                <div class="col-12 col-lg-6"><button [disabled]="contactForm.invalid" >INICIAR SESIÓN</button></div>
                <div class="col-12 col-lg-6"><input type="button" (click)="abrirFormSocios()" value="INGRESO POR PRIMERA VEZ" ></div>
            </div>
            <div class="row boxObligatorios">
                <p><small>* Campos obligatorios</small></p>
            </div>
            
            
        </div>                              
    </div>
</form>

<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 25px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>