<div id="idoc">


    <section class="principal" style="background-image: url('assets/img/idoc-back-top.png');">
        <div class="container">
            <div class="row h-100">
                <div class="col-12 col-lg-6">
                    <img src="{{headerImange}}" alt="">
                </div>
                <div class="col-12 col-lg-6 contentTxt my-auto">
                    <img src="{{headerLogo}}" alt="">
                    <div [innerHTML]="headerBajada"></div>
                    
                </div>
                <div class="col-12">
                    <div class="banner">
                        <div class="row mx-0 h-100 ">
                            <div class="col-12 col-lg-7 px-4 px-lg-5 my-auto" [innerHTML]="headerIntroduccion"></div>
                            <div class="col-12 col-lg-5 p-0 my-auto">
                                <img src="{{headerImgBanner}}" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="bullets">
        <div class="container">
            <div class="row m-0">
                <div class="col-12 col-lg-6">
                    <h2><img src="{{Bullets1Icono}}" alt=""><span [innerHTML]='Bullets1Titulo'></span></h2>
                    <div [innerHTML]='Bullets1Bajada'></div>
                </div>
                <div class="col-12 col-lg-6">
                    <h2><img src="{{Bullets2Icono}}" alt=""><span [innerHTML]='Bullets2Titulo'></span></h2>
                    <div [innerHTML]='Bullets2Bajada'></div>
                </div>
                <div class="col-12 col-lg-6">
                    <h2><img src="{{Bullets3Icono}}" alt=""><span [innerHTML]='Bullets3Titulo'></span></h2>
                    <div [innerHTML]='Bullets3Bajada'></div>
                </div>
                <div class="col-12 col-lg-6 iconoBullets">
                    <img src="{{BulletsImg}}" alt="">
                </div>
            </div>
        </div>
    </section>

    <section class="aplicacion">
        <div class="container">
            <h2>{{DescargaTitulo}}</h2>
            <p>{{DescargaSubTitulo}}</p>
            <div class="boxDescarga">
                <div class="row mx-0">
                    <div class="col-12 col-lg-6 p-3">
                        <p>{{DescargaTitulo_box_1}}</p>
                        <p>
                            <a href="{{DescargaLink_1_box_1}}" target="_blank"><img src="assets/img/idoc-down-1.png" alt=""></a>
                            <a href="{{DescargaLink_2_box_1}}" target="_blank"><img src="assets/img/idoc-down-2.png" alt=""></a>
                        </p>
                    </div>
                    <div class="col-12 col-lg-6 p-3">
                        <p>{{DescargaTitulo_box_2}}</p>
                        <h2><a href="{{DescargaLink_box_2}}"  target="_blank">idoc.vittal.com.ar</a></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="pasos">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-4 mb-5">
                    <img src="{{pasosPaso_1Imagen}}" alt="">
                    <div [innerHTML]='pasosPaso_1Bajada'></div>
                </div>
                <div class="col-12 col-lg-4 mb-5">
                    <img src="{{pasosPaso_2Imagen}}" alt="">
                    <div [innerHTML]='pasosPaso_2Bajada'></div>
                </div>
                <div class="col-12 col-lg-4 mb-5">
                    <img src="{{pasosPaso_3Imagen}}" alt="">
                    <div [innerHTML]='pasosPaso_3Bajada'></div>
                </div>
                <div class="col-12 footText" [innerHTML]='pasosTextoFinal'>
                    
                </div>
            </div>
            <div class="contentLegales" *ngIf="legales">
                <a class="btnLegales" (click)="openModal(popupLegales)">Ver legales</a>
            </div>
        </div>

    </section>

</div>    


<ng-template #popupLegales let-modal>
    <div class="contentPopUp">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()"><span aria-hidden="true">×</span></button>       
        <div *ngIf="legales" class="modal-body" [innerHTML]="transform(legales)" style="height: 500px; overflow-y: scroll;"></div>
    </div>                        
  </ng-template>