<div class="titulosRegistroSocios">
    <h2>Registrate en<br> Gestiones online</h2>
    <p>Completá el formulario con tus datos:</p>
    <!-- <div *ngIf='screenSize < 470 && (!isHome)' class="contentImgMobile" id="imgHover">
        <div (click)="volverGestion()" [ngx-scroll-to]="'#up'"  class="btnVolverGestion" id="volverGestion">Gestion Online</div>
    </div> -->
</div>
<form class="formLoginSocios" [formGroup]="contactForm" (ngSubmit)="submitForm()">
    <div class="row">
        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': nombreSocioReg.invalid && (nombreSocioReg.dirty || nombreSocioReg.touched)  }" >
            <input type="text" formControlName="nombreSocioReg"  id="nombreSocioReg">
            <span [ngClass]="{'spanInvalido ': nombreSocioReg.invalid && (nombreSocioReg.dirty || nombreSocioReg.touched)  } ">Nombre *</span>
            <div *ngIf="nombreSocioReg.invalid && (nombreSocioReg.dirty || nombreSocioReg.touched)" class="invalido">
                <div *ngIf="nombreSocioReg.errors.required">
                    El nombre es requerido.
                </div>
            </div>  
        </div>

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': apellidoSocioReg.invalid && (apellidoSocioReg.dirty || apellidoSocioReg.touched)  }" >
            <input type="text" formControlName="apellidoSocioReg"  id="apellidoSocioReg">
            <span [ngClass]="{'spanInvalido ': apellidoSocioReg.invalid && (apellidoSocioReg.dirty || apellidoSocioReg.touched)  } ">Apellido *</span>
            <div *ngIf="apellidoSocioReg.invalid && (apellidoSocioReg.dirty || apellidoSocioReg.touched)" class="invalido">
                <div *ngIf="apellidoSocioReg.errors.required">
                    El Apellido es requerido.
                </div>
            </div>  
        </div>
        <mat-checkbox formControlName="checkbox" class="col-12 col-lg-12"  color='primary'>Marcar si es Pasaporte</mat-checkbox>
        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': dniSocio.invalid && (dniSocio.dirty || dniSocio.touched) }">
            <input *ngIf="ischecked"  maxlength="9" formControlName="dniSocio"  id="dniSocio" >
            <input  *ngIf="!ischecked" maxlength="8" formControlName="dniSocio"  id="dniSocio" >
            <span [ngClass]="{'spanInvalido': dniSocio.invalid && (dniSocio.dirty || dniSocio.touched)}" >{{dniOPass}} *</span>
            <div *ngIf="dniSocio.invalid && (dniSocio.dirty || dniSocio.touched)" class="invalido">
                <div *ngIf="dniSocio.errors.required && !ischecked">
                    El DNI es requerido
                </div>
                <div *ngIf="dniSocio.errors.required && ischecked">
                    El Pasaporte es requerido
                </div>   
                <div *ngIf="dniSocio.errors.pattern && !ischecked">
                    El DNI debe tener entre 7 y 8 números.
                </div>
                <div *ngIf="dniSocio.errors.pattern && ischecked">
                    El Pasaporte debe tener 6 numeros y 3 letras.
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': nroSocioReg.invalid && (nroSocioReg.dirty || nroSocioReg.touched) }">
            <input  type="text"  formControlName="nroSocioReg"  id="nroSocioReg" >
            <span [ngClass]="{'spanInvalido': nroSocioReg.invalid && (nroSocioReg.dirty || nroSocioReg.touched)}" >Nro. Socio *</span>
            <div *ngIf="nroSocioReg.invalid && (nroSocioReg.dirty || nroSocioReg.touched)" class="invalido">
                <div *ngIf="nroSocioReg.errors.required">
                    El Nro. Socio es requerido
                </div>
                <div *ngIf="nroSocioReg.errors.pattern">
                    Debe ingresar hasta 10 digitos.
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': emailSocioReg.invalid && (emailSocioReg.dirty || emailSocioReg.touched)  }" >
            <input type="email" formControlName="emailSocioReg"  id="emailSocioReg">
            <span [ngClass]="{'spanInvalido ': emailSocioReg.invalid && (emailSocioReg.dirty || emailSocioReg.touched)  } ">Email *</span>
            <div *ngIf="emailSocioReg.invalid && (emailSocioReg.dirty || emailSocioReg.touched)" class="invalido">
                <div *ngIf="emailSocioReg.errors.required">
                    El Email es requerido.
                </div>
                <div *ngIf="emailSocioReg.errors.email">
                    Debe colocar una direccion de email valida.
                </div>
            </div>  
        </div>
        

        <div class="col-12 col-lg-4" [ngClass]="{'campoInvalido ': telefonoSocioReg2.invalid && (telefonoSocioReg2.dirty || telefonoSocioReg2.touched) }">
            <input  maxlength="8" type="number" formControlName="telefonoSocioReg2"  id="telefonoSocioReg2" >
            <span [ngClass]="{'spanInvalido': telefonoSocioReg2.invalid && (telefonoSocioReg2.dirty || telefonoSocioReg2.touched)}" >Código de área *</span>
            <div *ngIf="telefonoSocioReg2.invalid && (telefonoSocioReg2.dirty || telefonoSocioReg2.touched)" class="invalido">
                <div *ngIf="telefonoSocioReg2.errors.required">
                    La caracteristica es requerida.
                </div>
            </div>
        </div>


        <div class="col-12 col-lg-8" [ngClass]="{'campoInvalido ': telefonoSocioReg3.invalid && (telefonoSocioReg3.dirty || telefonoSocioReg3.touched) }">
            <input  maxlength="8" type="number" formControlName="telefonoSocioReg3"  id="telefonoSocioReg3" >
            <span [ngClass]="{'spanInvalido': telefonoSocioReg3.invalid && (telefonoSocioReg3.dirty || telefonoSocioReg3.touched)}" >Número de teléfono *</span>
            <div *ngIf="telefonoSocioReg3.invalid && (telefonoSocioReg3.dirty || telefonoSocioReg3.touched)" class="invalido">
                <div *ngIf="telefonoSocioReg3.errors.required">
                    El telefono es requerido
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-12 contentButton">
            <div class="row">
                <div class="col-12 col-lg-6"><button [disabled]="contactForm.invalid" >ENVIAR</button></div>
                
            </div>
            <div class="row boxObligatorios">
                <p><small>* Campos obligatorios</small></p>
            </div>
            
            
        </div>                              
    </div>
</form>

<ng-template #modalEnvioOk let-modal>
    <div class="modal-body contentModal">  
        <h2>GESTIÓN ONLINE DE VITTAL</h2>
        <p>Tu registro se completó correctamente, <br> a la brevedad te confirmaremos
            por email el acceso a Gestiones online, y luego podrás loguearte para
            ingresar.</p>
        <div class="contentBtns">
            <p><span class="btnClose" aria-label="Close" (click)="volverGestion()">VOLVER</span></p>
        </div>
    </div>
</ng-template>


<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>