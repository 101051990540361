<section class="contacto">
    
        <div class="row m-0">
            <div class="col-12 col-lg-4 contentBack"  style="background-image: url('assets/img/back-contacto.jpg');"></div>
            <div class="col-12 col-lg-8 px-5 contentRight">
                
                <div class="row contentForm">
                    <div class="col-12">
                        <h2><strong>Completa el siguiente formulario</strong> <br> y nos contactaremos a la brevedad.</h2>
                    </div>
                    <div class="col-12">
                        <form  [formGroup]="contactForm" (ngSubmit)="submitForm()">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <span id="areaSpan">Area de consulta</span>
                                    <div class="contentSelect" id="contentSelect" (click)="spanTop()" >
                                        <select  formControlName="area" id="area" >
                                            <option value="" disabled>Seleccionar Area</option>
                                            <option *ngFor="let area of areas" [value]="area">{{area}}</option>   
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3" [ngClass]="{'campoInvalido ': nombres.invalid && (nombres.dirty || nombres.touched) }">
                                    <input type="text" formControlName="nombres"  id="nombres">
                                    <span [ngClass]="{'spanInvalido': nombres.invalid && (nombres.dirty || nombres.touched)}" >Nombres *</span>
                                    <div *ngIf="nombres.invalid && (nombres.dirty || nombres.touched)" class="invalido">
                                        <div *ngIf="nombres.errors.required">
                                            El nombre es requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3" [ngClass]="{'campoInvalido ': apellido.invalid && (apellido.dirty || apellido.touched) }">
                                    <input type="text" formControlName="apellido"  id="apellido">
                                    <span [ngClass]="{'spanInvalido': apellido.invalid && (apellido.dirty || apellido.touched)}">Apellido *</span>
                                    <div *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)" class="invalido">
                                        <div *ngIf="apellido.errors.required">
                                            El apellido es requerido
                                        </div>
                                    </div>
                                </div>   


                                <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': email.invalid && (email.dirty || email.touched)  }" >
                                    <input type="text" formControlName="email"  id="email">
                                    <span [ngClass]="{'spanInvalido ': email.invalid && (email.dirty || email.touched)  } ">Email *</span>


                                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalido">
                                        <div *ngIf="email.errors.required">
                                            El email es requerido.
                                        </div>
                                        <div *ngIf="email.errors.email">
                                            Debe colocar una direccion de email valida.
                                        </div>
                                    </div>  
                                </div>
                                <div class="col-4 col-lg-2" [ngClass]="{'campoInvalido ': telefono_car.invalid && (telefono_car.dirty || telefono_car.touched)  }">
                                    <input type="text" formControlName="telefono_car"  id="telefono_car">
                                    <span  [ngClass]="{'spanInvalido': telefono_car.invalid && (telefono_car.dirty || telefono_car.touched) }">Cod. Área*</span>
                                </div>
                                <div class="col-8 col-lg-4" [ngClass]="{'campoInvalido ': telefono_num.invalid && (telefono_num.dirty || telefono_num.touched)  }" >
                                    <input type="text" formControlName="telefono_num"  id="telefono_num">
                                    <span [ngClass]="{'spanInvalido': telefono_num.invalid && (telefono_num.dirty || telefono_num.touched) }">Teléfono*</span>
                                </div>
                                
                                <div class="col-12 offset-lg-6 col-lg-6 msjTels" >


                                    <p *ngIf="telefono_car.invalid && (telefono_car.dirty || telefono_car.touched)" class="invalido">
                                        <span *ngIf="telefono_car.errors.required">
                                            El codigo de area es requerido.
                                        </span>
                                        <span *ngIf="telefono_car.invalid">
                                            Debe colocar un codigo de area valido.
                                        </span>
                                    </p>  

                                    <p *ngIf="telefono_num.invalid && (telefono_num.dirty || telefono_num.touched)" class="invalido">
                                        <span *ngIf="telefono_num.errors.required">
                                            El telefono es requerido.
                                        </span>
                                        <span *ngIf="telefono_num.invalid">
                                            Debe colocar un telefono valido.
                                        </span>
                                    </p> 



                                    <!-- 

                                        <div *ngIf="(telefono_car.invalid || telefono_num.invalid )  && ( (telefono_car.dirty || telefono_car.touched) && (telefono_num.dirty || telefono_num.touched) )" class="invalido">
                                            <span *ngIf="telefono_car.errors.required && telefono_num.errors.required">
                                                El telefono es requerido.
                                            </span>
                                            <span *ngIf="telefono_car.invalid || telefono_num.invalid  && (telefono_car.errors.invalidNumber || telefono_num.errors.invalidNumber)">
                                                Debe ser un telefono correcto.
                                            </span>
                                            <span *ngIf="telefono_car.errors.minlength || telefono_num.errors.minlength">
                                                El telefono debe tener al menos 6 números.
                                            </span>
                                        </div>  

                                    -->




                                </div>

                                <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': mensaje.invalid && (mensaje.dirty || mensaje.touched)  }">
                                    <textarea formControlName="mensaje" id="mensaje"></textarea>
                                    <span [ngClass]="{'spanInvalido': mensaje.invalid && (mensaje.dirty || mensaje.touched) }">Mensaje</span>
                                    <div *ngIf="mensaje.invalid && (mensaje.dirty || mensaje.touched)" class="invalido">
                                        <div *ngIf="mensaje.errors.required">
                                            El mensaje es requerido.
                                        </div>
                                        <div *ngIf="mensaje.errors.minlength">
                                            El mensaje debe tener al menos 20 caracteres.
                                        </div>
                                    </div> 
                                </div>

                                <div class="col-12 col-lg-12 contentButton">
                                    <button type="submit" [ngx-scroll-to]="'#sendOk'" [disabled]="contactForm.invalid" >Enviar</button>
                                </div>                              
                            </div>
                        </form>
                    </div>
                </div>
                <div class="boxEnviado my-auto" id="sendOk">
                    <div class="row h-100 m-0">
                        <div class="col-12 my-auto">
                            <div class="contentSendOk">
                                <img src="assets/img/noun_send.png" alt="">
                                <h2>Tu consulta fue enviada</h2>
                                <p>¡Pronto uno de nuestros asesores se <br> contactara con vos!</p>
                                <button (click)="otraConsulta()">Hacer otra consulta</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="boxEnviado my-auto" id="sendError">
                    <div class="row h-100 m-0">
                        <div class="col-12 my-auto">
                            <div class="contentSendOk">
                                <img src="assets/img/noun_send.png" alt="">
                                <h2>{{errorMessage}}</h2>
                                <!-- <p>¡Pronto uno de nuestros asesores se <br> contactara con vos!</p> -->
                                <button (click)="otraConsulta()">Hacer otra consulta</button>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
        <!-- <section class="franquicias">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <a routerLink='/franquicias'>
                            <img src="assets/img/banner_franquicias_3.png" alt="">
                        </a>
                    </div>
                    <div class="col-12 col-lg-6">                        
                        <img src="assets/img/banner-_2.png" alt="">
                    </div>
                </div>    
            </div>
        </section> -->
    
</section>