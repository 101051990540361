<section class="destacadas" *ngFor="let post of posts$ | async">
    <h2>OTROS PLANES</h2>
    
    <div class="row m-0 seven-cols">
        <ng-container *ngFor="let botton of post.acf.boton">
            <a *ngIf="botton.promo == false" routerLink="/{{botton.link}}" class="col-6 col-lg">
                <div  class="itemPlan">
                    <div class="row h-100 itemPlanBoxInterno">
                        <div class="col-12 my-auto">
                            <p><img src="{{botton.icono}}" alt=""></p>
                            <p><span [innerHTML]='botton.nombre'></span></p>
                        </div>
                    </div>
                </div>
            </a>
        </ng-container>
    </div>

    
</section>


