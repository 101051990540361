<div class="contentForm" id="search">
          
    <form action="">
      <input type="text" minlength="1" name="buscar" name="filterPost" [(ngModel)]="filterPost" required>
      <button (click)='buscadorShow()'></button>
      <div class="resultados">
        <ul>
            <li *ngFor="let post of posts | filter:filterPost" (click)="buscadorHide()">              
              <!-- Planes -->
              <a *ngIf="post.parent == 116" id="{{post.id}}" routerLink="/{{post.slug}}">{{post.title.rendered}}</a>
              <!-- Socios -->
              <a *ngIf="post.parent == 426" id="{{post.id}}" routerLink="/{{post.slug}}">{{post.title.rendered}}</a>
              <!-- Servicios Adicionales -->
              <a *ngIf="post.parent == 381" id="{{post.id}}" routerLink="/servicios-adicionales/{{post.slug}}">{{post.title.rendered}}</a>
              <!-- Areas Protegidas -->
              <a *ngIf="post.parent == 686" id="{{post.id}}" routerLink="/areas-protegidas/{{post.slug}}">{{post.title.rendered}}</a>
              <!-- Padres -->
              <a *ngIf="post.parent == 0" id="{{post.id}}" routerLink="/{{post.slug}}">{{post.title.rendered}}</a>
            </li>  
            <li *ngFor="let post of postsBlog | filter:filterPost" (click)="buscadorHide()">
              <a id="{{post.id}}" routerLink="/blog/{{post.slug}}">{{post.title.rendered}}</a>
            </li>
            
            
        </ul>
        <div class="clearfix"></div>
    </div>
    </form>


  </div>
  <div class="backBuscador" id="backBuscador" (click)='buscadorHide()'></div>  

