<section class="nav-servicios">
    
    <h2>Notas relacionadas</h2>
    
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let post of posts" class="slide">
          <div class="contentItem" >
            <div class="item-servicio">
              <div class="pic" [ngStyle]="{'background-image':'url(' + post.better_featured_image?.source_url + ')'}"></div>
              <div class="textBox">
                <p><span class="fecha"><i class="fa fa-calendar" aria-hidden="true"></i> {{post.date_gmt | date:'fullDate' }}</span></p>
                <h3>{{post.title.rendered}}</h3>
                <p [innerHTML]="post.excerpt.rendered.substring(0,160)+'...'"></p>
              </div>
              <p class="contentLink"><a routerLink="/{{post.slug}}">Más info</a></p>

            </div>
        </div>     
      </div>
    </ngx-slick-carousel>

</section>
