<section class="header3" style="background-image:url('assets/img/oil-gas.jpg')">
    <div class="container containerHeader">
        <div class="row h-100">
            <div class="col-12 offset-lg-6 col-lg-6 my-auto">
                <h2>Oil & Gas</h2>                
            </div> 
        </div>
    </div>
</section>
<section class="box2col">
    <div class="container">
        <div class="box">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <p>En <strong>vittal</strong> creamos una división especializada en el desarrollo de cobertura médica para industrias del sector del petróleo, gas y minería.</p>
                </div>
                <div class="col-12 col-lg-6">
                    <p>Nuestra red de cobertura nacional nos permite tener un respaldo operativo permanente, logrando la mayor e­ciencia a la hora de una intervención para una hospitalización o un traslado sanitario aéreo.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="txt-foto">
    <div class="container content-txt-foto">
        <div class="row">
            <div class="col-12 col-lg-6">
                <p>Nuestra red de cobertura nacional nos permite tener un respaldo operativo permanente, logrando la mayor e­ciencia a la hora de una intervención para una hospitalización o un traslado sanitario aéreo.</p>
            </div>
            <div class="col-12 col-lg-6">
                <img src="assets/img/oil-gas-pic-1.jpg" alt="">
            </div>
        </div>
    </div>
</section>
<section class="bullets">
    <div class="container content-bullets">
        <div class="row">
            <div class="col-12">
                <h2>Servicios:</h2>
                <p>Quienes nos eligen destacan la amplitud, la competencia y la calidad de nuestra cobertura médica, la excelencia de nuestros profesionales y la atención personalizada que brindamos a través de un equipo altamente capacitado.</p>
            </div>  
            <div class="col-12 col-lg-6">
                
                <ul>
                    <li>Unidad de Terapia Intensiva Móvil 4x4 ­fija con equipamiento de última generación, óptima para el traslado de pacientes.</li>
                    <li>Módulo sanitario fi­jo con unidad habitacional y consultorio médico de última generación.</li>
                    <li>Guardia y enfermería las 24 horas.</li>
                    <li>Medicina laboral.</li>
                </ul>
            </div>
            <div class="col-12 col-lg-6">
                <ul>
                    <li>Equipos DEA y entrenamiento en RCP para obtener la certi­cación de Espacio Cardioprotegido.</li>
                    <li>Provisión de medicamentos e insumos médicos.</li>
                    <li>Traslados aeromédicos</li>
                </ul>
            </div>
        </div>
    </div>
</section>