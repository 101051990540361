<div class="boxForm cotizador" id="contentAll">
  <form action="" [formGroup]="contactForm" (ngSubmit)="submitForm(codeForm)">
    <div class="row m-0">
      <div class="col-12">
        <h2>{{ tituloForm }}</h2>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ': nombre.invalid && (nombre.dirty || nombre.touched)
        }"
      >
        <input type="text" formControlName="nombre" id="nombre" />
        <span
          [ngClass]="{
            spanInvalido: nombre.invalid && (nombre.dirty || nombre.touched)
          }"
          >Nombre *</span
        >
        <div
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"
          class="invalido"
        >
          <div *ngIf="nombre.errors.required">El nombre es requerido</div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            apellido.invalid && (apellido.dirty || apellido.touched)
        }"
      >
        <input type="text" formControlName="apellido" id="apellido" />
        <span
          [ngClass]="{
            spanInvalido:
              apellido.invalid && (apellido.dirty || apellido.touched)
          }"
          >Apellido *</span
        >
        <div
          *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)"
          class="invalido"
        >
          <div *ngIf="apellido.errors.required">El apellido es requerido</div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ': email.invalid && (email.dirty || email.touched)
        }"
      >
        <input type="text" formControlName="email" id="email" />
        <span
          [ngClass]="{
            'spanInvalido ': email.invalid && (email.dirty || email.touched)
          }"
          >Email *</span
        >

        <div
          *ngIf="email.invalid && (email.dirty || email.touched)"
          class="invalido"
        >
          <div *ngIf="email.errors.required">El email es requerido.</div>
          <div *ngIf="email.errors.email">
            Debe colocar una direccion de email valida.
          </div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            telefono.invalid && (telefono.dirty || telefono.touched)
        }"
      >
        <input type="number" id="telefono" formControlName="telefono" />
        <span
          [ngClass]="{
            'spanInvalido ':
              telefono.invalid && (telefono.dirty || telefono.touched)
          }"
          >Teléfono *</span
        >

        <div
          *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)"
          class="invalido"
        >
          <div *ngIf="telefono.errors.required">El telefono es requerido.</div>
          <div *ngIf="telefono.errors.pattern">
            Debe colocar un telefono valido.
          </div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            localidad.invalid && (localidad.dirty || localidad.touched)
        }"
      >
        <input type="text" formControlName="localidad" id="localidad" />
        <span
          [ngClass]="{
            spanInvalido:
              localidad.invalid && (localidad.dirty || localidad.touched)
          }"
          >Localidad *</span
        >
        <div
          *ngIf="localidad.invalid && (localidad.dirty || localidad.touched)"
          class="invalido"
        >
          <div *ngIf="localidad.errors.required">
            La localidad es requerida.
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 itemForm">
        <div class="contentSelect" id="contentSelect">
          <select
            formControlName="select"
            id="select"
            #t
            (change)="selectHijos(t.value)"
            (focus)="spanTop()"
          >
            <option *ngFor="let item of items" [value]="item">
              {{ item }}
            </option>
          </select>
          <span id="areaSpan">Cantidad de Integrantes *</span>
        </div>
      </div>

      <div
        class="col-12 col-lg-12 itemForm itemsEdades"
        id="contentEddades"
        (click)="spanTopEdades()"
      >
        <span id="edadesSpan">Edades *</span>

        <div class="fieldEdad" id="contentField1">
          <input
            (focus)="spanTopEdades()"
            type="number"
            maxlength="3"
            placeholder="--"
            formControlName="edad1"
            id="edad1"
            required
          />
        </div>
        <div *ngIf="valor > 1" class="fieldEdad" id="contentField2">
          <input
            type="number"
            maxlength="3"
            formControlName="edad2"
            id="edad2"
          />
        </div>
        <div *ngIf="valor > 2" class="fieldEdad" id="contentField3">
          <input
            type="number"
            maxlength="3"
            formControlName="edad3"
            id="edad3"
          />
        </div>
        <div *ngIf="valor > 3" class="fieldEdad" id="contentField4">
          <input
            type="number"
            maxlength="3"
            formControlName="edad4"
            id="edad4"
          />
        </div>
        <div *ngIf="valor > 4" class="fieldEdad" id="contentField5">
          <input
            type="number"
            maxlength="3"
            formControlName="edad5"
            id="edad5"
            required
          />
        </div>
        <div *ngIf="valor > 5" class="fieldEdad" id="contentField6">
          <input
            type="number"
            maxlength="3"
            formControlName="edad6"
            id="edad6"
            required
          />
        </div>
        <div *ngIf="valor > 6" class="fieldEdad" id="contentField7">
          <input
            type="number"
            maxlength="3"
            formControlName="edad7"
            id="edad7"
            required
          />
        </div>
        <div *ngIf="valor > 7" class="fieldEdad" id="contentField8">
          <input
            type="number"
            maxlength="3"
            formControlName="edad8"
            id="edad8"
            required
          />
        </div>
        <div *ngIf="valor > 8" class="fieldEdad" id="contentField9">
          <input
            type="number"
            maxlength="3"
            formControlName="edad9"
            id="edad9"
            required
          />
        </div>
        <div *ngIf="valor > 9" class="fieldEdad" id="contentField10">
          <input
            type="number"
            maxlength="3"
            formControlName="edad10"
            id="edad10"
            required
          />
        </div>
      </div>

      <div *ngFor="let post of posts" class="col-12 contentButton">
        <p *ngIf="post.id == codePrice">
          <button
            id="{{ planName }}"
            data-dismiss="modal"
            (click)="open(modalResult)"
            [ngx-scroll-to]="'#sendOk'"
            [disabled]="contactForm.invalid"
          >
            Cotizar
          </button>
        </p>
      </div>
    </div>
  </form>

  <ng-template #modalResult let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-lg-3 titulosModalCoti" id="plan-ideal">
          <h2>¡TU PLAN IDEAL!</h2>
        </div>
        <div
          *ngIf="!isMobile && codePrice != '4011'"
          class="col-12 col-lg-9 titulosModalCoti"
          id="otros-planes"
        >
          <h2>¡OTROS PLANES QUE TE PUEDEN INTERESAR!</h2>
        </div>

        <ng-container *ngFor="let post of posts; let i = index">
          <div
            class="col-12 col-lg-3 plan-{{ i }}"
            *ngIf="codePrice == post.id && post.id != '4011'"
          >
            <app-calculador
              [formCode]="codeForm"
              [idPrecio]="post.id"
              [primer]="true"
              [slug_plan]="post.acf.slug_plan"
              [icono]="post.acf.icono_blanco"
              [nombre]="post.acf.nombre"
              [planName]="planName"
              [price_1p]="post.acf.price_1p"
              [price_2p]="post.acf.price_2p"
              [price_3p]="post.acf.price_3p"
              [price_4p]="post.acf.price_4p"
              [price_p_adicional]="post.acf.price_p_adicional"
              [price_copago]="post.acf.price_copago"
              [precio_adicional_65a69]="post.acf.precio_adicional_65a69"
              [precio_adicional_70a74]="post.acf.precio_adicional_70a74"
              [precio_adicional_75a79]="post.acf.precio_adicional_75a79"
              [precio_adicional_80mas]="post.acf.precio_adicional_80mas"
              [valor]="valor"
              [nombrePersona]="nombrePersona"
              [apellidoPersona]="apellidoPersona"
              [emailPersona]="emailPersona"
              [telefonoPersona]="telefonoPersona"
              [localidadPersona]="localidadPersona"
              [datoFormEdad1]="datoFormEdad1"
              [datoFormEdad2]="datoFormEdad2"
              [datoFormEdad3]="datoFormEdad3"
              [datoFormEdad4]="datoFormEdad4"
              [datoFormEdad5]="datoFormEdad5"
              [datoFormEdad6]="datoFormEdad6"
              [datoFormEdad7]="datoFormEdad7"
              [datoFormEdad8]="datoFormEdad8"
              [datoFormEdad9]="datoFormEdad9"
              [datoFormEdad10]="datoFormEdad10"
              [isBitrix]="isBitrix"
              [isMobile]="isMobile"
            ></app-calculador>
          </div>

          <div
            class="col-12 col-lg-12 plan-{{ i }}"
            *ngIf="codePrice == post.id && post.id == '4011'"
          >
            <app-calculador
              [formCode]="codeForm"
              [idPrecio]="post.id"
              [primer]="true"
              [slug_plan]="post.acf.slug_plan"
              [icono]="post.acf.icono_blanco"
              [nombre]="post.acf.nombre"
              [planName]="planName"
              [price_1p]="post.acf.price_1p"
              [price_2p]="post.acf.price_2p"
              [price_3p]="post.acf.price_3p"
              [price_4p]="post.acf.price_4p"
              [price_p_adicional]="post.acf.price_p_adicional"
              [price_copago]="post.acf.price_copago"
              [precio_adicional_65a69]="post.acf.precio_adicional_65a69"
              [precio_adicional_70a74]="post.acf.precio_adicional_70a74"
              [precio_adicional_75a79]="post.acf.precio_adicional_75a79"
              [precio_adicional_80mas]="post.acf.precio_adicional_80mas"
              [valor]="valor"
              [nombrePersona]="nombrePersona"
              [apellidoPersona]="apellidoPersona"
              [emailPersona]="emailPersona"
              [telefonoPersona]="telefonoPersona"
              [localidadPersona]="localidadPersona"
              [datoFormEdad1]="datoFormEdad1"
              [datoFormEdad2]="datoFormEdad2"
              [datoFormEdad3]="datoFormEdad3"
              [datoFormEdad4]="datoFormEdad4"
              [datoFormEdad5]="datoFormEdad5"
              [datoFormEdad6]="datoFormEdad6"
              [datoFormEdad7]="datoFormEdad7"
              [datoFormEdad8]="datoFormEdad8"
              [datoFormEdad9]="datoFormEdad9"
              [datoFormEdad10]="datoFormEdad10"
              [isBitrix]="isBitrix"
              [isMobile]="isMobile"
              [isIdoc]="true"
            ></app-calculador>
            <div
              *ngIf="!isMobile && codePrice == '4011'"
              class="col-12 col-lg-9 titulosModalCoti"
              id="otros-planes-idoc"
            >
              <h2>¡OTROS PLANES QUE TE PUEDEN INTERESAR!</h2>
            </div>
          </div>
        </ng-container>
        <div *ngIf="isMobile" class="col-12 titulosModalCoti">
          <h2>¡OTROS PLANES QUE TE PUEDEN INTERESAR!</h2>
        </div>
        <ng-container *ngFor="let post of posts; let i = index">
          <div
            class="col-12 col-lg-3 plan-{{ i }}"
            *ngIf="codePrice != post.id && post.id != 4011"
          >
            <app-calculador
              [idPrecio]="post.id"
              [nombre]="post.acf.nombre"
              [planName]="planName"
              [primer]="false"
              [slug_plan]="post.acf.slug_plan"
              [icono]="post.acf.icono_violeta"
              [price_1p]="post.acf.price_1p"
              [price_2p]="post.acf.price_2p"
              [price_3p]="post.acf.price_3p"
              [price_4p]="post.acf.price_4p"
              [price_p_adicional]="post.acf.price_p_adicional"
              [price_copago]="post.acf.price_copago"
              [precio_adicional_65a69]="post.acf.precio_adicional_65a69"
              [precio_adicional_70a74]="post.acf.precio_adicional_70a74"
              [precio_adicional_75a79]="post.acf.precio_adicional_75a79"
              [precio_adicional_80mas]="post.acf.precio_adicional_80mas"
              [valor]="valor"
              [nombrePersona]="nombrePersona"
              [apellidoPersona]="apellidoPersona"
              [emailPersona]="emailPersona"
              [telefonoPersona]="telefonoPersona"
              [localidadPersona]="localidadPersona"
              [datoFormEdad1]="datoFormEdad1"
              [datoFormEdad2]="datoFormEdad2"
              [datoFormEdad3]="datoFormEdad3"
              [datoFormEdad4]="datoFormEdad4"
              [datoFormEdad5]="datoFormEdad5"
              [datoFormEdad6]="datoFormEdad6"
              [datoFormEdad7]="datoFormEdad7"
              [datoFormEdad8]="datoFormEdad8"
              [datoFormEdad9]="datoFormEdad9"
              [datoFormEdad10]="datoFormEdad10"
              [isBitrix]="isBitrix"
              [isMobile]="isMobile"
            ></app-calculador>
          </div>
          <div
            class="col-12 col-lg-12 plan-{{ i }}"
            *ngIf="codePrice != post.id && post.id == '4011'"
          >
            <app-calculador
              [idPrecio]="post.id"
              [nombre]="post.acf.nombre"
              [planName]="planName"
              [primer]="false"
              [slug_plan]="post.acf.slug_plan"
              [icono]="post.acf.icono_violeta"
              [price_1p]="post.acf.price_1p"
              [price_2p]="post.acf.price_2p"
              [price_3p]="post.acf.price_3p"
              [price_4p]="post.acf.price_4p"
              [price_p_adicional]="post.acf.price_p_adicional"
              [price_copago]="post.acf.price_copago"
              [precio_adicional_65a69]="post.acf.precio_adicional_65a69"
              [precio_adicional_70a74]="post.acf.precio_adicional_70a74"
              [precio_adicional_75a79]="post.acf.precio_adicional_75a79"
              [precio_adicional_80mas]="post.acf.precio_adicional_80mas"
              [valor]="valor"
              [nombrePersona]="nombrePersona"
              [apellidoPersona]="apellidoPersona"
              [emailPersona]="emailPersona"
              [telefonoPersona]="telefonoPersona"
              [localidadPersona]="localidadPersona"
              [datoFormEdad1]="datoFormEdad1"
              [datoFormEdad2]="datoFormEdad2"
              [datoFormEdad3]="datoFormEdad3"
              [datoFormEdad4]="datoFormEdad4"
              [datoFormEdad5]="datoFormEdad5"
              [datoFormEdad6]="datoFormEdad6"
              [datoFormEdad7]="datoFormEdad7"
              [datoFormEdad8]="datoFormEdad8"
              [datoFormEdad9]="datoFormEdad9"
              [datoFormEdad10]="datoFormEdad10"
              [isBitrix]="isBitrix"
              [isMobile]="isMobile"
              [isIdoc]="true"
            ></app-calculador>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
