<div class="contentModalGral px-3 pt-4">
    <div class="row titulos " >
        <div class="col-12 px-4">
            <h2><img src="assets/img/icon-socios-05.png" alt="">Cambio de forma de pago</h2>
        </div>
    </div>

        <div class="row justify-content-end formulario">
            <div class="col-12 col-lg-12">
                <div class="row ">
                    <div class="col-12">
                        <h3>Seleccionar nuevo método de pago</h3>
                    </div>
                    <div class="col-12 col-lg-12 itemForm">
                        <div class="contentSelect" id="contentSelect">
                              <select id="select" #t (change)="selectHijos(t.value)"  (focus)="spanTop()">
                                  <option value="" disabled>Seleccionar Forma de pago</option>
                                  <option [value]="1">Tarjeta de crédito</option>
                                  <option [value]="2">Débito en cuenta bancaria</option>
                                  <option [value]="3">Pago Mis Cuentas</option>   
                              </select>
                              <span id="areaSpan">Forma de pago *</span>
                        </div>
                    </div>

                    <div class="col-12 contentFormsPagos" id="formCredito">
                        <form class="formCredito" [formGroup]="formCredito" (ngSubmit)="submitForm(formCredito)">
                            <div class="row">
                                <div class="col-12 col-lg-12 itemForm">
                                    <div class="contentSelect" id="contentSelectCred">
                                          <select id="credito" formControlName="credito" (focus)="spanTopCredito()">
                                              <option value="" disabled>Seleccionar Tarjeta</option>
                                              <option value="Visa">Visa</option>
                                              <option value="Mastercard">Mastercard</option>
                                              <option value="Cabal">Cabal</option>
                                              <option value="Naranja">Naranja</option>
                                              <option value="Nativa">Nativa</option>
                                              <option value="American E.">American E.</option>
                                              <option value="Credial">Credial</option>
                                          </select>
                                          <span id="areaSpanCred">Tarjeta *</span>
                                    </div>
                                </div>
                                <div  class="col-12 col-lg-8 boxObligatoriosForms">
                                    <p><small>* Campos obligatorios</small></p>
                                </div>
                                <div class="col-12 col-lg-4 contentButton">
                                    <button [disabled]="formCredito.invalid" >ENVIAR</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col-12 contentFormsPagos" id="formDebito">
                        <form class="formDebito" [formGroup]="formDebito" (ngSubmit)="submitForm(formDebito)">
                            <div class="row">
                                <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': debito.invalid && (debito.dirty || debito.touched)  }" >
                                    <input type="text" maxlength="22" formControlName="debito"  id="debito">
                                    <span [ngClass]="{'spanInvalido ': debito.invalid && (debito.dirty || debito.touched)  } ">CBU: *</span>
                                    <div *ngIf="debito.invalid && (debito.dirty || debito.touched)" class="invalido">
                                        <div *ngIf="debito.errors.required">
                                            El CBU es requerido.
                                        </div>
                                        <div *ngIf="debito.errors.pattern">
                                            El CBU debe tener 22 digitos.
                                        </div>
                                    </div>
                                </div>
                                <div  class="col-12 col-lg-8 boxObligatoriosForms">
                                    <p><small>* Campos obligatorios</small></p>
                                </div>
                                <div class="col-12 col-lg-4 contentButton">
                                    <button [disabled]="formDebito.invalid" >ENVIAR</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col-12 contentFormsPagos" id="formPagoMisCuentas">
                        <form class="formPagoMisCuentas" [formGroup]="formPagoMisCuentas" (ngSubmit)="submitForm(formPagoMisCuentas)">
                            <div class="row">
                                <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': pagomiscuentas.invalid && (pagomiscuentas.dirty || pagomiscuentas.touched)  }" >
                                    <input type="checkbox" formControlName="pagomiscuentas"  id="pagomiscuentas" hidden >
                                </div>
                                <div  class="col-12 col-lg-8 boxObligatoriosForms">
                                    <p><small>* Campos obligatorios</small></p>
                                </div>
                                <div class="col-12 col-lg-4 contentButton">
                                    <button [disabled]="formPagoMisCuentas.invalid" >ENVIAR</button>
                                </div>
                            </div>
                        </form>
                    </div>               
                </div>
            </div>



        </div>


</div>


<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4>
    </div>
</ng-template>
