<div *ngIf="post" id="planesAreas">
    <section class="cobertura header3"  [ngStyle]="{'background-image':'url(' + post.acf.header.fondo + ')'}">
        <div class="container containerHeader">
            <div class="row h-100">
                <div class="col-12 col-lg-6 my-auto">
                    <h2 [innerHTML]="post.acf.header.titulo"></h2>
                    <p [innerHTML]="post.acf.header.bajada"></p>
                </div> 
            </div>
        </div>
    </section>
    <section class="cobertura box2col box2colImg">
        <div class="container">
            <div class="box" style="background-image: url(assets/img/back-box-cobertura.jpg);">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <h2 [innerHTML]="post.acf.introduccion.titulo"></h2>
                        <p [innerHTML]="post.acf.introduccion.bajada"></p>
                    </div>
                    <div class="col-12 col-lg-6">
                        <img src="{{ post.acf.introduccion.foto }}" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="cobertura servicios">
        <div class="container content-servicios">
            <div *ngIf="post.acf.servicios.bajada_top" class="topText" [innerHTML]='transform(post.acf.servicios.bajada_top)'></div>
            <h2 *ngIf="post.acf.servicios.titulo" class="topServicios"  [innerHTML]="post.acf.servicios.titulo"></h2>
            <p *ngIf="post.acf.servicios.bajada" class="topServicios" [innerHTML]="post.acf.servicios.bajada"></p>
            <div class="contentItems">
        
                <div class="row justify-content-center">
                    <div *ngFor="let boxServicio of post.acf.servicios.box_servicio" class="col-12 col-lg-6" >
                        <div class="contentItem">
                            <img src="{{ boxServicio.imagen }}" alt="" width="100%">
                            <div class="contentTitle">
                                <h2 [innerHTML]="boxServicio.titulo"></h2>
                            </div>
                            <div class="contentTxt">
                                <p [innerHTML]="boxServicio.bajada"></p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</div>


