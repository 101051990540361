<div *ngIf="post; else elseBlock">

    <div *ngIf="post.parent==116">

        <div *ngIf="post.id==180 else singlePlan">
            <app-promos></app-promos>
        </div>        
        <ng-template #singlePlan>
            <app-plan-single></app-plan-single>
        </ng-template>
        
    </div>


    <div *ngIf="post.parent==180">
        <app-promo-single></app-promo-single>
    </div>

    <div *ngIf="isBlog">
        <app-single-blog></app-single-blog>
    </div>
    

</div>


<ng-template #elseBlock>
    <div *ngIf="postSubscriptionCompleted && blogSubscriptionCompleted ">
        <app-page-not-found></app-page-not-found>
    </div>    
</ng-template>