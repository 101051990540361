<section class="planes" >
    <div class="content-plan" *ngFor="let post of posts">
        <div class="section-plan">

            <a *ngIf="post.acf.portada.en_construccion" routerLink="/en-construccion/" >
                <div class="back" *ngIf="!mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada.fondo + ')'}">
                    <div class="gradient1"></div>
                    <div class="gradient2"></div>
                </div>
                <div class="back" *ngIf="mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen_de_fondo_mobile + ')'}">
                    <div class="gradient1"></div>
                    <div class="gradient2"></div>
                </div>     
                <div class="container">
                    <h2 [innerHTML]='post.acf.portada.titulo'></h2>
                    <p [innerHTML]='post.acf.portada.bajada'></p>
                    <p class="contentLink"><span>VER SERVICIO</span></p>
                </div>
            </a>

            <a *ngIf="!post.acf.portada.en_construccion" routerLink="/{{ post.acf.link }}">
                <div class="back" *ngIf="!mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada.fondo + ')'}">
                    <div class="gradient1"></div>
                    <div class="gradient2"></div>
                </div>
                <div class="back" *ngIf="mobile" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen_de_fondo_mobile + ')'}">
                    <div class="gradient1"></div>
                    <div class="gradient2"></div>
                </div>      
                <div class="container">
                    <h2 [innerHTML]='post.acf.portada.titulo'></h2>
                    <p [innerHTML]='post.acf.portada.bajada'></p>
                    <p class="contentLink"><span>VER SERVICIO</span></p>
                </div>
            </a>
            
        </div>
    </div>
</section>