<div class="contentModalGral">
    <div class="row titulos" >
        <div class="col-12">
            <h2><img src="assets/img/icon-socios-07.png" alt=""> Reclamo de correo inicial</h2>
        </div>        
    </div>
    <form class="formSolicitarCredencial" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="row formulario">
            <div class="col-12">
                <h3>Ingrese la nueva dirección de correo.</h3>
            </div>
            <div class="col-12 col-lg-8" [ngClass]="{'campoInvalido ': emailReclamo.invalid && (emailReclamo.dirty || emailReclamo.touched)  }" >
                <input type="text" formControlName="emailReclamo"  id="emailReclamo">
                <span [ngClass]="{'spanInvalido ': emailReclamo.invalid && (emailReclamo.dirty || emailReclamo.touched)  } ">Email *</span>
                <div *ngIf="emailReclamo.invalid && (emailReclamo.dirty || emailReclamo.touched)" class="invalido">
                    <div *ngIf="emailReclamo.errors.required">
                        El Email es requerido.
                    </div>
                    <div *ngIf="emailReclamo.errors.email">
                        Debe colocar una direccion de email valida.
                    </div>
                </div>  
            </div>
            <div  class="col-12 col-lg-8 boxObligatoriosForms">
                <p><small>* Campos obligatorios</small></p>
            </div>
            <div class="col-12 col-lg-4 contentButton">
                <button [disabled]="contactForm.invalid" >ENVIAR</button>
            </div>  
        </div>
    </form>

</div>


