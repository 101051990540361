<section class="headerSocios">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9">
                <h2>Gestiones Online</h2>
                <p>
                    Bienvenido <strong>{{datosEmpresa.empresa_nombre}}</strong> <br>
                    Cuit: <strong>{{datosEmpresa.empresa_cuit}}</strong> <br>                    
                    <a routerLink="/" (click)='logout()'>CERRAR SESIÓN</a> <br>
                    <a routerLink="/atencion-al-socio/gestion-online/empresas" class="topVolver">Volver</a>
                </p>
            </div>
        </div>
    </div>
</section>
<section class="datos">
    <div class="container">
        <div class="content">
            <h2><img src="assets/img/icon-socios-06.png" alt=""> Mis datos</h2>
            
            <div class="contentData datos">
                <p *ngIf="datosEmpresa.empresa_plan_tipo == 'Area Protegida'">
                    <strong>Nombre de la empresa:</strong> {{datosEmpresa.empresa_nombre}} <br>
                    <strong>Área Protegida: </strong> {{datosEmpresa.empresa_codigo}}  <br><br>                  
                    <!-- <strong>Plan vittal:</strong> Plan Plus <br> -->
                    <strong>Email:</strong> {{datosEmpresa.empresa_email}}
                </p>
                <p *ngIf="datosEmpresa.empresa_plan_tipo == 'Convenio'">
                    <strong>Nombre de la empresa:</strong> {{datosEmpresa.empresa_nombre}} <br>
                    <strong>Convenio: </strong> {{datosEmpresa.empresa_factura}}     <br><br>               
                    <!-- <strong>Plan vittal:</strong> Plan Plus <br> -->
                    <strong>Email:</strong> {{datosEmpresa.empresa_email}}
                </p>
            </div>
            <!-- <div class="contentData grupo">
                <p><strong>Grupo Familiar:</strong></p>
                <p>
                    N° de socio 13345654 | Martina Marchiota <br>
                    N° de socio 13345456 | Martin Perotti <br>
                    N° de socio 13345457 |  Carla Perotti 
                </p>
            </div>             -->
            <div class="clearfix"></div>

        </div>
    </div>
</section>