<div class="boxForm cotizador" id="contentAll">
  <form action="" [formGroup]="contactForm">
    <div class="row m-0">
      <div class="col-12">
        <h2>{{ tituloForm }}</h2>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ': nombre.invalid && (nombre.dirty || nombre.touched)
        }"
      >
        <input type="text" formControlName="nombre" id="nombre" />
        <span
          [ngClass]="{
            spanInvalido: nombre.invalid && (nombre.dirty || nombre.touched)
          }"
          >Nombre *</span
        >
        <div
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"
          class="invalido"
        >
          <div *ngIf="nombre.errors.required">El nombre es requerido</div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            apellido.invalid && (apellido.dirty || apellido.touched)
        }"
      >
        <input type="text" formControlName="apellido" id="apellido" />
        <span
          [ngClass]="{
            spanInvalido:
              apellido.invalid && (apellido.dirty || apellido.touched)
          }"
          >Apellido *</span
        >
        <div
          *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)"
          class="invalido"
        >
          <div *ngIf="apellido.errors.required">El apellido es requerido</div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ': email.invalid && (email.dirty || email.touched)
        }"
      >
        <input type="text" formControlName="email" id="email" />
        <span
          [ngClass]="{
            'spanInvalido ': email.invalid && (email.dirty || email.touched)
          }"
          >Email *</span
        >

        <div
          *ngIf="email.invalid && (email.dirty || email.touched)"
          class="invalido"
        >
          <div *ngIf="email.errors.required">El email es requerido.</div>
          <div *ngIf="email.errors.email">
            Debe colocar una direccion de email valida.
          </div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            telefono.invalid && (telefono.dirty || telefono.touched)
        }"
      >
        <input type="tel" id="telefono" formControlName="telefono" />
        <span
          [ngClass]="{
            'spanInvalido ':
              telefono.invalid && (telefono.dirty || telefono.touched)
          }"
          >Teléfono *</span
        >

        <div
          *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)"
          class="invalido"
        >
          <div *ngIf="telefono.errors.required">El telefono es requerido.</div>
          <div *ngIf="telefono.invalid">Debe colocar un telefono valido.</div>
        </div>
      </div>

      <div
        class="col-12 col-lg-6 itemField"
        [ngClass]="{
          'campoInvalido ':
            localidad.invalid && (localidad.dirty || localidad.touched)
        }"
      >
        <input type="text" formControlName="localidad" id="localidad" />
        <span
          [ngClass]="{
            spanInvalido:
              localidad.invalid && (localidad.dirty || localidad.touched)
          }"
          >Localidad *</span
        >
        <div
          *ngIf="localidad.invalid && (localidad.dirty || localidad.touched)"
          class="invalido"
        >
          <div *ngIf="localidad.errors.required">
            La localidad es requerida.
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 itemForm">
        <div class="contentSelect" id="contentSelect">
          <select
            id="select"
            #t
            (change)="selectHijos(t.value)"
            (focus)="spanTop()"
          >
            <option value="" disabled>Seleccionar cantidad</option>
            <option [value]="1">1</option>
            <option [value]="2">2</option>
            <option [value]="3">3</option>
            <option [value]="4">4</option>
            <option [value]="5">5</option>
            <option [value]="6">6</option>
            <option [value]="7">7</option>
            <option [value]="8">8</option>
            <option [value]="9">9</option>
            <option [value]="10">10</option>
          </select>
          <span id="areaSpan">Cantidad de Integrantes</span>
        </div>
      </div>

      <div
        class="col-12 col-lg-12 itemForm itemsEdades"
        id="contentEddades"
        (click)="spanTopEdades()"
      >
        <span id="edadesSpan">Edades</span>

        <div class="fieldEdad" id="contentField1">
          <input
            (focus)="spanTopEdades()"
            type="number"
            maxlength="3"
            formControlName="edad1"
            id="edad1"
          />
        </div>
        <div class="fieldEdad" id="contentField2">
          <input
            type="number"
            maxlength="3"
            formControlName="edad2"
            id="edad2"
          />
        </div>
        <div class="fieldEdad" id="contentField3">
          <input
            type="number"
            maxlength="3"
            formControlName="edad3"
            id="edad3"
          />
        </div>
        <div class="fieldEdad" id="contentField4">
          <input
            type="number"
            maxlength="3"
            formControlName="edad4"
            id="edad4"
          />
        </div>
        <div class="fieldEdad" id="contentField5">
          <input
            type="number"
            maxlength="3"
            formControlName="edad5"
            id="edad5"
          />
        </div>
        <div class="fieldEdad" id="contentField6">
          <input
            type="number"
            maxlength="3"
            formControlName="edad6"
            id="edad6"
          />
        </div>
        <div class="fieldEdad" id="contentField7">
          <input
            type="number"
            maxlength="3"
            formControlName="edad7"
            id="edad7"
          />
        </div>
        <div class="fieldEdad" id="contentField8">
          <input
            type="number"
            maxlength="3"
            formControlName="edad8"
            id="edad8"
          />
        </div>
        <div class="fieldEdad" id="contentField9">
          <input
            type="number"
            maxlength="3"
            formControlName="edad9"
            id="edad9"
          />
        </div>
        <div class="fieldEdad" id="contentField10">
          <input
            type="number"
            maxlength="3"
            formControlName="edad10"
            id="edad10"
          />
        </div>
      </div>

      <div *ngFor="let post of posts" class="col-12 contentButton">
        <p *ngIf="post.id == codePrice">
          <button
            (click)="
              sendForm(
                post.acf.nombre,
                post.acf.price_1p,
                post.acf.price_2p,
                post.acf.price_3p,
                post.acf.price_4p,
                post.acf.price_p_adicional,
                post.acf.price_copago,
                post.acf.precio_adicional_65a69,
                post.acf.precio_adicional_70a74,
                post.acf.precio_adicional_75a79,
                post.acf.precio_adicional_80mas
              )
            "
            [ngx-scroll-to]="'#sendOk'"
            [disabled]="contactForm.invalid"
          >
            Cotizar
          </button>
        </p>
      </div>
    </div>
  </form>

  <div *ngIf="!isMobile" class="placaEnviado">
    <div class="row h-100 m-0">
      <div class="col-12 my-auto">
        <div>
          <div class="titulo">{{ nombrePlan }}</div>
          <div class="txt">
            <p><img src="{{ icono }}" alt="" /></p>
            <p>
              <strong>Grupo familiar <br />{{ valor }}</strong>
              {{ textoPersonas }}
            </p>
            <h1 style="margin-bottom: 0">
              $<strong id="resultadoCotizacion"></strong>
            </h1>
            <p style="position: relative; bottom: 10px">
              <small>Es tu abono mensual</small>
            </p>
            <p><button (click)="contratar()">CONTRATAR</button></p>
            <p>
              <a routerLink="/{{ slug_plan }}" target="_blank"
                >Ver plan detallado</a
              >
            </p>
            <p><a (click)="resetForm()">Volver a cotizar</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isMobile" class="boxCalculador my-auto none" id="boxCalculador">
    <div class="row h-100 m-0">
      <div class="col-12 my-auto">
        <div>
          <div class="titulo">{{ nombrePlan }}</div>
          <div class="txt">
            <p><img src="{{ icono }}" alt="" /></p>
            <p>
              <strong>Grupo familiar <br />{{ valor }}</strong>
              {{ textoPersonas }}
            </p>
            <h1>$<strong id="resultadoCotizacion"></strong></h1>
            <p><small>Es tu abono mensual</small></p>
            <p><button (click)="contratar()">CONTRATAR</button></p>
            <p>
              <a routerLink="/{{ slug_plan }}" target="_blank"
                >Ver plan detallado</a
              >
            </p>
            <p><a (click)="resetForm()">Volver a cotizar</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
