<div class="contentModalGral px-3 pt-4">
    <div class="row titulos " >
        <div class="col-12 px-4">
            <h2><img src="assets/img/icon-socios-02.png" alt=""> Ampliación grupo familiar</h2>
            <span style="color:#225bb2 ;">Completá los datos de la persona que deseas agregar</span>
        </div>        
    </div>
    <form class="formSolicitarCredencial" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="row justify-content-end formulario">
            <div class="col-12 col-lg-12">
                <div class="row ">

                    <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': nombre.invalid && (nombre.dirty || nombre.touched)  }" >
                        <input type="text" formControlName="nombre"  id="nombre">
                        <span [ngClass]="{'spanInvalido ': nombre.invalid && (nombre.dirty || nombre.touched)  } ">Nombre *</span>
                        <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="invalido">
                            <div *ngIf="nombre.errors.required">
                                El nombre es requerido.
                            </div>
                        </div>  
                    </div>

                    <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': apellido.invalid && (apellido.dirty || apellido.touched)  }" >
                        <input type="text" formControlName="apellido"  id="apellido">
                        <span [ngClass]="{'spanInvalido ': apellido.invalid && (apellido.dirty || apellido.touched)  } ">Apellido *</span>
                        <div *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)" class="invalido">
                            <div *ngIf="apellido.errors.required">
                                El apellido es requerido.
                            </div>
                        </div>  
                    </div>

                    <div class="col-12 col-lg-6" [ngClass]="{'campoInvalido ': fechaNac.invalid && (fechaNac.dirty || fechaNac.touched)  }" >
                        <input type="date" formControlName="fechaNac"  id="fechaNac" max={{hoy}}>
                        <span [ngClass]="{'spanInvalido ': fechaNac.invalid && (fechaNac.dirty || fechaNac.touched)  } ">Fecha de Nacimiento *</span>
                        <div *ngIf="fechaNac.invalid && (fechaNac.dirty || fechaNac.touched)" class="invalido">
                            <div *ngIf="fechaNac.errors.required">
                                La fecha de Nacimiento es requerida.
                            </div>
                        </div>  
                    </div>

                    <div class="col-12 col-lg-3" >
                        <div class="itemForm">                        
                            <div class="contentSelect" id="contentSelect">  
                                  <select formControlName="tipoDoc" id="select"  (focus)="spanTop()">
                                      <option value="" disabled>Seleccionar tipo documento</option>
                                      <option value="DNI">DNI</option>   
                                      <option value="CI">CI</option>      
                                      <option value="LE">LE</option>   
                                      <option value="LC">LC</option>      
                                  </select>
                                  <span id="areaSpan">Tipo Doc*</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-3" [ngClass]="{'campoInvalido ': nroDoc.invalid && (nroDoc.dirty || nroDoc.touched)  }" >
                        <input type="text" maxlength="8" formControlName="nroDoc"  id="nroDoc">
                        <span [ngClass]="{'spanInvalido ': nroDoc.invalid && (nroDoc.dirty || nroDoc.touched)  } ">Nro de doc. *</span>
                        <div *ngIf="nroDoc.invalid && (nroDoc.dirty || nroDoc.touched)" class="invalido">
                            <div *ngIf="nroDoc.errors.required">
                                El DNI es requerido.
                            </div>
                            <div *ngIf="nroDoc.errors.pattern">
                                El DNI debe tener entre 7 y 8 números.
                            </div>
                        </div>  
                    </div>

                </div>
            </div>

            <div  class="col-12 col-lg-8 boxObligatoriosForms">
                <p><small>* Campos obligatorios</small></p>
            </div>
            <div class="col-12 col-lg-4 contentButton">
                <button [disabled]="contactForm.invalid" >ENVIAR</button>
            </div>  
        </div>
    </form>

</div>


<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>
