<div *ngIf="post">
    <section class="landingContacto">

        <section class="header">
            <div class="container">                
                <div class="row">
                    <div class="col-12 col-lg-6 logo-landing">
                        <img src="assets/img/logo-vittal-w.png" alt="">
                    </div>
                    <div class="col-12 col-lg-6 claim-landing">
                        <h2 *ngIf="post.acf.landing_contacto.telefono">{{post.acf.landing_contacto.telefono}}</h2>
                    </div>    
                </div>        
            </div>
        </section>        

    </section>

    <section *ngIf="!mobile" class="backTop" [ngStyle]="{'background-image': 'url(' + post.acf.landing_contacto.imagen + ')'}" ></section>
    <section *ngIf="mobile" class="backTop" [ngStyle]="{'background-image': 'url(' + post.acf.landing_contacto.imagenmobile + ')'}" ></section>  
    <section class="contentForm">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h1 [innerHTML]='post.acf.landing_contacto.titulo'></h1>
                    <div class="contentTxt" [innerHTML]='post.acf.landing_contacto.bajada'></div>
                </div>

                <div class="col-12 col-lg-6" *ngIf="post.acf.tipo_de_landing == 'Landing Contacto'">
                    <app-form-contacto [tituloForm]="post.acf.landing_contacto.titulo_formulario" [codeForm]="post.acf.landing_contacto.codigo_formulario" [isBitrix]="isBitrix"></app-form-contacto>
                </div>
                <div class="col-12 col-lg-6" *ngIf="post.acf.tipo_de_landing == 'Landing Cotizador'">
                    <app-form-cotizar [tituloForm]="post.acf.landing_contacto.titulo_formulario" [codePrice]="post.acf.precio" [codeForm]="post.acf.landing_contacto.codigo_formulario" [isMobile]="mobile" [isBitrix]="isBitrix"></app-form-cotizar>                    
                </div>
                <div class="col-12 col-lg-6" *ngIf="post.acf.tipo_de_landing == 'Landing Cotizador todos los planes'">
                    <app-form-cotizadores [tituloForm]="post.acf.landing_contacto.titulo_formulario" [codePrice]="post.acf.precio" [codeForm]="post.acf.landing_contacto.codigo_formulario" [isMobile]="mobile" [isBitrix]="isBitrix"></app-form-cotizadores>
                </div>
                <div class="col-12 col-lg-6" *ngIf="post.acf.tipo_de_landing == 'Landing Contacto Bitrix'">
                    <app-form-contacto-bitrix [tituloForm]="post.acf.landing_contacto.titulo_formulario" [scriptBitrix]="post.acf.scriptbitrix"></app-form-contacto-bitrix>
                </div>

                
            </div>
        </div>
    </section>
    <section class="servicios" style="background-image: url('assets/img/bk-beneficios-landing.png');">
        <div class="container">
            <div class="row m-0 justify-content-center align-items-center">
                <div class="col-12">
                    <h2>¡Beneficios!</h2>
                </div>
                <div class="col-12 col-lg-3" *ngFor='let beneficio of post.acf.landing_contacto.beneficios'>
                    <div class="contentItem">
                        <img src="{{beneficio.icono}}" alt=""><br>
                        {{beneficio.texto}}
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <footer>
        <div class="container">
    
            <div class="row m-0">
                <div class="col-12">
                    <p [innerHTML]='post.acf.landing_contacto.legales'></p>
                </div>
            </div>
    
        </div>
    </footer>

    <app-chatbot></app-chatbot>
    

</div>

