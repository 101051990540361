<section class="nav-promos">
    
    <h2>Otras promos</h2>
    
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <ng-container *ngFor="let post of posts" >

          <div ngxSlickItem *ngIf="slugId != post.slug" class="slide">
              <div  class="contentItem"  >
                <div class="item-servicio" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                  <div class="hoverContent  h-100" [ngStyle]="{'background-image':'url(' + post.acf.portada.imagen + ')'}">
                    <div class="hoverContentTxt w-100 my-auto ">
                      <h2 [innerHTML]="post.acf.portada.titulo"></h2>
                      <p [innerHTML]="post.acf.portada.descripcion"></p>
    
                      <a *ngIf="!post.acf.portada.redirect.utilizar_redireccionamiento" routerLink="/promos/{{post.slug}}">
                        <div class="boton">Más información</div>
                      </a>
                      <a *ngIf="post.acf.portada.redirect.utilizar_redireccionamiento" href="{{post.acf.portada.redirect.url}}">
                        <div class="boton">Más información</div>
                      </a>
                      
                    </div>
                  </div>
                  <h3>{{post.acf.portada.titulo}}</h3>
                </div>
            </div>     
          </div>

        </ng-container>  
    </ngx-slick-carousel>