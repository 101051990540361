<form class="formLoginEmpresa" [formGroup]="contactForm" (ngSubmit)="submitLoginFormEmpresa()">
    <div class="row">

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': cuitEmpresa.invalid && (cuitEmpresa.dirty || cuitEmpresa.touched) }">
            <input  maxlength="11" type="text" formControlName="cuitEmpresa"  id="cuitEmpresa" >
            <span [ngClass]="{'spanInvalido': cuitEmpresa.invalid && (cuitEmpresa.dirty || cuitEmpresa.touched)}" >CUIT *</span>
            <div *ngIf="cuitEmpresa.invalid && (cuitEmpresa.dirty || cuitEmpresa.touched)" class="invalido">
                <div *ngIf="cuitEmpresa.errors.required">
                    El CUIT es requerido, no poner espacios ni guiones.
                </div>
                <div *ngIf="cuitEmpresa.errors.pattern">
                    El CUIT debe tener como minimo 11 numeros sin guiones.
                </div>             
            </div>
        </div>

        <div class="col-12 col-lg-12" [ngClass]="{'campoInvalido ': passwordEmpresa.invalid && (passwordEmpresa.dirty || passwordEmpresa.touched)  }" >
            <input type="password" formControlName="passwordEmpresa"  id="passwordEmpresa">
            <span [ngClass]="{'spanInvalido ': passwordEmpresa.invalid && (passwordEmpresa.dirty || passwordEmpresa.touched)  } ">Contraseña *</span>
            <div *ngIf="passwordEmpresa.invalid && (passwordEmpresa.dirty || passwordEmpresa.touched)" class="invalido">
                <div *ngIf="passwordEmpresa.errors.required">
                    La contraseña es requerida.
                </div>
            </div>  
        </div>

        <div class="col-12 col-lg-12 contentButton">
            <div class="row">
                <div class="col-12 col-lg-6"><button [disabled]="contactForm.invalid" >INICIAR SESIÓN</button></div>
                <div class="col-12 col-lg-6"><input type="button" (click)="abrirFormSocios()" value="INGRESO POR PRIMERA VEZ" ></div>
            </div>
            <div class="row boxObligatorios">
                <p><small>* Campos obligatorios</small></p>
            </div>
            
            
        </div>                              
    </div>
</form>

<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 25px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>