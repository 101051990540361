<section class="slider">
    
    <div id="carouselSliderHome" class="carousel slide" data-ride="carousel" *ngFor="let post of posts$ | async">
        <ol class="carousel-indicators">
          <li *ngFor="let slide of post.acf.slide; let i=index" data-target="#carouselSliderHome" [attr.data-slide-to]="i" [ngClass]="{'active' : i == 0}"></li>          
        </ol>
        <div class="carousel-inner" >
            <div *ngFor="let slide of post.acf.slide; let iSlide=index" [ngClass]="{'active' : iSlide == 0}" class="carousel-item">
               <div *ngIf="slide.tipo_de_slide == 'Template 1'"  class="item01" [ngStyle]="{'background-image':'url(' + slide.template_1.imagen + ')'}" >
                   <div class="container h-100">
                        <div class="row h-100">
                            <div class="col-md-6 col-12 p-0 contentPic" [ngStyle]="{'background-image':'url(' + slide.template_1.imagen + ')'}"></div>
                            <div class="col-md-6 col-12 h-md-100 px-5 my-auto contentTxt">
                                <h2 [innerHTML]='slide.template_1.titulo'></h2>
                                <p class="bajada" [innerHTML]='slide.template_1.bajada'></p>
                                
                                <p *ngIf="slide.template_1.link_externo" class="contentLink"><a href="{{slide.template_1.link}}" target="_blank">{{slide.template_1.label}}</a></p>
                                <p *ngIf="!slide.template_1.link_externo" class="contentLink"><a routerLink="/{{slide.template_1.link}}">{{slide.template_1.label}}</a></p>
                            </div>
                        </div>
                    </div>
               </div>
               <div *ngIf="slide.tipo_de_slide == 'Template 2'" class="item02" [ngStyle]="{'background-image':'url(' + slide.template_2.imagen + ')'}">
                    <div class="container h-100">
                        <div class="row h-100">
                            <div class="col-12 col-md-6 my-auto contentTxt">
                                <h2 [innerHTML]='slide.template_2.titulo'></h2>
                                <p [innerHTML]='slide.template_2.bajada'></p>
                                <p class="contentLink"><a routerLink="/{{slide.template_2.link}}">{{slide.template_2.label}}</a></p>
                            </div>
                            <div class="col col-md-6 my-auto sectorR"></div>
                        </div>
                    </div>
                </div>
               <div *ngIf="slide.tipo_de_slide == 'Template 3'" class="item03" style="background-image: url('assets/img/bk-destacados-home.png');">
                   
                    <div class="container h-100">
                        <div class="row h-100-desk">

                            <div class="col-12 col-md-6 order-md-1 my-auto contentTxt">
                                <h2 [innerHTML]='slide.template_3.titulo'></h2>
                                <p class="bajada" [innerHTML]='slide.template_3.bajada'></p>
                                <div *ngIf="!slide.template_3.sin_boton">
                                    <p class="contentLink btnDesk"><a routerLink="/{{slide.template_3.link}}">{{slide.template_3.label}}</a></p>
                                </div>
                                <div *ngIf="slide.template_3.link_externo">
                                    <p class="contentLink btnDesk"><a href="{{slide.template_3.link}}" target="_blank">{{slide.template_3.label}}</a></p>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 order-md-2 my-auto contentVideo">
                                <iframe width="560" height="315" [attr.src]="'//www.youtube-nocookie.com/embed/'+slide.template_3.video+'?controls=0' | safe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <p class="contentLink btnMobile"><a routerLink="/{{slide.template_3.link}}">{{slide.template_3.label}}</a></p>
                                <div class="clearfix"></div>
                            </div>

                        </div>
                    </div> 

               </div>
               <div *ngIf="slide.tipo_de_slide == 'Template 4'" class="item04" [ngStyle]="{'background-image':'url(' + slide.template_4.imagen + ')'}">
                   
                    <div class="container h-100">
                        <div class="contentAll" [ngStyle]="{'background-image':'url(' + slide.template_4.imagen_responsive + ')'}"></div>   
                        <div class="row h-100">
                            <div class="col my-auto mx-3">
                                <h2 [innerHTML]='slide.template_4.titulo'></h2>
                                <p [innerHTML]='slide.template_4.bajada'></p>

                                <p *ngIf="!slide.template_4.sin_boton" class="contentLink"><a routerLink="/{{slide.template_4.link}}">{{slide.template_4.label}}</a></p>
                            </div>
                        </div>                    
                    </div>

               </div>
                
            </div>
            
        </div>
        <a class="carousel-control-prev" href="#carouselSliderHome" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselSliderHome" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>

</section>
