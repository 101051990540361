<section class="headerSocios">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-9">
        <h2>Gestiones Online</h2>
        <p>
          Bienvenido
          <strong
            >{{ datosSocio.socio_nombre + " " + datosSocio.socio_apellido }} ({{
              datosSocio.socio_razon_social
            }})</strong
          >
          <br />
          <!-- Agregar razón social -->
          Nº de Socio: <strong>{{ datosSocio.socio_numero }}</strong> <br />
          <a routerLink="/" (click)="logout()">CERRAR SESIÓN</a> <br />
          <a
            routerLink="/atencion-al-socio/gestion-online/socios"
            class="topVolver"
            >Volver</a
          >
        </p>
      </div>
      <div class="col-12 col-lg-3">
        <a routerLink="/atencion-al-socio/gestion-online/socios/credencial">
          <div class="boxVisualizar">
            <p><img src="assets/img/icon-socios-visualizar.png" alt="" /></p>
            <p>
              Credencial <br />
              Digital
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="datos">
  <div class="container">
    <div class="content">
      <h2><img src="assets/img/icon-socios-06.png" alt="" /> Mis datos</h2>

      <div *ngIf="isLoading" class="spinner">
        <!-- Aquí puedes usar un spinner CSS o una imagen de carga -->
        <p>Cargando datos...</p>
      </div>

      <div class="contentData datos" *ngIf="!isLoading">
        <p>
          <strong>Nombre y apellido:</strong>
          {{ datosSocio.socio_nombre + " " + datosSocio.socio_apellido }} <br />
          <strong>Telefono:</strong> {{ datosSocio.telefono }} <br />
          <strong>Email:</strong> {{ datosSocio.socio_email }} <br />
          <strong>Dirección:</strong> {{ datosSocio.direccion }} <br />
          <strong>Localidad:</strong> {{ datosSocio.localidad }} <br />
          <strong>Partido:</strong> {{ datosSocio.partido }} <br />
          <strong>Provincia:</strong> {{ datosSocio.provincia }} <br />
        </p>
      </div>
      <!-- <div class="contentData grupo">
                <p><strong>Grupo Familiar:</strong></p>
                 <p>
                    N° de socio 13345654 | Martina Marchiota <br>
                    N° de socio 13345456 | Martin Perotti <br>
                    N° de socio 13345457 |  Carla Perotti 
                </p> 
            </div>             -->
      <div class="clearfix"></div>
    </div>
  </div>
</section>
