<div class="contentModalGral px-3 pt-4">
    <div class="row titulos " >
        <div class="col-12 px-4">
            <h2><img src="assets/img/icon-socios-04.png" alt=""> Cambio de domicilio</h2>
        </div>        
    </div>
    <form class="formSolicitarCredencial" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="row justify-content-end formulario">
            <div class="col-12 col-lg-6">
                <div class="row ">
                    <div class="col-12" [ngClass]="{'campoInvalido ': calle.invalid && (calle.dirty || calle.touched)  }" >
                        <input type="text" formControlName="calle"  id="calle">
                        <span [ngClass]="{'spanInvalido ': calle.invalid && (calle.dirty || calle.touched)  } ">Calle *</span>
                        <div *ngIf="calle.invalid && (calle.dirty || calle.touched)" class="invalido">
                            <div *ngIf="calle.errors.required">
                                La calle es requerido.
                            </div>
                        </div>  
                    </div>

                    <div class="col-4" [ngClass]="{'campoInvalido ': altura.invalid && (altura.dirty || altura.touched)  }" >
                        <input type="text" formControlName="altura"  id="altura">
                        <span [ngClass]="{'spanInvalido ': altura.invalid && (altura.dirty || altura.touched)  } ">Altura *</span>
                        <div *ngIf="altura.invalid && (altura.dirty || altura.touched)" class="invalido">
                            <div *ngIf="altura.errors.required">
                                La altura es requerido.
                            </div>
                        </div>  
                    </div>

                    <div class="col-4" [ngClass]="{'campoInvalido ': piso.invalid && (piso.dirty || piso.touched)  }" >
                        <input type="text" formControlName="piso"  id="piso">
                        <span [ngClass]="{'spanInvalido ': piso.invalid && (piso.dirty || piso.touched)  } ">Piso *</span>
                        <div *ngIf="piso.invalid && (piso.dirty || piso.touched)" class="invalido">
                            <div *ngIf="piso.errors.required">
                                El piso es requerido.
                            </div>
                        </div>  
                    </div>

                    <div class="col-4" [ngClass]="{'campoInvalido ': departamento.invalid && (departamento.dirty || departamento.touched)  }" >
                        <input type="text" formControlName="departamento"  id="departamento">
                        <span [ngClass]="{'spanInvalido ': departamento.invalid && (departamento.dirty || departamento.touched)  } ">Dpto. *</span>
                        <div *ngIf="departamento.invalid && (departamento.dirty || departamento.touched)" class="invalido">
                            <div *ngIf="departamento.errors.required">
                                El departamento es requerido.
                            </div>
                        </div>  
                    </div>

                    <div class="col-12" [ngClass]="{'campoInvalido ': entreCalles.invalid && (entreCalles.dirty || entreCalles.touched)  }" >
                        <input type="text" formControlName="entreCalles"  id="entreCalles">
                        <span [ngClass]="{'spanInvalido ': entreCalles.invalid && (entreCalles.dirty || entreCalles.touched)  } ">Entre calles *</span>
                        <div *ngIf="entreCalles.invalid && (entreCalles.dirty || entreCalles.touched)" class="invalido">
                            <div *ngIf="entreCalles.errors.required">
                                Entre Calles es requerido.
                            </div>
                        </div>  
                    </div>

                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row  ">
                    <div class="col-12" [ngClass]="{'campoInvalido ': localidad.invalid && (localidad.dirty || localidad.touched)  }" >
                        <input type="text" formControlName="localidad"  id="localidad">
                        <span [ngClass]="{'spanInvalido ': localidad.invalid && (localidad.dirty || localidad.touched)  } ">Localidad. *</span>
                        <div *ngIf="localidad.invalid && (localidad.dirty || localidad.touched)" class="invalido">
                            <div *ngIf="localidad.errors.required">
                                La localidad es requerida.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': cp.invalid && (cp.dirty || cp.touched)  }" >
                        <input type="text" formControlName="cp"  id="cp">
                        <span [ngClass]="{'spanInvalido ': cp.invalid && (cp.dirty || cp.touched)  } ">Código postal *</span>
                        <div *ngIf="cp.invalid && (cp.dirty || cp.touched)" class="invalido">
                            <div *ngIf="cp.errors.required">
                                El Código postal es requerida.
                            </div>
                        </div>  
                    </div>
                    <div class="col-12" [ngClass]="{'campoInvalido ': telefono.invalid && (telefono.dirty || telefono.touched)  }" >
                        <input type="text" formControlName="telefono"  id="telefono">
                        <span [ngClass]="{'spanInvalido ': telefono.invalid && (telefono.dirty || telefono.touched)  } ">Teléfono *</span>
                        <div *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)" class="invalido">
                            <div *ngIf="telefono.errors.required">
                                El Teléfono postal es requerida.
                            </div>
                        </div>  
                    </div>

                </div>
            </div>
            <div class="col-12 col-lg-12" >
                <textarea type="text" formControlName="aclaraciones"  id="aclaraciones"></textarea>
                <span>Aclaraciones</span> 
            </div>
            <div  class="col-12 col-lg-8 boxObligatoriosForms">
                <p><small>* Campos obligatorios</small></p>
            </div>
            <div class="col-12 col-lg-4 contentButton">
                <button [disabled]="contactForm.invalid" >ENVIAR</button>
            </div>  
        </div>
    </form>

</div>


<ng-template #modalRespuesta let-modal id="modalRespuesta">
    <div class="modal-body contentModal" style="height: 200px;">  
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>        
            <h4  style="color: #005ab8; width: 95%; margin-top: 50px; text-align: center;">{{modalMessage}}</h4> 
    </div>
</ng-template>
