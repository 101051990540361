import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DopplerService {
  private apiUrl = environment.dopplerApiUrl;

  constructor(private http: HttpClient) {}

  submitForm(formData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/subscribers`, {
      email: formData.email,
      fields: {
        NOMBRE: formData.nombre,
        APELLIDO: formData.apellido,
        TELEFONO: formData.telefono,
        LOCALIDAD: formData.localidad
      }
    });
  }
}